import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { ChartsPageIntroDialog } from 'features/product-tour/charts-page/charts-page-intro-dialog'
import { useTour } from '@reactour/tour'
import { ExitTourDialog } from 'features/product-tour/exit-tour-dialog'
import { getChartsPageTourSteps } from 'features/product-tour/charts-page/charts-page-tour-steps'
import { PRODUCT_TOUR_FAKE_DWELLING_ID, PRODUCT_TOUR_QUERY_KEYS, ProductTourPageName } from 'features/product-tour/pro-portal-tour'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'


export interface ChartsPageTourProps {
  startTour: boolean;
  onEndTour?: () => void;
}

/**
 * A component that controls the UI workflow for the product tour on the charts page. It only contains UI logic.
 * @param props ChartsPageTourProps
 */
export const ChartsPageTour = (props: ChartsPageTourProps): JSX.Element => {
  const { startTour, onEndTour } = props

  const navigate = useNavigate()

  const {
    setIsOpen, setCurrentStep, setSteps, steps,
  } = useTour()

  const [isIntroDialogOpen, setIsIntroDialogOpen] = useState(false)
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false)


  // Display Intro Dialog whenever tour starts or hide when tour ends.
  useEffect(() => {
    setIsIntroDialogOpen(startTour)
  }, [startTour])

  // The actual tour steps only start when the user proceed from the intro dialog.
  const handleChartsPageIntroDialogContinue = () => {
    setIsIntroDialogOpen(false)
    setSteps(getChartsPageTourSteps(handlePreviousStep, handleNextStep, handleCloseStep))
    setCurrentStep(0)
    setIsOpen(true)
  }

  const handlePreviousStep = (previousStep?: number, currentStep?: number, totalSteps?: number) => {
    setCurrentStep((prevStep: number) => prevStep - 1)
  }

  const handleNextStep = (nextStep?: number, currentStep?: number, totalSteps?: number) => {

    if (currentStep === totalSteps) {
      handleExitTourDialogExit()
      navigate({
        pathname: generatePath(ROUTE_PATHS.customers.details.equipment.absolute, {
          dwellingId: PRODUCT_TOUR_FAKE_DWELLING_ID.toString(10),
        }),
        search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Equipment}` +
          `&${HISTORY_QUERY_KEYS.zone}=Downstairs`,
      })
    } else {
      setCurrentStep((prevStep: number) => prevStep + 1)
    }
  }

  const handleCloseStep = () => {
    setIsOpen(false)
    setIsIntroDialogOpen(false)
    setIsExitDialogOpen(true)
  }

  const handleExitTourDialogContinue = () => {
    setIsExitDialogOpen(false)

    // If tour steps are greater than 0 it means tour has already started. Otherwise, the intro dialog was being displayed.
    if (steps.length > 0) {
      setIsOpen(true)
    } else {
      setIsIntroDialogOpen(true)
    }
  }

  const handleExitTourDialogExit = () => {
    setIsExitDialogOpen(false)
    setIsIntroDialogOpen(false)

    setSteps([])
    setCurrentStep(0)
    setIsOpen(false)

    if (onEndTour) {
      onEndTour()
    }
  }

  return (
    <>
      <ChartsPageIntroDialog
        isOpen={isIntroDialogOpen}
        onContinue={handleChartsPageIntroDialogContinue}
        onClose={handleCloseStep} />

      <ExitTourDialog
        isOpen={isExitDialogOpen}
        onContinueTour={handleExitTourDialogContinue}
        onExitTour={handleExitTourDialogExit} />
    </>
  )
}
