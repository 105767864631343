import {
  Box,
  Breadcrumbs,
  Button,
  Typography
} from '@material-ui/core'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import PersonIcon from '@material-ui/icons/Person'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import CustomerSelectZone from 'features/customer-drill-down/customer-select-zone'
import alertsImageUrl from 'features/customer-drill-down/icons/alerts.svg'
import equipmentImageUrl from 'features/customer-drill-down/icons/fan.svg'
import { CHARTS_PAGE_TOUR_SELECTORS } from 'features/product-tour'
import React, { useEffect, useMemo } from 'react'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { theme } from 'theme'
import InfoTooltip from 'ui/info-tooltip'
import SideMenuLayout from 'ui/side-menu-layout'
import { SideMenuItem } from 'ui/side-menu-layout/side-menu-layout'
import Stack from 'ui/stack'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import { devicesTooltip } from 'utils/constants/tooltip-content'
import { useStateInURLQueryParameter } from 'utils/hooks/router'
import useAdmin from 'utils/hooks/useAdmin'
import useAllZones from 'utils/hooks/useAllZones'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import useHasMonitorInZone from 'utils/hooks/useHasMonitorInZone'

interface Props {
  renderer: (params: { isCollapsed: boolean }) => React.ReactNode;
  dwellingId: number;
}

export const CustomerLayout = (props: Props) => {
  const { renderer, dwellingId } = props
  const { mixpanel } = useMixPanel()

  const location = useLocation()

  const hideReportButton = location.pathname?.includes('equipment') ||
    location.pathname?.includes('profile') ||
    location.pathname?.includes('alerts')


  const [zone, setZone] = useStateInURLQueryParameter({
    initialValue: '',
    key: HISTORY_QUERY_KEYS.zone,
  })

  const dwellingDetails = useCurrentDwellingDetails()
  const allZones = useAllZones()
  const admin = useAdmin()
  const hasMonitor = useHasMonitorInZone()

  // Initially set the zone to the URL if isn't set yet. Do it only once.
  useEffect(() => {
    if (!zone && allZones.length > 0) {
      setZone(allZones[0], true)
    }
  }, [
    allZones?.length,
    zone,
    setZone,
  ])

  let newSearch = ''

  if (zone) {
    newSearch = `?${HISTORY_QUERY_KEYS.zone}=${encodeURI(zone).replace('#', '%23')}`
  }

  const sideMenuItems: Array<SideMenuItem> = useMemo(() => {

    return [
      {
        to: `/customers/${dwellingId.toString()}${newSearch}`,
        // to: {
        //   pathname: generatePath(ROUTE_PATHS.customers.details.root.absolute, {
        //     dwellingId: dwellingId.toString(),
        //   }),
        //   search: newSearch,
        // },
        icon: <InsertChartIcon />,
        label: 'Charts',
      },
      {
        to: `/customers/${dwellingId.toString()}/equipment${newSearch}`,
        // to: {
        //   pathname: generatePath(ROUTE_PATHS.customers.details.equipment.absolute, {
        //     dwellingId: dwellingId.toString(),
        //   }),
        //   search: newSearch,
        // },
        icon:
          <img
            alt="Equipment Icon"
            src={equipmentImageUrl}
            width="24"
            height="24"
          />,
        label: 'Equipment',
      },
      {
        to: `/customers/${dwellingId.toString()}/alerts${newSearch}`,
        // to: {
        //   pathname: generatePath(ROUTE_PATHS.customers.details.alerts.absolute, {
        //     dwellingId: dwellingId.toString(),
        //   }),
        //   search: newSearch,
        // },
        icon: <img
          alt="Alerts Icon"
          src={alertsImageUrl}
          width="24"
          height="24"
        />,
        label: 'Alerts',
      },
      {
        to: `/customers/${dwellingId.toString()}/profile${newSearch}`,
        // to: {
        //   pathname: generatePath(ROUTE_PATHS.customers.details.profile.absolute, {
        //     dwellingId: dwellingId.toString(),
        //   }),
        //   search: newSearch,
        // },
        icon: <PersonIcon />,
        label: 'Customer Details',
      },
    ]
  }, [dwellingId, zone])

  return (
    <>
      {/* Top zone navigation bar section. */}
      <Box
        p={1}
        pl={2}
        style={{
          backgroundColor: theme.palette.grey[200],
        }}
        display="flex"
        alignItems="center"
      >
        {hasMonitor ? (
          <Breadcrumbs>
            <Link to={ROUTE_PATHS.customers.root.absolute}>
              <Typography color="textPrimary" variant="subtitle1">
                Customers
              </Typography>
            </Link>
            {(dwellingDetails && admin) ? (
              <Typography color="textPrimary" variant="subtitle1">
                {admin?.last_name} - {dwellingDetails?.name} ({dwellingDetails?.street_1})
              </Typography>
            ) : (<Typography variant="caption">Loading dwelling...</Typography>)}
            <CustomerSelectZone
              allZones={allZones}
              setZone={setZone}
            />
          </Breadcrumbs>
        ) : (
          <Stack justify='flex-start' direction='row'>
            <Typography variant="caption">No monitor found</Typography>
            <CustomerSelectZone
              allZones={allZones}
              setZone={setZone}
            />
          </Stack>
        )}
        {devicesTooltip}

        {!hideReportButton && (
          <Box ml="auto" mr="10px">
            <InfoTooltip
              placement="bottom-end"
              arrow
              interactive
              title={
                <>
                  <p>
                    This feature is currently part of an indefinite Free Trial which means we haven’t set up a date when
                    it will be part of a paid tier of software services
                  </p>
                  <p>
                    Please note, you will <b>not</b> be charged for it during this Free Trial phase. We’ll keep you
                    posted on any future changes to the feature.
                  </p>
                </>
              }
            >
              <Button
                id={CHARTS_PAGE_TOUR_SELECTORS.BUILD_A_REPORT}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (mixpanel) {
                    mixpanel.track('pp_customerDrillDownPage_buildReportButton')
                  }
                }}
                component={Link}
                to={`/customer/${dwellingId.toString()}/report${newSearch}`}
              // to={{
              //   pathname: generatePath(ROUTE_PATHS.customers.details.report.absolute, {
              //     dwellingId: dwellingId.toString(10),
              //   }),
              //   search: `?zone=${zone}`,
              // }}
              >
                Build A Report
                <Box
                  py={0}
                  px={1}
                  ml={1}
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '.25rem',
                    fontSize: '.8rem',
                  }}
                >
                  TRIAL
                </Box>
              </Button>
            </InfoTooltip>
          </Box>

        )}
      </Box>

      {/* Both side menu layout and the content section that this layout will render (renderer) by its side. */}
      <SideMenuLayout
        menuItems={sideMenuItems}
        isCollapsable
        height="calc(100vh - 64px - 58px)" // 100% to the browser - the top nav menu - top zone navigation bar section.
        name="customer"
        renderer={renderer}
      />
    </>
  )
}
