import React, { useCallback, useEffect, useState } from 'react'
import 'features/customer-drill-down/charts/current-status/device-current-status.scss'
import {
  Typography, Box, Grid, Paper, Button,
} from '@material-ui/core'
import Thermometer from 'features/customer-drill-down/charts/current-status/thermometer'
import gaugeService from 'state-mngt/services/gauge-service'
import { DeviceTelemetry } from 'state-mngt/models/device'
import tempService from 'utils/temp-converter'
import { TELEMETRY_THRESHOLD } from 'utils/constants/telemetry-threshold'
import { TimeInterval } from 'utils/constants/time-interval'
import { UserPreference } from 'state-mngt/models/user'
import { currentStatusTooltip, currentStatusWarningTooltip } from 'utils/constants/tooltip-content'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Alert, AlertTitle } from '@material-ui/lab'
import { getCurrentData } from 'state-mngt/actions/dwelling-actions'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { useRequestsAdapter } from 'utils/hooks/http'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { CHARTS_PAGE_TOUR_SELECTORS } from 'features/product-tour'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import { capitalize } from 'utils/string-utils'
import useEcosenseTelemetry from 'utils/hooks/useEcosenseTelemetry'
import useCurrentDwellingContents from 'utils/hooks/useCurrentDwellingContents'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import useEcosenseStore, { selectLatest, selectTelemetry } from 'stores/ecosense'


interface Props {
  deviceId: number;
  setOpen: any
}

/**
 * Display selected device's current telemetry
 */
const DeviceCurrentStatus = (props: Props) => {
  const { deviceId, setOpen } = props

  const dispatch = useAppDispatch()
  const { mixpanel } = useMixPanel()

  const userPreferences = useAppSelector(getUserPreferences)
  const [savedDeviceId, setSavedDeviceId] = useState<number>()
  const [telemetry, setTelemetry] = useState<DeviceTelemetry | undefined>()
  const [isActive, setIsActive] = useState<boolean>(true)
  const [timeoutId, setTimeoutId] = useState<number>()
  const [isCollapsed, setToggleOpen] = useState(true)
  const [hasData, setHasData] = useState<boolean>(true) // for db connection error
  const requestsAdapter = useRequestsAdapter()

  const memorizedDrawGauge = useCallback(() => {
    if (userPreferences) {
      drawGauge(telemetry, userPreferences)
    }
  }, [telemetry, userPreferences])

  useEffect(() => {
    if (google && google.charts) {
      google.charts.load('current', {
        packages: ['gauge'],
      })
      google.charts.setOnLoadCallback(() => memorizedDrawGauge())
    }

    if (requestsAdapter) {
      /**
       * Call get Telemetry API endpoint,
       * 1. updates state for current telemetry data
       * 2. updates state for isActive field
       * 3. dispatch current telemetry to store
       * @param {number} deviceId - number
       */
      const getTelemetry = async (deviceId: number) => {
        try {
          const currentData = await dispatch(getCurrentData(deviceId, requestsAdapter))
          if (currentData) {
            // check for time stamp, if less than 3 min ago, then active
            const activeTimeStamp = Date.now() - new Date(currentData.timestamp).getTime() <= TimeInterval.MINUTE * 3
            setHasData(true)
            setTelemetry(currentData)
            setIsActive(Math.abs(currentData.airflow!) > TELEMETRY_THRESHOLD.airflow && activeTimeStamp)
          } else {
            setHasData(false)
            setIsActive(false)
            setTelemetry(undefined)
          }
        } catch {
          // aborted
        }
      }

      /**
       * call current telemetry every minute
       * @param deviceIdInput - number
       */
      const getTelemetryEveryMinute = (deviceIdInput: number) => {
        if (!deviceIdInput) {
          return
        }

        window.setTimeout(function repeat() {
          getTelemetry(deviceIdInput)
          setTimeoutId(window.setTimeout(repeat, TimeInterval.MINUTE))
        })
      }

      if (deviceId && deviceId !== savedDeviceId) {
        setSavedDeviceId(deviceId)

        if (timeoutId) {
          clearTimeout(timeoutId) // clear timeout when switching charts
        }

        if (deviceId) {
          getTelemetryEveryMinute(deviceId)
        }
      }

      setOpen(isCollapsed)

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId) // clear timeout after leaving the page
        }
      }
    }
  }, [
    memorizedDrawGauge,
    deviceId,
    telemetry,
    timeoutId,
    isCollapsed,
    savedDeviceId,
    getCurrentData,
    setOpen,
    requestsAdapter,
  ])

  /**
   * Draw gauges with state data,
   * if device is not active, display 0 on pm and voc gauges
   */
  const drawGauge = (telemetryValue: DeviceTelemetry | undefined, userPreferencesValue: UserPreference) => {
    if (!telemetryValue) {
      return
    }

    const { pm_isMc, voc_isMc } = userPreferencesValue
    const {
      pm_mc, pm_count, voc_mc, voc_count, humidity,
    } = telemetryValue

    gaugeService.pmGauge(pm_isMc ? Number(pm_mc.toFixed(1)) : Number(pm_count.toFixed(1)), pm_isMc)
    gaugeService.vocGauge(voc_isMc ? Number(voc_mc.toFixed(1)) : Number(voc_count.toFixed(1)), voc_isMc)
    gaugeService.rhGauge(Number(humidity.toFixed(1)))
  }

  /**
   * Convert Celsius to Fahrenheit if userPreference is Fahrenheit
   * @param temperature - number
   * @param isFahrenheit - boolean
   */
  const getConvertedTemperature = (temperature: number | undefined, isFahrenheit: boolean) => {
    if (temperature !== undefined) {
      if (isFahrenheit) {
        return tempService.convertToFahrenheit(temperature)
      } else {
        return temperature
      }
    }
  }

  /**
   * callback fn for clicking on toggle icon to expand or collapse current status
   */
  const handleToggle = () => {
    if (mixpanel) {
      mixpanel.track('pp_customerDrillDownPage_currentStatus_toggleIconButton')
    }
    setToggleOpen(!isCollapsed)
  }

  const temperatureConverted = getConvertedTemperature(
    telemetry?.temperature,
    userPreferences.temperature_isFahrenheit,
  )

  const statusColor = (str: string) => {
    switch (str) {
      case 'good': return '#39DD7B'
      case 'fair': return '#FFC700'
      case 'poor': return '#F96C6C'
      default: return '#888'
    }
  }

  const zone = useCurrentZone()
  const contents = useCurrentDwellingContents()

  const ecosense_devices = contents?.ecosense_devices
    .filter(x => x.zone.toLowerCase() === zone.toLowerCase()) || []

  const latestEcosense = useEcosenseStore(selectLatest(ecosense_devices.map(x => x.serial_number)))

  return (
    <Box
      id={CHARTS_PAGE_TOUR_SELECTORS.DEVICE_CURRENT_STATUS}
      className={isCollapsed ? 'collapsed' : 'expanded'}
    >
      {hasData && (
        <>
          <Box
            display="flex"
            alignItems="center"
            pt='10px'
            pb={4}
          >
            <Typography
              variant="h5">Current {!isCollapsed && 'Status'} </Typography>
            {currentStatusTooltip}
          </Box>
          <Grid
            id="status-box-container"
            container
            spacing={isCollapsed ? 3 : 1}
          >
            <Grid
              item
              sm={isCollapsed ? 12 : 6}
              className="status-box"
            >
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography
                  variant="caption"
                  className="status-label-sm">
                  HVAC FAN
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="h5"
                    className={!isActive ? 'status-inactive' : ''}
                  >
                    {isActive ? 'Active' : 'Inactive'}
                  </Typography>
                  {!isActive && currentStatusWarningTooltip}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              sm={isCollapsed ? 12 : 6}
              className="status-box"
            >
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography
                  className="status-label-sm"
                  variant="caption"
                >
                  LAST READING
                </Typography>
                {isActive &&
                  <Typography variant="h5">Just now</Typography>}
                {!isActive && (
                  <Typography variant="h5">
                    {telemetry && telemetry.timestamp ?
                      new Date(telemetry.timestamp).toLocaleString() :
                      'Not available'}
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid
              item
              sm={isCollapsed ? 12 : 6}
              className={isActive ? 'status-box' : 'status-box fan-inactive'}
            >
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography variant="caption" className="status-label-sm">
                  PM
                </Typography>
                {telemetry && !isCollapsed && (
                  <Typography variant="h5">
                    <b>
                      {userPreferences.pm_isMc ?
                        telemetry.pm_mc.toFixed(1) + 'μg/m³' :
                        telemetry.pm_count.toFixed(1) + 'count/m³'}
                    </b>
                  </Typography>
                )}
                <Box
                  m={'0 auto'}
                >
                  {telemetry?.pm_status && (
                    <>
                      <div id="pm_div" />
                      <Typography
                        variant="h5"
                        style={{
                          color: statusColor(telemetry?.voc_status), 
                        }}
                      >
                        {capitalize(telemetry?.pm_status)}
                      </Typography>
                    </>
                  )}
                  {!telemetry?.pm_status &&
                    <Typography variant="h5">N/A</Typography>}
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={isCollapsed ? 12 : 6} className={isActive ? 'status-box' : 'status-box fan-inactive'}>
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography variant="caption" className="status-label-sm">
                  VOC
                </Typography>
                {telemetry && !isCollapsed && (
                  <Typography variant="h5">
                    <b>
                      {userPreferences.voc_isMc ?
                        telemetry.voc_mc.toFixed(1) + 'μg/m³' :
                        telemetry.voc_count.toFixed(1) + 'ppb'}
                    </b>
                  </Typography>
                )}
                <Box m={'0 auto'}>
                  {telemetry?.voc_status && (
                    <>
                      <div id="voc_div" />
                      <Typography
                        variant="h5"
                        style={{
                          color: statusColor(telemetry?.voc_status), 
                        }}
                      >
                        {capitalize(telemetry?.voc_status)}
                      </Typography>
                    </>
                  )}
                  {!telemetry?.voc_status && <Typography variant="h5">N/A</Typography>}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              sm={isCollapsed ? 12 : 6}
              className={isActive ? 'status-box' : 'status-box fan-inactive'}
            >
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography variant="caption" className="status-label-sm">
                  RH
                </Typography>
                {telemetry?.humidity !== undefined && !isCollapsed && (
                  <Typography variant="h5">
                    <b>{telemetry?.humidity.toFixed(1)}%</b>
                  </Typography>
                )}
                {telemetry?.humidity !== undefined && isCollapsed && (
                  <Typography variant="h5" gutterBottom>
                    {telemetry?.humidity.toFixed(1)}%
                  </Typography>
                )}
                {telemetry?.humidity === undefined && <Typography variant="h5">N/A</Typography>}
                <Box m={'0 auto'}>{telemetry?.humidity !== undefined && <div id="rh_div" />}</Box>
              </Paper>
            </Grid>

            <Grid
              item
              sm={isCollapsed ? 12 : 6}
              className={isActive ? 'status-box' : 'status-box fan-inactive'}>
              <Paper
                style={{
                  borderRadius: 10,
                  padding: isCollapsed ? '0' : '0.5em 0.75em',
                }}
              >
                <Typography variant="caption" className="status-label-sm">
                  TEMP
                </Typography>
                {!isCollapsed && temperatureConverted !== undefined && (
                  <Typography variant="h5">
                    <b>
                      {temperatureConverted?.toFixed(1)}
                      <sup>o</sup>
                      {userPreferences.temperature_isFahrenheit ? 'F' : 'C'}
                    </b>
                  </Typography>
                )}
                {isCollapsed && temperatureConverted !== undefined && (
                  <Typography variant="h5" gutterBottom>
                    {temperatureConverted?.toFixed(1)}
                    <sup>o</sup>
                    {userPreferences.temperature_isFahrenheit ? 'F' : 'C'}
                  </Typography>
                )}
                {telemetry?.temperature !== undefined && (
                  <Box
                    id="temp_div"
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    <Thermometer
                      width="60px"
                      height="120px"
                      steps={5}
                      minValue={0}
                      maxValue={100}
                      currentValue={temperatureConverted}
                    />
                  </Box>
                )}
                {telemetry?.temperature === undefined && <Typography variant="h5">N/A</Typography>}
              </Paper>
            </Grid>
            {Object.keys(latestEcosense).map((key, index) => (
              <Grid
                item
                key={key}
                sm={isCollapsed ? 12 : 6}
                className={isActive ? 'status-box' : 'status-box fan-inactive'}>
                <Paper
                  style={{
                    borderRadius: 10,
                    padding: isCollapsed ? '0' : '0.5em 0.75em',
                  }}
                >
                  <Typography variant="caption" className="status-label-sm">
                    Radon ({ecosense_devices[index]?.name})
                  </Typography>
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <b>
                        {latestEcosense[key]?.radon || '-'}
                      </b>
                    </Typography>
                  )}
                  {isCollapsed && (
                    <Typography variant="h5" gutterBottom>
                      {latestEcosense[key]?.radon || '-'}
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Button id="collapse-icon-btn" color="primary" variant="contained" onClick={handleToggle}>
            {isCollapsed && <ChevronRightIcon />}
            {!isCollapsed && <ChevronLeftIcon />}
          </Button>
        </>
      )}
      {!hasData && (
        <>
          <Box p={2} m={2} width="100%">
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Data not available, please check back at a later time
            </Alert>
          </Box>
          <Button
            id="collapse-icon-btn"
            color="primary"
            variant="contained"
            onClick={handleToggle}>
            {isCollapsed && <ChevronRightIcon />}
            {!isCollapsed && <ChevronLeftIcon />}
          </Button>
        </>
      )}
    </Box>
  )
}

export default DeviceCurrentStatus
