import React, { useState } from 'react'
import {
  persistTemporaryRule,
  resetTemporaryRule,
  selectEquipments,
  selectOutputsByRule,
  selectRule,
  useEquipmentStore,
  useRulesStore,
} from './store'
import Override from './override'
import AutomationInputControl from './input'
import RuleTitle from './ruleTitle'
import { Typography } from '@material-ui/core'
import Stack from 'ui/stack'
import { AutomationPillar, AutomationRuleGroup } from 'types'
import Dot from './dot'
import AutomationRuleCard from './automation-rule-card'
import InnerCard from './inner-card'
import Footer from './footer'
import useSaveAutomation from './useSaveAutomation'
import useIsTouched from './useIsTouched'
import Rule from './rule'
import { useResetAfterTimeout } from './useRuleState'

const useEquipmentsByRule = (ruleId) => {
  const outputs = useRulesStore(selectOutputsByRule(ruleId))
  return useEquipmentStore(selectEquipments(outputs?.map(x => x.equipment_id) || []))
}

const RuleGroup = ({ ruleGroup, pillar }: { ruleGroup: AutomationRuleGroup, pillar: AutomationPillar }) => {

  const rootRuleId = ruleGroup.rules[0]?.id
  const nestedRuleId = ruleGroup.rules[1]?.id

  const rootRule = useRulesStore(selectRule(rootRuleId))
  const nestedRule = useRulesStore(selectRule(nestedRuleId))

  const rootEquipment = useEquipmentsByRule(rootRule?.id)
  const nestedOutputs = nestedRule?.outputs.filter(x => !ruleGroup.rules[0]?.outputs.includes(x.id)) || []
  const nestedEquipment = useEquipmentStore(selectEquipments(nestedOutputs.map(x => x.equipment_id)))

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  useResetAfterTimeout([success, error], [setSuccess, setError])

  const rootTouchd = useIsTouched(rootRuleId)
  const nestedTouched = useIsTouched(nestedRuleId)

  const saveRootAutomation = useSaveAutomation(rootRule.automationId)
  const saveNestedAutomation = useSaveAutomation(nestedRule?.automationId)

  const handleCancel = () => {
    resetTemporaryRule(rootRuleId)
    resetTemporaryRule(nestedRuleId)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await Promise.all([
        saveRootAutomation(),
        saveNestedAutomation(),
      ])
      persistTemporaryRule(rootRuleId)
      persistTemporaryRule(nestedRuleId)
      setSuccess('Saved ✓')
    } catch (e) {
      console.error(e)
      setError('Couldn\'t save')
    }
    setLoading(false)
  }

  const touched = rootTouchd || nestedTouched

  if (!nestedRuleId) return <Rule ruleId={rootRule.id} pillar={pillar} />

  return (
    <AutomationRuleCard>
      <Stack spacing={4}>
        <Stack spacing={1}>
          <Stack direction='row'>
            <RuleTitle>
              Activate
            </RuleTitle>
            <Override
              automationId={rootRule.automationId}
              pillar={pillar}
              ruleId={rootRule.id}
            />
          </Stack>
          <InnerCard>
            <Stack divider>
              {Object.values(rootEquipment || {
              }).map((x, i) => (
                <Stack
                  key={x.id}
                  direction='row'>
                  <Typography variant='body1'>
                    {x?.name}
                  </Typography>
                  <Dot active={rootRule.outputs[i].currently_triggered} />
                </Stack>
              ))}
            </Stack>
          </InnerCard>
        </Stack>
        {ruleGroup.sharedInputs.map((input, index) => (
          <Stack
            key={input}
            spacing={1}
          >
            <RuleTitle>
              {(index > 0) ? 'And' : 'When'}
            </RuleTitle>
            <AutomationInputControl
              ruleId={rootRuleId}
              inputId={input} />
          </Stack>
        ))}
        <InnerCard>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Stack direction='row'>
                <RuleTitle>
                  And activate
                </RuleTitle>
              </Stack>
              <InnerCard>
                <Stack divider>
                  {Object.values(nestedEquipment || {
                  }).map((x, i) => (
                    <Stack
                      key={x.id}
                      direction='row'>
                      <Typography variant='body1'>
                        {x?.name}
                      </Typography>
                      <Dot active={nestedRule.outputs[i].currently_triggered} />
                    </Stack>
                  ))}
                </Stack>
              </InnerCard>
            </Stack>
            {nestedRule.inputs.filter(x => !ruleGroup.sharedInputs.includes(x.id)).map((input, index) => (
              <Stack
                key={input.id}
                spacing={1}
              >
                <RuleTitle>
                  {(index > 0) ? 'And' : 'When'}
                </RuleTitle>
                <AutomationInputControl
                  ruleId={nestedRule.id}
                  inputId={input.id} />
              </Stack>
            ))}
          </Stack>
        </InnerCard>
        <Footer
          touched={touched}
          loading={loading}
          success={success}
          error={error}
          handleCancel={handleCancel}
          handleSave={handleSave}
        />
      </Stack>
    </AutomationRuleCard>
  )
}

export default RuleGroup
