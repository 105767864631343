import React, {
  useCallback, useEffect, useMemo, useState, 
} from 'react'
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { RootState } from 'state-mngt/store'
import { dataTypeSelections, TelemetryDataType, TypeSelection } from 'utils/constants/telemetry-data-type'
import 'features/customer-drill-down/charts/customer-history/customer-history.scss'
import { customerHistoryTooltip } from 'utils/constants/tooltip-content'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import CollectionsIcon from '@material-ui/icons/Collections'
import BorderRightIcon from '@material-ui/icons/BorderRight'
import BorderLeftIcon from '@material-ui/icons/BorderLeft'
import HistoryTimeControl from 'features/customer-drill-down/charts/customer-history/history-time-control'
import { getMillisecondsForTimeFrame } from 'utils/time-utils'
import { setDeviceDataSetting } from 'state-mngt/actions/device-actions'
import { getUserDemoActiveDemo, getUserDemoActiveStep, getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { UserDemoName, UserPreference } from 'state-mngt/models/user'
import { useStateInURLQueryParameter } from 'utils/hooks/router'
import { TELEMETRY_THRESHOLD } from 'utils/constants/telemetry-threshold'
import { Timeframe } from 'utils/constants/time-interval'
import { DemoHintElementName } from 'state-mngt/models/user'
import { hideHintElement } from 'state-mngt/actions/user-actions'
import { getHintElement } from 'state-mngt/selectors/user-selectors'
import TourTooltip from 'ui/tour-tooltip'
import { CONTROLS_WIDTH, DeviceChart } from 'features/customer-drill-down/charts/device-chart'
import {
  CUSTOMER_HISTORY_CONTAINER_ID,
} from 'features/customer-drill-down/charts/device-chart/chart-utils'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import HistoryPieCharts from './historyPieCharts'
import useEcosenseDevicesInCurrentDwellingZone from 'utils/hooks/useEcosenseDevicesInCurrentDwellingZone'

const overlayOptions = ['Thresholds', 'Airflow inactive']

const getChartAnnotations = (
  demoName: UserDemoName | null,
  demoStep: number | null,
  userPreference: UserPreference,
): any => {
  if (demoName === null || demoStep === null) {
    return null
  }
  switch (demoName) {
    case UserDemoName.Demo1: {
      const getMaxPoint = (series: any[]) => {
        const pmSeries = series.find((item) => item.name === 'PM')
        if (pmSeries) {
          if (pmSeries.data.length === 0) {
            return null
          }
          const maxDataPoint = pmSeries.data.reduce((acc: { x: number; y: number }, [x, y]: [number, number]) => {
            if (y > acc.y) {
              return {
                x,
                y,
              }
            }
            return acc
          }, {
            x: pmSeries.data[0][0] as number,
            y: pmSeries.data[0][1] as number,
          })
          return {
            ...maxDataPoint,
            xAxis: 0,
            yAxis: 0,
          }
        }
        return null
      }

      const getFirstPoint = (series: any[]) => {
        const pmSeries = series.find((item) => item.name === 'PM')
        if (pmSeries) {
          const firstDataPoint = pmSeries.data[0]
          if (firstDataPoint) {
            return {
              x: firstDataPoint[0],
              y: firstDataPoint[1],
              xAxis: 0,
              yAxis: 0,
            }
          }
        }
        return null
      }

      const getChronicIssues = (series: any[], maxValue: number) => {
        const pmSeries = series.find((item) => item.name === 'PM')
        if (pmSeries) {
          const pointsWithIssues = pmSeries
            .data
            .reduce((acc: Array<{ x: number; y: number; xAxis: number; yAxis: number; }>, [x, y]: [number, number]) => {
              if (y > maxValue) {
                return acc.concat({
                  x,
                  y,
                  xAxis: 0,
                  yAxis: 0,
                })
              }
              return acc
            }, [])
          return pointsWithIssues
        }
        return []
      }

      const getChronicIssuesInHour = (series: any[], maxValue: number, inHour: number) => {
        const pmSeries = series.find((item) => item.name === 'PM')
        if (pmSeries) {
          const pointsWithIssues = pmSeries
            .data
            .reduce((acc: Array<{ x: number; y: number; xAxis: number; yAxis: number; }>, [x, y]: [number, number]) => {
              const hour = new Date(x).getHours()
              const minutes = new Date(x).getMinutes()
              if (minutes === 0 && y > maxValue && hour === inHour) {
                return acc.concat({
                  x,
                  y,
                  xAxis: 0,
                  yAxis: 0,
                })
              }
              return acc
            }, [])
          return pointsWithIssues
        }
        return []
      }

      if (demoStep === 1) {
        return {
          getLabels: (series: any) => {
            const maxPoint = getMaxPoint(series)
            if (maxPoint) {
              return [{
                point: maxPoint,
                text: 'Large PM2.5 event',
                align: 'right',
              }]
            }
            return []
          },
          getShapes: (series: any) => {
            const maxPoint = getMaxPoint(series)
            if (maxPoint) {
              return [{
                point: maxPoint,
                type: 'circle',
              }]
            }
            return []
          },
        }
      }
      if (demoStep === 2) {
        return {
          getLabels: (series: any) => {
            const firstPoint = getFirstPoint(series)
            const result = []
            if (firstPoint) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: firstPoint,
                // @ts-ignore
                text: 'Monitor installed',
              })
            }
            const chronicIssues = getChronicIssues(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcFair : TELEMETRY_THRESHOLD.pmCountFair,
            )
            if (chronicIssues[0]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[0],
                // @ts-ignore
                text: 'Chronic PM2.5 issues',
              })
            }
            return result
          },
          getShapes: (series: any) => {
            const firstPoint = getFirstPoint(series)
            const result = []
            if (firstPoint) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: firstPoint,
                // @ts-ignore
                type: 'circle',
              })
            }
            const chronicIssues = getChronicIssues(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcFair : TELEMETRY_THRESHOLD.pmCountFair,
            )
            if (chronicIssues[0]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[0],
                // @ts-ignore
                type: 'circle',
              })
            }
            if (chronicIssues[1]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[1],
                // @ts-ignore
                type: 'circle',
              })
            }
            return result
          },
        }
      }
      if (demoStep === 4 || demoStep === 5) {
        return {
          getLabels: (series: any) => {
            const chronicIssues = getChronicIssuesInHour(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcPoor : TELEMETRY_THRESHOLD.pmCountPoor,
              18,
            )
            const result = []
            chronicIssues.sort((a: any, b: any) => a.y - b.y)
            const maxPoint = chronicIssues[0]
            if (maxPoint) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: maxPoint,
                // @ts-ignore
                text: 'Unblocking the range hood duct',
              })
            }
            return result
          },
          getShapes: (series: any) => {
            const result: any[] = []
            const chronicIssues = getChronicIssuesInHour(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcPoor : TELEMETRY_THRESHOLD.pmCountPoor,
              18,
            )
            chronicIssues.forEach((item: any) => {
              result.push({
                point: item,
                // @ts-ignore
                type: 'circle',
              })
            })
            return result
          },
        }
      }
      if (demoStep === 6) {
        return {
          getLabels: (series: any) => {
            const chronicIssues = getChronicIssuesInHour(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcFair * 0.8 : TELEMETRY_THRESHOLD.pmCountFair * 0.8,
              18,
            )
            const result = []
            chronicIssues.sort((a: any, b: any) => a.y - b.y)
            const maxPoint = chronicIssues[0]
            if (maxPoint) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: maxPoint,
                // @ts-ignore
                text: 'Venting effectively',
              })
            }
            return result
          },
          getShapes: (series: any) => {
            const result = []
            const chronicIssues = getChronicIssuesInHour(
              series,
              userPreference.pm_isMc ? TELEMETRY_THRESHOLD.pmMcFair * 0.8 : TELEMETRY_THRESHOLD.pmCountFair * 0.8,
              18,
            )
            if (chronicIssues[0]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[0],
                // @ts-ignore
                type: 'circle',
              })
            }
            if (chronicIssues[1]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[1],
                // @ts-ignore
                type: 'circle',
              })
            }
            if (chronicIssues[2]) {
              // @ts-ignore
              result.push({
                // @ts-ignore
                point: chronicIssues[2],
                // @ts-ignore
                type: 'circle',
              })
            }
            return result
          },
        }
      }
      return null
    }
    default: {
      return null
    }
  }
}

interface BasePropos {
  isCurrentStatusCollapsed: boolean;
  isSidebarCollapsed: boolean;
}

/**
 * Display telemetry range data interval selector and chart
*/
const CustomerHistoryTemplate = (props: BasePropos) => {
  const { isCurrentStatusCollapsed, isSidebarCollapsed } = props

  const dispatch = useAppDispatch()

  const userPreferences = useAppSelector(getUserPreferences)
  const demoName = useAppSelector(getUserDemoActiveDemo)
  const demoStep = useAppSelector(getUserDemoActiveStep)
  const dataTypeHint = useAppSelector((state: RootState) => getHintElement(state, DemoHintElementName.DataType))
  const secondDataTypeHint = useAppSelector(
    (state: RootState) => getHintElement(state, DemoHintElementName.SecondDataType),
  )

  const [startTime, setStartTime] = useStateInURLQueryParameter<Date>({
    key: HISTORY_QUERY_KEYS.startTime,
    initialValue: new Date(getMillisecondsForTimeFrame(Timeframe.Month)),
    fromQuery: (value) => new Date(parseInt(value)),
    toQuery: (value) => value.valueOf().toString(),
    replace: true,
    initializeInTheUrl: true,
  })

  const [endTime, setEndTime] = useStateInURLQueryParameter<Date>({
    key: HISTORY_QUERY_KEYS.endTime,
    initialValue: new Date(),
    fromQuery: (value) => new Date(parseInt(value)),
    toQuery: (value) => value.valueOf().toString(),
    replace: true,
    initializeInTheUrl: true,
  })

  const [dataType, setDataType] = useStateInURLQueryParameter<TelemetryDataType>({
    key: HISTORY_QUERY_KEYS.dataType,
    initialValue: TelemetryDataType.PM,
    fromQuery: (dataType) => dataType as TelemetryDataType,
    replace: true,
    initializeInTheUrl: true,
  })

  const [secondDataType, setSecondDataType] = useStateInURLQueryParameter<TelemetryDataType | ''>({
    key: HISTORY_QUERY_KEYS.secondDataType,
    initialValue: '',
    fromQuery: (queryValue) => queryValue as TelemetryDataType,
    replace: true,
    initializeInTheUrl: true,
  })

  const [selectedOverlays, setSelectedOverlays] = useStateInURLQueryParameter<string[]>({
    key: HISTORY_QUERY_KEYS.selectedOverlays,
    initialValue: ['Thresholds'],
    fromQuery: (queryValue) => queryValue.split(','),
    toQuery: (stateValue) => stateValue.join(','),
    replace: true,
    initializeInTheUrl: true,
  })

  const renderContainerId = CUSTOMER_HISTORY_CONTAINER_ID.realCustomer

  const [timeframe, setTimeframe] = useState<Timeframe>(Timeframe.Month)
  const [_dataTypeSelections, setDataTypeSelections] = useState<TypeSelection[]>(dataTypeSelections)

  const currentZone = useCurrentZone()
  const ecosenseDevices = useEcosenseDevicesInCurrentDwellingZone()

  useEffect(() => {
    const _ecosenseInZone = ecosenseDevices || []

    setDataTypeSelections([
      ...dataTypeSelections,
      ..._ecosenseInZone.map(x => ({
        type: x.serial_number,
        label: `Radon - ${x.name}`,
      })),
    ])
  }, [
    ecosenseDevices?.map(x => x.serial_number).join(''),
    currentZone,
  ])

  // set device data setting in store to be used later for zoom out
  useEffect(() => {
    dispatch(setDeviceDataSetting({
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf(),
      secondType: secondDataType,
      selectedOverlayOptions: selectedOverlays,
    }))
  }, [
    dispatch,
    startTime,
    endTime,
    secondDataType,
    selectedOverlays,
  ])

  const annotations = useMemo(
    () => getChartAnnotations(demoName, demoStep, userPreferences),
    [
      demoName,
      demoStep,
      JSON.stringify(userPreferences),
    ],
  )

  /**
   * callback function to save changed data type value in state and dispatch new device setting to store
   * @param event change event for radio group
   */
  const handleDataTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target && event.target.value !== dataType) {
      setDataType(event.target.value as TelemetryDataType)

      // if the first type chose is the same as the second, set second to ''
      if (event.target.value === secondDataType) {
        setSecondDataType('')
      }
    }
  }

  /**
   * callback function to save changed right-axis data type value in state and dispatch new device setting to store
   * @param event change event for radio group
   */
  const handleSecondDataTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target && event.target.value !== secondDataType) {
      setSecondDataType(event.target.value as TelemetryDataType)
    }
  }

  /**
   * Handle overlay multi-select value change
   * @param event - ChangeEvent
   */
  const handleOverlayChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target) {
      const value = event.target.value as string[]
      setSelectedOverlays(value.filter((item) => !!item))
    }
  }

  const _setStartTime = useCallback(setStartTime, [])
  const _setEndTime = useCallback(setEndTime, [])

  /**
   * callback function for any change in start time, end time, or timeframe: calls API to fetch range data based on 
   * these inputs calls API with selected start time and end time
   */
  const onChangeTimeframe = useCallback((timeframe: Timeframe) => {
    setTimeframe(timeframe)
    const startMillisecond = getMillisecondsForTimeFrame(timeframe)
    const startTime = new Date(startMillisecond)
    const endTime = new Date()

    if (endTime.getTime() < startTime.getTime()) {
      return
    }

    _setStartTime(startTime) // set time in parent component
    _setEndTime(endTime) // set time in parent component
  }, [])

  return (
    <Box
      mx={1}
      id={renderContainerId}
    >
      <Box
        display="flex"
        alignItems='center'
        id="history-time-control"
        pt={1}>
        {(
          <>
            <Box
              display="flex"
              alignItems='center'
              flex='1'
            >
              <Typography variant="h5">History</Typography>
              {customerHistoryTooltip}
            </Box>
            <HistoryTimeControl
              timeframe={timeframe}
              onChangeTimeframe={onChangeTimeframe}
              setStart={_setStartTime}
              setEnd={_setEndTime}
              startTime={startTime}
              endTime={endTime}
            />
          </>
        )}
      </Box>
      <Box
        my={4}
        id="history-charts"
      >
        {
          <Grid
            container
            justifyContent='flex-start'
          >
            <HistoryPieCharts
              startTime={startTime}
              endTime={endTime}
            />
          </Grid>
        }
        <Box
          display='flex'
          mt={4}
        >
          <Box
            mt={3}
          >
            <Typography
              variant="subtitle2"
            >Chart parameters</Typography>
            <br />
            <Box display="flex">
              <TourTooltip
                title={dataTypeHint ? dataTypeHint.text : ''}
                placement="top-start"
                onClose={() => dispatch(hideHintElement(DemoHintElementName.DataType))}
                arrow
                open={!!dataTypeHint}
              >
                <FormControl variant="outlined"
                  style={{
                    marginBottom: '1rem',
                    width: `${CONTROLS_WIDTH}px`,
                  }} size="small">
                  <InputLabel id="data-type-select-label-left">Left Axis</InputLabel>
                  <Select
                    label="Left Axis"
                    labelId="data-type-select-label-left"
                    id="data-type-select-left"
                    value={dataType}
                    onChange={handleDataTypeChange}
                    startAdornment={
                      <InputAdornment position='start'>
                        <BorderLeftIcon color="primary"
                          style={{
                            marginLeft: 0,
                          }} />
                      </InputAdornment>
                    }
                  >
                    {_dataTypeSelections.map((menuItem: TypeSelection, index) =>
                      <MenuItem value={menuItem.type} key={'first-type-menu-' + index}>{menuItem.label}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
              </TourTooltip>
            </Box>
            <Box
              display="flex"
            >
              <TourTooltip
                title={secondDataTypeHint ? secondDataTypeHint.text : ''}
                placement="bottom-start"
                onClose={() => dispatch(hideHintElement(DemoHintElementName.SecondDataType))}
                arrow
                open={!!secondDataTypeHint}
              >
                <FormControl
                  variant="outlined"
                  style={{
                    minWidth: '180px',
                    marginBottom: '1rem',
                    width: `${CONTROLS_WIDTH}px`,
                  }}
                  size="small">
                  <InputLabel shrink
                    id="data-type-select-label-right"
                    style={{
                      backgroundColor: 'white',
                      padding: '0 4px',
                    }}>Right Axis</InputLabel>
                  <Select
                    label="Right Axis"
                    labelId="data-type-select-label-right"
                    id="data-type-select-right"
                    value={secondDataType}
                    onChange={handleSecondDataTypeChange}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        <BorderRightIcon color="primary"
                          style={{
                            marginLeft: 0,
                          }} />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value=''>None</MenuItem>
                    {_dataTypeSelections.filter(s => s.type !== dataType).map((menuItem, index) =>
                      <MenuItem value={menuItem.type} key={'second-type-menu-' + index}>{menuItem.label}</MenuItem>,
                    )}
                  </Select>
                </FormControl>
              </TourTooltip>
            </Box>
            <Box display="flex">
              <FormControl variant="outlined"
                style={{
                  width: `${CONTROLS_WIDTH}px`,
                }} size="small">
                <InputLabel shrink
                  htmlFor="chart-overlay-select"
                  style={{
                    backgroundColor: 'white',
                    padding: '0 4px',
                  }}>
                  Chart Overlays
                </InputLabel>
                <Select
                  inputProps={{
                    id: 'chart-overlay-select',
                  }}
                  multiple
                  displayEmpty
                  value={selectedOverlays}
                  onChange={handleOverlayChange}
                  renderValue={(selected) => {
                    if ((selected as string[]).length === 0) {
                      return 'None'
                    }
                    return (selected as string[]).join(', ')
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  startAdornment={
                    <InputAdornment position='start'>
                      <CollectionsIcon color="primary"
                        style={{
                          marginLeft: 0,
                        }} />
                    </InputAdornment>
                  }
                >
                  {overlayOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={selectedOverlays.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* } */}
          </Box>
          <DeviceChart
            containerId={renderContainerId}
            isCurrentStatusCollapsed={isCurrentStatusCollapsed}
            isSidebarCollapsed={isSidebarCollapsed}
            interactive={true}
            offsetLeft={140}
            annotations={annotations}
            dataType={dataType}
            secondDataType={secondDataType}
            setTimeframe={setTimeframe}
            setStartTime={_setStartTime}
            setEndTime={_setEndTime}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerHistoryTemplate
