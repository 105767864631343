import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { CustomerDetailsPageIntroDialog } from 'features/product-tour/customer-details-page/index'
import { useTour } from '@reactour/tour'
import { ExitTourDialog } from 'features/product-tour/exit-tour-dialog'
import { PRODUCT_TOUR_QUERY_KEYS, ProductTourPageName } from 'features/product-tour/pro-portal-tour'


export interface CustomerDetailsPageTourProps {
  startTour: boolean;
  onEndTour?: () => void;
}

/**
 * A component that controls the UI workflow for the product tour on the customer details page. It only contains UI logic.
 * @param props CustomerDetailsPageTourProps
 */
export const CustomerDetailsPageTour = (props: CustomerDetailsPageTourProps): JSX.Element => {
  const { startTour, onEndTour } = props

  const navigate = useNavigate()

  const {
    setIsOpen, setCurrentStep, setSteps, steps,
  } = useTour()

  const [isIntroDialogOpen, setIsIntroDialogOpen] = useState(false)
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false)


  // Display Intro Dialog whenever tour starts or hide when tour ends.
  useEffect(() => {
    setIsIntroDialogOpen(startTour)
  }, [startTour])

  // There are no steps for this page.
  const handleCustomerDetailsPageIntroDialogContinue = () => {
    handleExitTourDialogExit()
    navigate({
      pathname: ROUTE_PATHS.resources.root.absolute,
      search: `&${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Resources}`,
    })
  }

  const handleCloseStep = () => {
    setIsOpen(false)
    setIsIntroDialogOpen(false)
    setIsExitDialogOpen(true)
  }

  const handleExitTourDialogContinue = () => {
    setIsExitDialogOpen(false)

    // If tour steps are greater than 0 it means tour has already started. Otherwise, the intro dialog was being displayed.
    if (steps.length > 0) {
      setIsOpen(true)
    } else {
      setIsIntroDialogOpen(true)
    }
  }

  const handleExitTourDialogExit = () => {
    setIsExitDialogOpen(false)
    setIsIntroDialogOpen(false)

    setSteps([])
    setCurrentStep(0)
    setIsOpen(false)

    if (onEndTour) {
      onEndTour()
    }
  }


  return (
    <>
      <CustomerDetailsPageIntroDialog
        isOpen={isIntroDialogOpen}
        onContinue={handleCustomerDetailsPageIntroDialogContinue}
        onClose={handleCloseStep} />

      <ExitTourDialog
        isOpen={isExitDialogOpen}
        onContinueTour={handleExitTourDialogContinue}
        onExitTour={handleExitTourDialogExit} />
    </>
  )
}
