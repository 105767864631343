import PhoneNumberInput from 'react-phone-input-material-ui'
import {
  Input,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { theme } from 'theme'

// const useStyles = makeStyles(() =>
//   createStyles({
//     helperText: {
//       '&:after': {
//         borderBottomColor: theme.palette.secondary.main + ' !important'
//       },
//       '& .MuiInput-underline.Mui-error:after': {
//         borderBottomColor: theme.palette.secondary.main + ' !important'
//       },
//       '& .MuiFormHelperText-root': {
//         color: theme.palette.secondary.main
//       }
//     }
//   })
// );

const PhoneInput = (props: {
  inputValue: string,
  name: string,
  id: string,
  touched: boolean,
  fullWidth: boolean,
  onPhoneValueChange: (value: string) => void,
  onPhoneErrorChange: (err: boolean) => void
}) => {
  const {
    inputValue, onPhoneValueChange, onPhoneErrorChange, name, id, touched, fullWidth,
  } = props
  const [phoneError, setPhoneError] = useState<string>(inputValue === '' && touched ? 'Phone number is required' : '')

  // const classes = useStyles();

  /**
     * Validate phone number field
     * Set value if valid
     * Set error message if format is not valid or empty
     * @param event - ChangeEvent
     */
  const handlePhoneChange = (value: any) => {

    setPhoneError('')
    onPhoneValueChange(value)
    onPhoneErrorChange(false)

    if (value === '') {
      setPhoneError('Phone number is required')
      onPhoneErrorChange(true)
      return
    }

    if (value.length < 10) {
      setPhoneError('Invalid phone number')
      onPhoneErrorChange(true)
      return
    }
  }

  return (
    <>
      <PhoneNumberInput
        inputProps={{
          id,
          name,
          placeholder: 'Phone*',
        }}
        defaultMask='(...) ... ....'
        alwaysDefaultMask
        value={inputValue}
        onChange={handlePhoneChange}
        disableCountryCode
        // @ts-ignore
        component={Input}
      />
      {/* <InputComp
        fullWidth={fullWidth}
        id={id}
        name={name}
        value={inputValue}
        // @ts-ignore
        onChange={handlePhoneChange}
        // @ts-ignore
        inputComponent={forwardRef((props, ref) => <PhoneNumberInput
          country='CA'
          international={false}
          withCountryCallingCode={true}
          ref={ref}
          {...props}
        />)}
        error={phoneError !== ''}
        className={classes.helperText}
      /> */}
      {phoneError !== '' &&
        <Typography
          color="secondary"
          style={{
            fontSize: '.75rem',
          }}
        >
          {phoneError}
        </Typography>
      }
    </>
  )
}

export default PhoneInput
