import config from 'config'
import { CancelableFetch, HavenApiErrorCode, HttpError as HError } from 'state-mngt/models/http'
import { ErrorType } from 'state-mngt/models/error'
import store from 'state-mngt/store'
import { setError } from 'state-mngt/actions/error-actions'


/**
 * It sends a fetch request to a resource from the network, returning a promise which is fulfilled once the response
 * is available. The promise resolves to the Response object representing the response to your request.
 * The promise does not reject on HTTP errors — it only rejects on network errors.
 * The promise returned from the fetch request is encapsulated into the CancelableFetch interface returned from this function.
 * @param url The URL of the resource you want to fetch.
 * @param params An object containing any custom settings that you want to apply to the fetch request function.
 * Check the fetch request function documentation to know the defined properties.
 * @returns CancelableFetch - A object containing a Promise<Response> and an AbortController object instance that
 * allows to abort this fetch request.
 */
const cancelableFetch = (url: string, params: any): CancelableFetch => {
  const abortController = new AbortController()

  return {
    promise: new Promise<Response>((resolve, reject) => {
      fetch(url, {
        ...params,
        signal: abortController.signal,
      })
        .then((result) => resolve(result as Response))
        .catch((e: Error) => reject(e))
    }),
    controller: abortController,
  }
}


/**
 * Class representing ANY type of error occurred from HTTP network requests.
 * Check the interface HavenApiErrorCode for possible errors.
 */
export class HttpError implements HError {
  readonly code: HavenApiErrorCode
  readonly message: string

  constructor(code: HavenApiErrorCode, message = '') {
    this.code = code
    this.message = message
  }
}

const reqs = new Map()
export class HTTPService {
  private async handleResponse(response: Response): Promise<any> {
    let data

    try {
      data = await response.json()
    } catch (error) {
      throw new HttpError(
        HavenApiErrorCode.INTERNAL_ERROR,
        'An unexpected error has occurred while parsing the HTTP request\'s response JSON! Error: ' +
        JSON.stringify(error),
      )
    }

    if (response.ok) {
      return data
    }

    const code = (response.status === 504 ?
      HavenApiErrorCode.DEVICE_TIMEOUT : (data.code ? data.code : response.status)
    )

    throw new HttpError(code, data.message)
  }

  private handleCatch(error: Error | HttpError) {
    // Check for Device Timeout error to dispatch action.
    if (error instanceof HttpError) {
      if (error.code === HavenApiErrorCode.DEVICE_TIMEOUT) {
        store.dispatch(setError(ErrorType.RequestTimeout))
      }

      // Rethrow error as received up the stack.
      throw error
    }

    // If we can't identify a proper Haven API HTTP Error than it's unknown.
    throw new HttpError(HavenApiErrorCode.UNKNOWN_ERROR, error.message)
  }

  /**
   * Fetch network resource using GET method that can be aborted.
   * @param url The URL of the resource you want to fetch.
   * @param numberOfRetries The maximum number of retries if the HTTP requests returns a 504 status (Timeout).
   * @returns CancelableFetch - A object containing a Promise<Response> in JSON format and an AbortController object
   * instance that allows to abort this fetch request.
   * @throws error if there is a problem with the fetch/network request.
   */
  getWithAbort(url: string, numberOfRetries = 3): CancelableFetch<any> {
    const baseUrl = config.baseUrl

    const fetchRequest: CancelableFetch<any> = cancelableFetch(baseUrl + url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })

    return {
      ...fetchRequest,
      promise: fetchRequest.promise
        .then((response) => {
          // when we get a 504 timeout, we try again until we've reached our maximum number
          if ((response.status === 504) && (numberOfRetries > 0)) {
            numberOfRetries -= 1
            return this.getWithAbort(response.url.slice(baseUrl.length), numberOfRetries)
          }

          return this.handleResponse(response)
        }).catch(this.handleCatch),
    }
  }

  /**
   * fetch service using GET
   * @param url The URL of the resource you want to fetch.
   * @param numberOfRetries The maximum number of retries if the HTTP requests returns a 504 status (Timeout).
   * @returns data in json format from the network resource (endpoint).
   * @throws error if there is a problem with the fetch/network request.
   */
  get<T extends any>(url: string, numberOfRetries = 3, body: any = null): Promise<T> {
    const baseUrl = config.baseUrl

    return fetch(baseUrl + url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Client-Application': 'pro_portal',
      },
      ...(body ? {
        body: JSON.stringify(body), 
      } : {
      }),
    })
      .then((response) => {
        // when we get a 504 timeout, we try again until we've reached our maximum number
        if ((response.status === 504) && (numberOfRetries > 0)) {
          numberOfRetries -= 1
          return this.get<T>(response.url.slice(baseUrl.length), numberOfRetries)
        }

        return this.handleResponse(response)
      })
      .catch(this.handleCatch)
  }

  /**
   * Stops multiple instances of the request from starting
   * before the previous has finished
   */
  async getOnce<T extends any>(url: string): Promise<T | void> {
    if (reqs.has(url)) return Promise.resolve() // the request is already in the air

    reqs.set(url, true)
    const result = await this.get<T>(url)
    reqs.delete(url)

    return result
  }

  /**
   * fetch service using GET
   * @param url The URL of the resource you want to fetch.
   * @param numberOfRetries The maximum number of retries if the HTTP requests returns a 504 status (Timeout).
   * @returns data in json format from the network resource (endpoint).
   * @throws error if there is a problem with the fetch/network request.
   */
  stripe(url: string, numberOfRetries = 3, init: RequestInit = {
  }): Promise<any> {
    const baseUrl = config.baseUrl

    return fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`,
        'Client-Application': 'pro_portal',
      },
      ...init,
    })
      .then((response) => {
        // when we get a 504 timeout, we try again until we've reached our maximum number
        if ((response.status === 504) && (numberOfRetries > 0)) {
          numberOfRetries -= 1
          return this.get(response.url.slice(baseUrl.length), numberOfRetries)
        }

        return this.handleResponse(response)
      })
      .catch(this.handleCatch)
  }

  /**
   * fetch service using POST
   * @param url string
   * @param body - any
   * @param numberOfRetries The maximum number of retries if the HTTP requests returns a 504 status (Timeout).
   * @returns data in json from endpoint
   * @throws error if error code or message is provided
   */
  post(url: string, bodyContent: any, numberOfRetries = 3): Promise<any> {
    const baseUrl = config.baseUrl

    return fetch(baseUrl + url, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Client-Application': 'pro_portal',
      },
      body: JSON.stringify(bodyContent),
    })
      .then((response) => {
        // when we get a 504 timeout, we try again until we've reached our maximum number
        if ((response.status === 504) && (numberOfRetries > 0)) {
          numberOfRetries -= 1
          return this.get(response.url.slice(baseUrl.length), numberOfRetries)
        }

        return this.handleResponse(response)
      })
      .catch(this.handleCatch)
  }

  /**
   * fetch service using DELETE
   * @param url string
   * @param body - any
   * @param numberOfRetries The maximum number of retries if the HTTP requests returns a 504 status (Timeout).
   * @returns data in json from endpoint
   * @throws error if error code or message is provided
   */
  delete(url: string, bodyContent: any, numberOfRetries = 3): Promise<any> {
    const baseUrl = config.baseUrl

    return fetch(baseUrl + url, {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyContent),
    })
      .then((response) => {
        // when we get a 504 timeout, we try again until we've reached our maximum number
        if ((response.status === 504) && (numberOfRetries > 0)) {
          numberOfRetries -= 1
          return this.get(response.url.slice(baseUrl.length), numberOfRetries)
        }

        return this.handleResponse(response)
      })
      .catch(this.handleCatch)
  }
}

const httpService = new HTTPService()

export default httpService
