/**
 * Router v6 is used in this project. There are routes and descendant routes throughout the source-code.
 * The <Routes> element when used to render deep within the component tree of another <Routes> element will work just
 * the same as any other <Routes>, except they will automatically build on the path of the route that rendered them.
 * When we do this we have to make sure to put a * at the end of the parent route's path. Otherwise, the parent route
 * won't match the URL when it is longer than the parent route's path, and your descendant <Routes> won't ever show up.
 * Absolute paths are used when navigating using links and relative paths are used to match the descendant routes paths,
 * since relative paths are not supported in this case.
 * We could also use relative when navigating with links but that would require using a similar path to folder structure
 * such as "../../" when navigating up, for easy maintenance purposes we don't want that.
 */
export const ROUTE_PATHS = {
  trailingDescendantRoutes: '/*',
  root: '/',
  login: {
    absolute: '/login',
    relative: 'login',
  },
  onboarding: {
    absolute: '/onboarding',
    relative: 'onboarding',
  },
  joinServiceCompany: {
    root: {
      absolute: '/join',
      relative: 'join',
    },
    onboarding: {
      absolute: '/join/onboarding',
      relative: 'onboarding',
    },
    error: {
      serviceCompanyDoesNotExist: {
        absolute: '/join/service-company-does-not-exist',
        relative: 'service-company-does-not-exist',
      },
      alreadyBelongsToServiceCompany: {
        absolute: '/join/already-belongs-to-service-company',
        relative: 'already-belongs-to-service-company',
      },
      inviteCodeExpired: {
        absolute: '/join/invite-code-expired',
        relative: 'invite-code-expired',
      },
      inviteCodeUsed: {
        absolute: '/join/invite-code-used',
        relative: 'invite-code-used',
      },
    },
  },
  customers: {
    root: {
      absolute: '/customers',
      relative: 'customer',
    },
    details: {
      root: {
        absolute: '/customers/:dwellingId',
        relative: ':dwellingId',
      },
      equipment: {
        absolute: '/customers/:dwellingId/equipment',
        relative: 'equipment',
      },
      alerts: {
        absolute: '/customers/:dwellingId/alerts',
        relative: 'alerts',
      },
      profile: {
        absolute: '/customers/:dwellingId/profile',
        relative: 'profile',
      },
      report: {
        absolute: '/customers/:dwellingId/report',
        relative: 'report',
      },
    },
  },
  resources: {
    root: {
      absolute: '/resources',
      relative: 'resources',
    },
    salesAndMarketing: {
      root: {
        absolute: '/resources/sales-and-marketing',
        relative: 'sales-and-marketing',
      },
      tab: {
        absolute: '/resources/sales-and-marketing/:tabName',
        relative: 'sales-and-marketing/:tabName',
      },
    },
  },
  howToBuy: {
    root: {
      absolute: '/how-to-buy',
      relative: 'how-to-buy',
    },
    personalUseProgram: {
      absolute: '/how-to-buy/personal-use-program',
      relative: 'personal-use-program',
      payment: {
        absolute: '/how-to-buy/personal-use-program/payment',
        relative: '/personal-use-program/payment',
      },
    },
  },
  teamMngt: {
    root: {
      absolute: '/team-mngt',
      relative: 'team-mngt',
    },
    add: {
      absolute: '/team-mngt/team-add',
      relative: 'team-add',
    },
  },
  preferences: {
    root: {
      absolute: '/preferences',
      relative: 'preferences',
    },
    settings: {
      absolute: '/preferences/settings',
      relative: 'settings',
    },
    account: {
      absolute: '/preferences/account',
      relative: 'account',
    },
    company: {
      absolute: '/preferences/company',
      relative: 'company',
    },
  },
  paymentRedirect: {
    absolute: '/payment-redirect',
    relative: 'payment-redirect',
  },
  notFound: {
    absolute: '/not-found',
    relative: 'not-found',
  },
}
