import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { AutomationRule } from 'types'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import useMonitorInCurrentZone from '../charts/device-chart/useMonitorInCurrentZone'
import AlertSettings from './alertSettings'
import { selectEmailRules, useAutomationsStore, useRulesStore } from './store'
import { findAlertType, hourToSecond, tempId } from './util'
import useAdmin from 'utils/hooks/useAdmin'
import { AddEmailDialog } from './add-email-dialog'
import InfoTooltip from 'ui/info-tooltip/info-tooltip'
import { theme } from 'theme'
import Stack from 'ui/stack'
import mixpanel from 'mixpanel-browser'

const defaults = {
  dew_point: {
    celsius: {
      high_end_start_set_point: 13,
      high_end_stop_set_point: 12,
      low_end_start_set_point: 10,
      low_end_stop_set_point: 9,
    },
    fahrenheit: {
      high_end_start_set_point: 55,
      high_end_stop_set_point: 54,
      low_end_start_set_point: 50,
      low_end_stop_set_point: 49,
    },
  },
}

const ids = function () {
  const ids: number[] = []

  for (let i = 0; i < 7; i++) {
    ids.push(tempId())
  }

  return ids
}()

const defaultAlertRules = ({
  device_id,
  dwelling_id,
  zone,
  email,
  temperature_isFahrenheit,
  pm_isMc,
  voc_isMc,
}): { [key: string]: {} } => {

  const unit = temperature_isFahrenheit ? 'fahrenheit' : 'celsius'

  return {
    [ids[0]]: {
      id: ids[0],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[0],
        type: 'cam_range' as const,
        device_id,
        set_point_unit: unit,
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'dew_point' as const,
        high_end_start_set_point: defaults.dew_point[unit].high_end_start_set_point,
        low_end_start_set_point: defaults.dew_point[unit].low_end_start_set_point,
      }],
      outputs: [{
        id: ids[0],
        type: 'email',
        email,
      }],
      rules: [{
        enabled: false,
        id: ids[0],
        inputs: [ids[0]],
        outputs: [ids[0]],
        filtration: false,
        ventilation: false,
        humidity: true,
        isTemplate: true,
      }],
    },
    [ids[1]]: {
      id: ids[1],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[1],
        type: 'cam_threshold',
        currently_triggered: false,
        device_id,
        measurement: 'airflow',
        rising: false,
        start_set_point: 0.1,
        stop_set_point: 0.2,
        detection_duration: hourToSecond(2),
      }],
      outputs: [{
        id: ids[1],
        type: 'email',
        email,
      }],
      rules: [{
        id: ids[1],
        enabled: false,
        inputs: [ids[1]],
        outputs: [ids[1]],
        filtration: true,
        ventilation: false,
        humidity: false,
        isTemplate: true,
      }],
    },
    [ids[2]]: {
      id: ids[2],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[2],
        type: 'cam_threshold' as const,
        device_id,
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'pm25' as const,
        rising: true,
        start_set_point: 33.2,
        stop_set_point: 29.9,
      }],
      outputs: [{
        id: ids[2],
        type: 'email',
        email,
      }],
      rules: [{
        id: ids[2],
        enabled: false,
        inputs: [ids[2]],
        outputs: [ids[2]],
        filtration: true,
        ventilation: false,
        humidity: false,
        isTemplate: true,
      }],
    },
    [ids[3]]: {
      id: ids[3],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[3],
        type: 'cam_range' as const,
        device_id,
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'humidity' as const,
        high_end_start_set_point: 60,
        low_end_start_set_point: 20,
      }],
      outputs: [{
        id: ids[3],
        type: 'email',
        email,
      }],
      rules: [{
        id: ids[3],
        enabled: false,
        inputs: [ids[3]],
        outputs: [ids[3]],
        filtration: false,
        ventilation: false,
        humidity: true,
        isTemplate: true,
      }],
    },
    [ids[4]]: {
      id: ids[4],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[4],
        type: 'cam_range' as const,
        device_id,
        set_point_unit: temperature_isFahrenheit ? 'fahrenheit' : 'celsius',
        currently_triggered: false,
        detection_duration: hourToSecond(12),
        measurement: 'temperature' as const,
        high_end_start_set_point: temperature_isFahrenheit ? 77 : 25,
        low_end_start_set_point: temperature_isFahrenheit ? 64 : 18,
      }],
      outputs: [{
        id: ids[4],
        type: 'email',
        email,
      }],
      rules: [{
        id: ids[4],
        enabled: false,
        inputs: [ids[4]],
        outputs: [ids[4]],
        filtration: false,
        ventilation: false,
        humidity: false,
        isTemplate: true,
      }],
    },
    [ids[5]]: {
      id: ids[5],
      dwelling_id,
      zone,
      inputs: [{
        id: ids[5],
        type: 'cam_threshold' as const,
        device_id,
        rising: true,
        currently_triggered: false,
        detection_duration: hourToSecond(4),
        measurement: 'voc' as const,
        start_set_point: 850,
        stop_set_point: 800,
      }],
      outputs: [{
        id: ids[5],
        type: 'email',
        email,
      }],
      rules: [{
        id: ids[5],
        enabled: false,
        inputs: [ids[5]],
        outputs: [ids[5]],
        filtration: true,
        ventilation: false,
        humidity: false,
        isTemplate: true,
      }],
    },
  }
}

function AlertHistory({ dwellingId }) {
  return (
    <p>Alert history {dwellingId}</p>
  )
}

function Alerts({
  dwellingId,
  loading,
}: {
  dwellingId: number,
  loading: boolean,
}) {
  // const [tab, setTab] = useState<'automations' | 'schedules'>('automations')

  const { pm_isMc, temperature_isFahrenheit, voc_isMc } = useAppSelector(getUserPreferences)
  const zone = useCurrentZone()
  const [emailDialogIsOpen, setEmailDialogIsOpen] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')

  const user = useAdmin()

  const emailRules = useRulesStore(selectEmailRules(dwellingId, zone))
  const [formattedRules, setFormattedRules] = useState<{ [key: string]: AutomationRule }>({})

  const monitor = useMonitorInCurrentZone()

  useEffect(() => {
    if (user?.email) setEmailAddress(user.email)
  }, [user?.email])

  useEffect(() => {
    if (!monitor) return
    if (!dwellingId) return
    if (!zone) return
    if (!emailAddress && !loading) return setEmailDialogIsOpen(true)
    if (emailAddress) setEmailDialogIsOpen(false)

    const defaultRules = defaultAlertRules({
      dwelling_id: dwellingId,
      device_id: monitor?.id,
      email: emailAddress,
      zone,
      pm_isMc,
      voc_isMc,
      temperature_isFahrenheit,
    })

    useAutomationsStore.setState(prev => ({
      ...prev,
      ...defaultRules,
    }))
  }, [
    monitor?.id,
    emailAddress,
    dwellingId,
    zone,
    pm_isMc,
    voc_isMc,
    temperature_isFahrenheit,
  ])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track_pageview()
    }
  }, [mixpanel])

  useEffect(() => {
    if (!Object.keys(emailRules || {}).length) return
    const result = {}

    // loop over rules, if there are duplicates
    // keep the ones that are not templates
    for (const key in emailRules) {
      const rule: AutomationRule = emailRules[key]
      const id = findAlertType(rule)

      // if there's no email address when these
      // automations arrive, we can grab an email
      // from an automaiton just to avoid opening
      // the email dialog
      if (!emailAddress) {
        // @ts-ignore
        if (rule.outputs[0].email) {
          // @ts-ignore
          setEmailAddress(rule.outputs[0].email)
        }
      }

      if (result[id]) {
        if (rule.trigger.enabled && !rule.trigger.isTemplate) {
          result[id] = rule
        }
      } else {
        result[id] = rule
      }

      // if (rule.trigger.isTemplate) result[id] = rule // TESTING ONLY
      // if (id === 'dew-point-out-of-range') result[id] = Object.values(emailRules).find(x => x.trigger.isTemplate) // TESTING ONLY
    }

    setFormattedRules(result)
  }, [JSON.stringify(emailRules)])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box
        p={6}
        bgcolor='#f7fbfd'
        minHeight='100vh'
      >
        <Stack
          spacing={1}
          style={{ marginBottom: '32px' }}
          justify='flex-start'
          direction='row'>
          <Typography variant='h6'>
            Email alerts
          </Typography>
          <InfoTooltip
            placement="right-end"
            arrow
            interactive
            title={
              <>
                <p>
                  This feature is currently part of an indefinite Free Trial which means we haven’t set up a date when
                  it will be part of a paid tier of software services.
                </p>
                <p>
                  Please note, you will <b>not</b> be charged for it during this Free Trial phase. We’ll keep you
                  posted on any future changes to the feature.
                </p>
              </>
            }
          >
            <Box
              height={18}
              ml={1}
              style={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: '.25rem',
                fontSize: '.8rem',
                color: 'white',
                padding: '0 .25rem',
                cursor: 'help',
              }}
            >
              TRIAL
            </Box>
          </InfoTooltip>
        </Stack>
        <AlertSettings rules={formattedRules} />
      </Box>
      <AddEmailDialog
        onClickYes={() => setEmailDialogIsOpen(false)}
        isOpen={emailDialogIsOpen}
        setEmail={setEmailAddress}
      />
    </>
  )
}

export {
  defaults,
}

export default Alerts
