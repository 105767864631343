import { useAuth0 } from '@auth0/auth0-react'
import {
  Box, createStyles, Grid, makeStyles, Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import 'features/home/home.scss'
import productImageUrl from 'features/how-to-buy/bundle.svg'
import React, { useEffect } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import InfoTooltip from 'ui/info-tooltip'
import { usePageTitle } from 'utils/hooks/router'

const useStyles = makeStyles(theme =>
  createStyles({
    checkedValue: {
      color: '#FFFFFF',
      marginRight: '.5em',
    },
    loginButton: {
      background: 'white',
      color: theme.palette.darkerBlew,
      '&:hover': {
        background: theme.palette.charcoalLightestTint,
      },
    },
  }),
)

/**
 * It renders the home page before user authenticates or redirect to the customers' page otherwise.
 */
export function Home() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const classes = useStyles()

  const [searchParams] = useSearchParams()

  const searchParamsWithoutPathname = new URLSearchParams(searchParams)
  searchParamsWithoutPathname.delete('pathname')

  const redirect = searchParams.get('pathname') ?
    `${searchParams.get('pathname')}?${searchParamsWithoutPathname.toString()}` : ''

  useEffect(() => {
    if (!redirect) return
    window.localStorage.setItem('hvn-redirect', redirect)
  }, [redirect])

  usePageTitle('')

  const valueList: string[] = [
    'Supercharge your company’s indoor air quality (IAQ) strategy',
    'Cutting edge air quality monitoring and controls solution (hardware + software)',
    'Compatible with any forced air HVAC system',
    'Professionally managed & supported',
    'Local availability',
  ]

  return (
    <>
      {!isLoading && isAuthenticated ?
        <Navigate to={ROUTE_PATHS.customers.root.absolute} replace /> :
        <Grid container className="home-page">
          <Grid item sm={2} />
          <Grid item sm={4} id="home-actions">
            <Box display="flex" alignItems="center" height="100%">
              <Box>
                <Typography variant="h1">Welcome to the HAVEN Pro Portal.</Typography>
                <br />
                <Box id="sub-text">
                  <Typography variant="h5" className="home-heading" gutterBottom>
                    Create a new service company account in a few short steps.
                  </Typography>
                </Box>
                <Box mt={3} width={280}>
                  <Button
                    variant="contained"
                    className={classes.loginButton}
                    fullWidth
                    onClick={() => loginWithRedirect()}
                  >
                    Create Account or Login
                  </Button>
                </Box>
                <Box mt={2} width={310}>
                  <Button
                    variant="outlined"
                    style={{
                      width: 280,
                    }}
                    component={Link}
                    to={ROUTE_PATHS.joinServiceCompany.root.absolute}
                  >
                    Join Existing Company
                  </Button>
                  <InfoTooltip
                    placement="bottom"
                    arrow
                    interactive
                    title={
                      <>
                        For folks who have received an invitation link from
                        an existing HAVEN Pro service company manager.
                      </>
                    }
                  >
                    <InfoIcon
                      style={{
                        marginLeft: 8,
                        verticalAlign: 'middle',
                      }}
                      fontSize="small" />
                  </InfoTooltip>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={4}>
            <Box display="flex" alignItems="center" height="100%" maxWidth={412}>
              <Box className="product-offering">
                <Box
                  borderBottom="1px solid white"
                  className="product-value-heading"
                  px={3}
                  py={1}
                >
                  <img
                    className="product-value-image"
                    src={productImageUrl}
                    alt="haven product offerings"
                    width={352}
                    height={271}
                  />
                  <Typography variant="h5" gutterBottom>Why sign up?</Typography>
                </Box>
                <Box py={2} px={3}>
                  {valueList.map((value, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      mb={1.5}
                    >
                      <CheckCircleIcon className={classes.checkedValue} />
                      <Typography variant="subtitle2">{value}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
    </>
  )
}
