import { findUnit } from "features/customer-drill-down/equipment/util"
import { useEffect, useState } from "react"
import { getUserPreferences } from "state-mngt/selectors/user-selectors"
import { Measurement } from "types"
import { useAppSelector } from "./reduxTypes"

function useConvertParticulateUnit(measurement: Measurement, unit) {
  const [convertedUnit, setConvertedUnit] = useState(unit)
  const preferences = useAppSelector(getUserPreferences)

  useEffect(() => {
    if (!measurement) return
    if (!preferences) return

    const { pm_isMc, voc_isMc } = preferences

    setConvertedUnit(
      measurement === 'pm25' ?
        (pm_isMc ? 'ugm3' : 'count') :
        (voc_isMc ? 'ugm3' : 'ppb'),
    )
  }, [
    measurement,
    JSON.stringify(preferences),
  ])

  return convertedUnit || unit
}

export default useConvertParticulateUnit
