import {
  Box,
  Button,
  ButtonGroup,
  debounce,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import 'features/customer-drill-down/charts/device-chart/device-chart.scss'
import {
  ANNOTATIONS_FOR_DEMO,
  generateLineChartSeries,
  generateLineChartXAxis,
  generateLineChartYAxis,
  getLineChartDefaultOptions,
} from 'features/customer-drill-down/charts/device-chart/line-chart-utils'
import { Annotation } from 'features/customer-drill-down/charts/device-chart/models'
import * as Highcharts from 'highcharts'
import { AnnotationsOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HCAnnot from 'highcharts/modules/annotations'
import HighchartsBoost from 'highcharts/modules/boost'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import XRange from 'highcharts/modules/xrange'
import parse from 'html-react-parser'
import React, {
  memo,
  useCallback,
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { RootState } from 'state-mngt/store'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import { useEffectOnce } from 'utils/hooks/lifecycle'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import useCurrentDwellingContents from 'utils/hooks/useCurrentDwellingContents'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'
import useEcosenseTelemetry from 'utils/hooks/useEcosenseTelemetry'
import { padDates } from './chart-utils'
import { sanitize } from './store'
import useGanttChartOptions from './useGanttChartOptions'
import useMonitorTelemetry from './useMonitorTelemetry'
import useOutdoorAir from './useOutdoorAir'

XRange(Highcharts) // require xrange module for gantt chart
HCAnnot(Highcharts) // Required for annotations.
NoDataToDisplay(Highcharts) // Required for displaying message for no data display in basicOptions.
HighchartsBoost(Highcharts)

export const CONTROLS_WIDTH = 238

/**
 * Function Component that renders one linear chart and one gantt chart.
 * The linear chart:
 * It displays the following data measurements from customers:
 * PM, VOC, Temperature, Humidity, Outdoor AQI, Outdoor Temperature, Outdoor Humidity, Outdoor DewPoint.
 * They are all optional and only two of them can be displayed at the same time in the chart.
 * One measurement uses the left yAxis and the other one the secondary right yAxis. By default, PM data is displayed
 * on the left yAxis and nothing is displayed on the right. The xAxis displays the timeframe for the requested data.
 * There two possible overlays available for the chart: Thresholds and Airflow Inactive. They are all optional.
 * However, Thresholds are displayed by default. They are plot lines stretching across the plot area, marking a specific
 * value on one of the axes.
 * Thresholds indicate the IAQ statuses fair and poor as horizontal dashed-lines.
 * Airflow Inactive indicates when there was no airflow reading as vertical grayed out area.
 * #####################################################################################################################
 * The Gantt Chart:
 * It displays airflow active status and the Controller's channel activation status as horizontal bars.
 * The yAxis displays the reading name (airflow, controller channel 01 and 02) and the xAxis displays the timeframe
 * for the requested data.
 * #####################################################################################################################
 * Both charts must be able to re-render itself based on screen size changes such as if the user collapse or expand a
 * side menu on the page where the graph is being displayed.
 * Because the "highcharts" is a JavaScript charting library we make use of the "highcharts-react-official" lib that
 * provides a React wrapper function component for Highcharts.
 * @param props
 * @constructor
 */
export const DeviceChart = memo<any>(({
  annotations = {
  } as Annotation,
  containerId = '',
  dataLabel = '',
  dataType,
  secondDataType,
  interactive,
  isSidebarCollapsed,
  isCurrentStatusCollapsed,
  isReport = false,
  setStartTime = (_x) => { },
  setEndTime = (_x) => { },
  setTimeframe = (_x) => { },
}) => {

  const dwellingDetails = useCurrentDwellingDetails()

  const [searchParams] = useDecodedSearchParams()
  const selectedOverlays = (searchParams.get(HISTORY_QUERY_KEYS.selectedOverlays) || '').split(',')

  const startTime = searchParams.get(HISTORY_QUERY_KEYS.startTime) ?
    new Date(parseInt(searchParams.get(HISTORY_QUERY_KEYS.startTime) || '')) :
    null

  const endTime = searchParams.get(HISTORY_QUERY_KEYS.endTime) ?
    new Date(parseInt(searchParams.get(HISTORY_QUERY_KEYS.endTime) || '')) :
    null

  const [paddedStart, paddedEnd] = padDates(startTime, endTime)

  const [{ dataMin, dataMax }, setMinMax] = useState({
    dataMin: paddedStart,
    dataMax: paddedEnd,
  })

  const setNewDataRange = () => {
    setMinMax({
      dataMin: paddedStart,
      dataMax: paddedEnd,
    })
  }

  const lineChartRef = useRef<HighchartsReact.RefObject>(null)
  const ganttChartRef = useRef<HighchartsReact.RefObject>(null)

  const userPreferences = useAppSelector((state: RootState) => getUserPreferences(state))

  const debouncedSetStartTime = useRef(debounce(setStartTime, 300)).current
  const debouncedSetEndTime = useRef(debounce(setEndTime, 300)).current
  const debouncedSetTimeframe = useRef(debounce(setTimeframe, 300)).current

  const [lineChartOptions, setLineChartOptions] = useState<Highcharts.Options>({
  })

  const {
    telemetry,
    hasMonitor,
    loading: monitorTelemetryLoading,
  } = useMonitorTelemetry(dataMin, dataMax)

  const content = useCurrentDwellingContents()

  const {
    telemetry: ecosenseTelemetry,
    loading: ecosenseTelemetryLoading,
  } = useEcosenseTelemetry(
    content?.ecosense_devices.map(x => x.serial_number) || [],
    dataMin,
    dataMax,
    true,
  )

  const afterSetExtremes: Highcharts.AxisSetExtremesEventCallbackFunction = (p) => {
    if (!p) return
    if (!p.min || !p.max) return

    // set the user's panned chart selection on the URL
    // the URL params are the authority on what data to
    // fetch and use for the charts and the whole page
    if (p.trigger === 'pan') {
      debouncedSetStartTime(new Date(p.min))
      debouncedSetEndTime(new Date(p.max))
      debouncedSetTimeframe('none')
    }
  }

  const { data: outdoorAirData, loading: outdoorAirDataLoading } = useOutdoorAir(
    sanitize(dwellingDetails?.postal_code || ''),
    dataMin,
    dataMax,
    [dataType, secondDataType],
  )

  const loading = outdoorAirDataLoading || ecosenseTelemetryLoading || monitorTelemetryLoading

  useEffect(() => {
    if (!startTime || !endTime) return

    if (lineChartRef?.current?.chart?.xAxis) {
      setTimeout(() =>
        lineChartRef.current &&
        lineChartRef.current.chart.xAxis[0].setExtremes(startTime.getTime(), endTime.getTime(), true, true), 100)
    }

    if (ganttChartRef?.current?.chart.xAxis) {
      setTimeout(() =>
        ganttChartRef.current &&
        ganttChartRef.current.chart.xAxis[0].setExtremes(startTime.getTime(), endTime.getTime(), true, true), 100)
    }
  }, [
    startTime?.getTime(),
    endTime?.getTime(),
    lineChartRef?.current?.chart,
    ganttChartRef?.current?.chart,
  ])

  useEffect(() => {
    if (!startTime || !endTime) return
    if ((startTime && endTime) && (!dataMin || !dataMax)) return setNewDataRange()

    const earliestPlottedPoint = telemetry[0]
    const latestPlottedPoint = telemetry[telemetry.length - 1]

    if (earliestPlottedPoint && latestPlottedPoint) {
      if (new Date(earliestPlottedPoint.timestamp) >= startTime) setNewDataRange()
      if (new Date(latestPlottedPoint.timestamp) <= endTime) setNewDataRange()
    }

  }, [
    JSON.stringify(telemetry),
    startTime?.getTime(),
    endTime?.getTime(),
    dataMin?.getTime(),
    dataMax?.getTime(),
  ])

  // Set the chart options for rendering
  useEffect(() => {
    const series = generateLineChartSeries(
      telemetry,
      outdoorAirData,
      ecosenseTelemetry,
      dataType,
      secondDataType,
      userPreferences,
    )

    let annotationsForChart: AnnotationsOptions[] | undefined

    if (Object.keys(annotations || {
    }).length) {
      annotationsForChart = [{
        ...ANNOTATIONS_FOR_DEMO,
        labels: annotations.getLabels(series),
        shapes: annotations.getShapes(series),
      }]
    }

    setLineChartOptions({
      ...getLineChartDefaultOptions(
        interactive,
        interactive ? 280 : 210,
      ),
      xAxis: generateLineChartXAxis(
        telemetry,
        startTime,
        endTime,
        selectedOverlays,
        {
          afterSetExtremes,
        },
        {
          enabled: !hasMonitor,
        },
      ),
      yAxis: generateLineChartYAxis(
        telemetry,
        dataType,
        secondDataType,
        userPreferences,
        selectedOverlays,
      ),
      series,
      annotations: annotationsForChart,
    })
  }, [
    JSON.stringify(annotations),
    startTime?.getTime(),
    endTime?.getTime(),
    telemetry.join(''),
    selectedOverlays.join(''),
    outdoorAirData.join(''),
    JSON.stringify(userPreferences),
    dataType,
    secondDataType,
  ])

  const { ganttChartOptions, ganttLegend } = useGanttChartOptions({
    startTime,
    endTime,
    dwellingId: dwellingDetails?.id,
    interactive,
    secondDataType,
    events: {
      afterSetExtremes,
    },
    monitorTelemetry: telemetry,
  })

  // Update chart size to adjust with other components on the parent screen.
  useLayoutEffect(() => {
    if (loading) return
    setTimeout(updateChartSize, 200)
  }, [
    isSidebarCollapsed,
    isCurrentStatusCollapsed,
    ganttChartOptions?.series?.length,
    secondDataType,
    loading,
  ])

  // Add a listener to window resize so the chart can have its size updated accordingly.
  useEffectOnce((markAsCalled) => {
    markAsCalled()
    window.addEventListener('resize', updateChartSize)

    return function cleanup() {
      window.removeEventListener('resize', updateChartSize)
    }
  }, [ganttChartOptions.series?.length])

  useEffect(() => {
    if (!lineChartRef?.current) return
    if (loading) lineChartRef.current?.chart.showLoading('Loading...')
    if (!loading) lineChartRef.current?.chart.hideLoading()
  }, [
    loading,
    lineChartRef?.current,
  ])

  const updateChartSize = useCallback(() => {
    // Retrieve the container of both line and gantt chart, so we can update the size.
    const container = document.getElementById(containerId)

    if (container && lineChartRef.current && ganttChartRef.current) {
      const lineChart = lineChartRef.current.chart
      const ganttChart = ganttChartRef.current.chart

      if (lineChart && ganttChart) {
        if (isReport) {
          lineChart.setSize(container.offsetWidth)
          ganttChart.setSize(container.offsetWidth)
          // lineChart.reflow()
          // ganttChart.reflow()
        } else {
          lineChart.setSize(container.offsetWidth - CONTROLS_WIDTH)
          ganttChart.setSize(container.offsetWidth)
        }
      }
    }
  }, [ganttChartOptions?.series?.length])

  const onClickZoomIn = () => {
    if (!endTime || !startTime) return
    const diff = endTime.getTime() - startTime.getTime()
    const factor = Math.round(diff / 4)

    setStartTime(new Date(startTime.getTime() + factor))
    setEndTime(new Date(endTime.getTime() - factor))
    setTimeframe('none') // timeframe won't be accurate anymore after a zoom
  }

  const onClickZoomOut = () => {
    if (!endTime || !startTime) return
    const diff = endTime.getTime() - startTime.getTime()

    const _endTime = Math.min(Date.now(), endTime.getTime() + diff)

    setStartTime(new Date(startTime.getTime() - diff))
    setEndTime(new Date(_endTime))
    setTimeframe('none') // timeframe won't be accurate anymore after a zoom
  }

  return (
    <>
      {(isReport && dataLabel) && (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingRight: '12px',
        }} id="report-legend">
          <div style={{
            display: 'flex',
            margin: '4px 8px',
            fontSize: 10,
          }}>
            <div style={{
              backgroundColor: '#068CA7',
              height: '2px',
              width: '12px',
              margin: '6px 4px 0 0',
            }} />{dataLabel} levels
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            margin: '4px 8px',
            fontSize: 10,
          }}>
            <div style={{
              borderRadius: '100%',
              backgroundColor: '#CFD8DC',
              height: '8px',
              width: '8px',
              margin: '2px',
              flex: 'none',
            }} />HVAC fan inactive
          </div>
          {ganttLegend?.map(({ title, color }, index) => (
            <div key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px 8px',
                fontSize: 10,
              }}>
              <div style={{
                borderRadius: '100%',
                backgroundColor: color,
                height: '8px',
                width: '8px',
                margin: '2px',
                flex: 'none',
              }} />
              {parse(title)}
            </div>
          ))}
        </div>
      )}
      <Box
        display='flex'
        flexDirection='column'
        id='container-0'
        width='100%'
      >
        <HighchartsReact
          ref={lineChartRef}
          highcharts={Highcharts}
          options={lineChartOptions}
          // Uses chart.update() method to apply new options to the chart when changing the parent component.
          allowChartUpdate={true}
        />
        {(interactive && !loading) ?
          (
            <Box
              position='absolute'
              left='64px'
              top='16px'
              bgcolor='white'
              boxShadow={1}
              borderRadius={4}
              id='zoom-buttons'
              display='flex'
            >
              <ButtonGroup
                variant='outlined'
                size='small'
              >
                <Button
                  onClick={onClickZoomIn}
                >
                  <AddIcon
                    fontSize='small'
                  />
                </Button>
                <Button
                  onClick={onClickZoomOut}
                >
                  <RemoveIcon
                    fontSize='small'
                  />
                </Button>
              </ButtonGroup>
            </Box>
          ) :
          null}
        {(Boolean(ganttChartOptions.series?.length) && hasMonitor) && (
          <Box
            id={isReport ? "container-1-report" : "container-1"}
            width='100%'
          >
            <HighchartsReact
              ref={ganttChartRef}
              highcharts={Highcharts}
              // Highcharts chart configuration object
              options={ganttChartOptions}
              // Uses chart.update() method to apply new options to the chart when changing the parent component.
              allowChartUpdate={true}
            />
          </Box>
        )}
      </Box>
    </>
  )
})
