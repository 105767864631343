import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { BuildReportTemplate } from 'features/build-report'
import { CustomerLayout } from 'features/customer-drill-down'
import Charts from 'features/customer-drill-down/charts'
import CustomerDetails from 'features/customer-drill-down/customer-details'
import Alerts from 'features/customer-drill-down/equipment/alerts'
import AutomationsAndSchedules from 'features/customer-drill-down/equipment/automations-and-schedules'
import useGetData from 'features/customer-drill-down/equipment/useGetData'
import { ChartsPageTour, CustomerDetailsPageTour } from 'features/product-tour'
import { PRODUCT_TOUR_QUERY_KEYS } from 'features/product-tour/pro-portal-tour'
import React, { useEffect, useState } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'
import useSyncAutomationWithRules from 'utils/hooks/useSyncAutomationWithRules'

/**
 * Render customer drill down page
 */
export const CustomerDrillDown = () => {
  const { mixpanel } = useMixPanel()
  const { dwellingId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useDecodedSearchParams()

  // this just has to be called once somewhere
  useSyncAutomationWithRules()

  const currentPageTour = searchParams.get(PRODUCT_TOUR_QUERY_KEYS.currentPageTour) || ''
  const [startProPortalTour, setStartProPortalTour] = useState(false)

  const handleProductTourEnd = () => {
    setStartProPortalTour(false)
    navigate(ROUTE_PATHS.customers.root.absolute, {
      replace: true,
    })
  }

  useEffect(() => {
    if (currentPageTour) {
      setStartProPortalTour(true)
    }
  }, [currentPageTour])

  const { loading } = useGetData(dwellingId)

  useEffect(() => {
    if (mixpanel) {
      // mixpanel.track_pageview()
      mixpanel.track('pp_customerDrillDownPage_open')
    }
  }, [mixpanel])

  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        {/* Index Route - A child route with no path that renders in the parent's outlet at the parent's URL. */}
        <Route
          index
          element={
            <CustomerLayout
              dwellingId={Number(dwellingId)}
              renderer={({ isCollapsed }) => {
                return (
                  <>
                    <Charts isSidebarCollapsed={isCollapsed} />
                    <ChartsPageTour startTour={startProPortalTour} onEndTour={handleProductTourEnd} />
                  </>
                )
              }}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.customers.details.equipment.relative}
          element={
            <CustomerLayout
              dwellingId={Number(dwellingId)}
              renderer={() => {
                return (
                  <>
                    <AutomationsAndSchedules
                      dwellingId={Number(dwellingId)}
                      loading={loading}
                    />
                    {/* <EquipmentPageTour startTour={startProPortalTour} onEndTour={handleProductTourEnd} /> */}
                  </>
                )
              }}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.customers.details.alerts.relative}
          element={
            <CustomerLayout
              dwellingId={Number(dwellingId)}
              renderer={() => {
                return (
                  <>
                    <Alerts
                      dwellingId={Number(dwellingId)}
                      loading={loading}
                    />
                  </>
                )
              }}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.customers.details.profile.relative}
          element={
            <CustomerLayout
              dwellingId={Number(dwellingId)}
              renderer={() => {
                return (
                  <>
                    <CustomerDetails customerId={Number(dwellingId)} />
                    <CustomerDetailsPageTour startTour={startProPortalTour} onEndTour={handleProductTourEnd} />
                  </>
                )
              }}
            />
          }
        />
        <Route path={ROUTE_PATHS.customers.details.report.relative} element={<BuildReportTemplate />} />
      </Routes>
      <Outlet />
    </>
  )
}
