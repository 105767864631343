import {
  Box,
  Grid,
} from '@material-ui/core'
import React from 'react'
import {
  selectEquipmentRulesByPillar,
  useRulesStore,
} from './store'
import {
  AutomationPillar,
  AutomationRule,
  AutomationRuleGroup,
  RuleRefs,
} from 'types'
import Stack from 'ui/stack'
import useClasses from './useClasses'
import Muted from './muted'
import RuleGroup from './rule-group'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import ColumnTitle from './columnTitle'

const mapToRefs = (rule: AutomationRule): RuleRefs => ({
  ...rule,
  trigger: rule.trigger.id,
  inputs: rule.inputs.map(x => x.id),
  outputs: rule.outputs.map(x => x.id),
})

const groupRules = (rules: AutomationRule[]): AutomationRuleGroup[] => {
  let groups: AutomationRuleGroup[] = []

  for (const rule of rules) {
    // does this rule share an input with any of the 
    // existing groups?
    const sharedInput = rule.inputs.find(ruleInput =>
      groups.some(group =>
        group.rules.some(rule =>
          rule.inputs.includes(ruleInput.id))))

    // find the group the shared input is in
    const group = sharedInput && groups.find(group =>
      rule.inputs.some(input =>
        group.rules.some(groupRule =>
          groupRule.inputs.includes(input.id))))

    if (sharedInput && group) {
      // add this rule to its group
      groups = groups.map(_group => {
        if (_group.id === group.id) {
          return {
            ...group,
            sharedInputs: [
              ...group.sharedInputs,
              sharedInput.id,
            ],
            rules: [
              ...group.rules,
              mapToRefs(rule),
            ],
          }
        }
        return group
      })
      continue
    }

    const newGroup: AutomationRuleGroup = {
      id: groups.length + 1,
      rules: [mapToRefs(rule)],
      sharedInputs: sharedInput ? [sharedInput.id] : [],
    }

    groups = [...groups, newGroup]
  }
  return groups
}

const useFilterByZone = (rules: AutomationRule[]) => {
  const currentZone = useCurrentZone()
  return rules.filter(rule => rule.zone.toLowerCase() === currentZone.toLowerCase())
}

const Pillar = ({ pillar, dwellingId, ...rest }: { pillar: AutomationPillar, dwellingId: number }) => {
  const rules = useRulesStore(selectEquipmentRulesByPillar(dwellingId)(pillar))
  const _rules = useFilterByZone(rules)
  const ruleGroups = groupRules(_rules)

  if (!_rules?.length) return (
    <Muted>
      No {pillar} automation rules
    </Muted>
  )

  return (
    <Stack
      {...rest}
    >
      {
        ruleGroups.map(group => (
          <RuleGroup
            key={group.id}
            ruleGroup={group}
            pillar={pillar}
          />
        ))
      }
    </Stack>
  )
}

function Automations({ dwellingId }: { dwellingId: number }) {
  const classes = useClasses()

  return (
    <Grid
      className={classes.root}
      spacing={4}
      container>
      <Grid
        xs={12}
        md={6}
        lg={4}
        item>
        <Stack>
          <ColumnTitle>
            Humidity
          </ColumnTitle>
          <Pillar
            pillar='humidity'
            dwellingId={dwellingId}
          />
        </Stack>
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={4}
        item>
        <Stack>
          <ColumnTitle>
            Ventilation
          </ColumnTitle>
          <Pillar
            pillar='ventilation'
            dwellingId={dwellingId}
          />
        </Stack>
      </Grid>
      <Grid
        xs={12}
        md={6}
        lg={4}
        item>
        <Stack>
          <ColumnTitle>
            Filtration
          </ColumnTitle>
          <Pillar
            pillar='filtration'
            dwellingId={dwellingId}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default Automations
