import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { UserDemoName } from 'state-mngt/models/user'
import { Dwelling } from 'state-mngt/models/dwelling'
import {
  Box, Typography, makeStyles, Button, TablePagination, TableRow, TableCell,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import InfoIcon from '@material-ui/icons/Info'
import tableIcons from 'features/customers-list/table-icons'
import { ROUTE_PATHS } from 'routes/routes'
import { theme } from 'theme'
import { customerTableOptions, demoCustomerTableOptions } from 'features/customers-list/customer-table-options'
import MaterialTable, {
  MTableHeader, MTableBody, MTableToolbar, Column,
} from 'material-table'
import { getPriority, getIssueType } from 'utils/customers-utils'
import InfoTooltip from 'ui/info-tooltip'
import { enableDemoMode } from 'state-mngt/actions/user-actions'
import demoService from 'state-mngt/services/data/demo-service'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import {
  getDemoNameByDeviceId,
} from 'state-mngt/services/data/demo-service/services/demo-dwelling-service/services/demo-device-service/demo-device-service'
import { CUSTOMERS_PAGE_TOUR_SELECTORS } from 'features/product-tour/customers-page'
import { PRODUCT_TOUR_QUERY_KEYS, ProductTourPageName } from 'features/product-tour/pro-portal-tour'


export enum DataType {
  Demo,
  Regular,
}

/**
 * The strings have to match the columns fields from the Customer table because it will be compared against it.
 * The column fileds are the data associated with the table (Dwelling interface model).
 * It's okay if there are more strings within this array than the columns itself but the names have to match.
 */
export enum CustomerTableOrderColumns {
  ID = 'id',
  CREATE_TIMESTAMP = 'create_timestamp',
  CUSTOMER_NAME = 'customer_name',
  DWELLING_NAME = 'name',
  DWELLING_ADDRESS = 'address',
  POOR_AVERAGE_TIME_PERCENTAGE = 'poor_average_time_percentage',
  DEVICE_STATUS_GOOD = 'device_status_good',
  FILTER_STATUS_GOOD = 'filter_status_good',
}

const cellStyle = {
  padding: '8px 16px',
}

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    minHeight: '48px !important',
    marginLeft: 0,
    padding: theme.spacing(3),
    borderBottom: 'none',
  },
  toolbarSearchField: {
    paddingLeft: 0,
  },
}))

interface Props {
  isLoading?: boolean;
  num_dwellings?: number;
  dwellings: Dwelling[];
  type: DataType;
  header?: React.ReactElement | null;
  callbackOnChangePage?: ((page: number) => void);
  callbackOnChangeRowsPerPage?: ((pageSizeLimit: number) => void);
  callbackOnChangeOrder?: ((oderBy: string) => void);
  pageIndex?: number;
  rowsPerPageLimit?: number;
  columnToOrder?: CustomerTableOrderColumns;
  columnOrderDirection?: ('asc' | 'desc' | undefined);
}

export const CustomersTable = (props: Props) => {
  const {
    isLoading, num_dwellings, dwellings, type, header, callbackOnChangePage, callbackOnChangeRowsPerPage,
    callbackOnChangeOrder, pageIndex, rowsPerPageLimit, columnToOrder, columnOrderDirection,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mixpanel } = useMixPanel()

  const tableRef = useRef<MaterialTable<Dwelling>>(null)
  // Table's column id/index that was requested to be ordered by the user.
  // Used for displaying the column's header sorting arrow.
  const orderByColumnIdIndex = useRef<number>(-1)

  /**
   * Navigate to customer drill down page given customer id
   * @param {Dwelling} customer - Dwelling object
   */
  const handleActionClick = (customer: Dwelling) => {
    if (type === DataType.Regular) {
      navigate(generatePath(ROUTE_PATHS.customers.details.root.absolute, {
        dwellingId: customer.id.toString(),
      }))
    } else {
      if (mixpanel) {
        mixpanel.track('pp_customersPage_viewDemoData_' + getDemoNameByDeviceId(customer.id))
      }

      dispatch(enableDemoMode(customer.id))
    }
  }

  const handleSortClick = (orderedColumnId: number, orderDirection: ('asc' | 'desc')) => {

    // If ordering is removed then the default is to order by poor_average_time_percentage (Priority).
    let orderBy: string = '~' + CustomerTableOrderColumns.POOR_AVERAGE_TIME_PERCENTAGE
    orderByColumnIdIndex.current = orderedColumnId

    if (tableRef.current && tableRef.current.props) {
      // orderedColumnId may be -1 if the user is removing the sort, that happens when a descended ordered column is clicked.
      // Let's change to ascending so the user can keep switching between descending and ascending instead of removing sort.
      if (orderedColumnId === -1) {
        // columnToOrder keeps track of the last/current column that is being ordered by.
        if (columnToOrder) {
          orderBy = columnToOrder
          // Get the column number to correctly display the column header with the sorting arrow.
          orderByColumnIdIndex.current = tableRef.current.props.columns.findIndex(value => value.field === columnToOrder)
        } else {
          console.log('Error! Cannot keep ordering because columnToOrder has not been defined! It will order by default!')
        }
      } else {
        // Retrieve the column based on the index/id of the clicked ordered column.
        const column = tableRef.current.props.columns[orderedColumnId]

        if (column) {
          // Make sure the column field matches any name in the table order columns enum.
          const columnField = Object.values(CustomerTableOrderColumns).find(value => value === column.field)

          if (columnField) {
            if (orderDirection === 'desc') {
              // prefix '~' before orderBy for descending.
              orderBy = '~' + columnField
            } else {
              orderBy = columnField
            }
          } else {
            // or the column being sorted was not added to the table order columns enum.
            console.log('Error! Cannot order a column with a field that does not pre-exist in the table order columns enum')
          }
        }
      }
    }

    callbackOnChangeOrder!(orderBy)
  }

  const ViewDemoDataInfoTooltipText = ({ dwellingId }: { dwellingId: number }) => {
    const demoName = demoService.getDemoNameByDwellingId(dwellingId)
    let infoTooltipText

    if (demoName === UserDemoName.Demo1)
      infoTooltipText = 'Exposure to fine particles (PM2.5) can cause eye, nose, throat, and lung irritation, ' +
        'coughing, sneezing, runny nose, and shortness of breath. '
    else if (demoName === UserDemoName.Demo2)
      infoTooltipText = 'High Relative Humidity (RH) can cause mold and mildew growth, which in turn can cause ' +
        'allergies and asthma. '
    else if (demoName === UserDemoName.Demo3)
      infoTooltipText = 'tVOC (Volatile Organic Compounds) can cause eye, nose, and throat discomfort, headaches, ' +
        'fatigue, allergic skin reactions.'
    else
      infoTooltipText = 'This is a demo account. Click on to see how you can use data to explore some common ' +
        'IAQ issues that can be solved through better HVAC.'

    return <>{infoTooltipText}</>
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      style={{
        backgroundColor: theme.palette.grey[100],
        boxShadow: 'none',
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
      icons={tableIcons}
      localization={{
        header: {
          actions: '',
        },
      }}
      isLoading={type === DataType.Regular && isLoading}
      options={type === DataType.Regular ? customerTableOptions : demoCustomerTableOptions}
      columns={[
        {
          title: 'Creation Date',
          field: CustomerTableOrderColumns.CREATE_TIMESTAMP,
          sorting: true,
          searchable: false,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.CREATE_TIMESTAMP),
          type: 'date',
          cellStyle,
        },
        {
          title: 'Customer Name',
          field: CustomerTableOrderColumns.CUSTOMER_NAME,
          sorting: true,
          searchable: true,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.CUSTOMER_NAME),
          cellStyle,
          render: (rowData) => (
            <>
              {rowData.isDemo ?
                (rowData['first_name'] && rowData['last_name'] && `${rowData['first_name']} ${rowData['last_name']}`) :
                (rowData['customer_name'] && `${rowData['customer_name']}`)
              }
              {rowData.isDemo && (
                <Typography variant="caption" display="block">
                  {rowData['name']}
                </Typography>
              )}
            </>
          ),
        },
        {
          title: 'Dwelling Name',
          field: CustomerTableOrderColumns.DWELLING_NAME,
          sorting: true,
          searchable: true,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.DWELLING_ADDRESS),
          cellStyle,
        },
        {
          title: 'Dwelling Address',
          field: CustomerTableOrderColumns.DWELLING_ADDRESS,
          sorting: true,
          searchable: true,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.DWELLING_ADDRESS),
          cellStyle,
        },
        {
          title: '',
          field: 'isDemo',
          sorting: false,
          searchable: false,
          align: 'center',
          cellStyle,
          render: (rowData) => {
            if (rowData.isDemo) {
              return (
                <InfoTooltip
                  placement="right-end"
                  arrow
                  interactive
                  title={
                    <ViewDemoDataInfoTooltipText dwellingId={rowData.id} />
                  }
                >
                  <Button
                    color="primary"
                    variant="contained"
                  >
                    View demo
                  </Button>
                </InfoTooltip>
              )
            }
            return ''
          },
        },
        {
          title: (
            <span data-tour={`${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}`}>
              Priority
            </span>
          ),
          field: CustomerTableOrderColumns.POOR_AVERAGE_TIME_PERCENTAGE,
          sorting: true,
          searchable: false,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.CREATE_TIMESTAMP),
          align: 'center',
          cellStyle,
          render: (rowData) => (
            <Box
              data-tour={
                `${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_PRIORITY_COLUMN}-id:${rowData.id}`
              } >
              {getPriority(rowData.poor_average_time_percentage)}
            </Box>
          ),
        },
        {
          title: (
            <span data-tour={`${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}`}>
              Issue Type
            </span>
          ),
          sorting: false,
          searchable: false,
          width: 100,
          align: 'center',
          cellStyle,
          render: (rowData) => (
            <Box
              data-tour={
                `${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_ISSUE_TYPE_COLUMN}-id:${rowData.id}`
              } >
              {getIssueType(
                rowData.poor_pm_time_percentage, rowData.poor_voc_time_percentage,
                rowData.poor_humidity_time_percentage,
              )}
            </Box>
          ),
        },
        {
          title: 'Hardware Status',
          field: CustomerTableOrderColumns.DEVICE_STATUS_GOOD,
          sorting: true,
          searchable: false,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.CREATE_TIMESTAMP),
          align: 'center',
          cellStyle,
          render: (rowData) => {
            return rowData.device_status_good ? (
              <CheckCircleOutlineOutlinedIcon />
            ) : (
              <CancelIcon
                style={{
                  color: theme.palette.error.main,
                }}
              />
            )
          },
        },
        {
          title: (
            <span data-tour={`${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}`}>
              Filter status
            </span>
          ),
          field: CustomerTableOrderColumns.FILTER_STATUS_GOOD,
          sorting: true,
          searchable: false,
          /*
            Do not use default sort because sorting is done on the server side. If used here it will sort the already
            sorted results, giving inconsistency and displaying incorrectly.
            The sorting arrow for the column header is done through a custom table header overwritten in the components
            section of this table.
           */
          // defaultSort: determineOrderDirection(CustomerTableOrderColumns.CREATE_TIMESTAMP),
          align: 'center',
          cellStyle,
          render: (rowData) => (
            <Box
              data-tour={
                `${CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE_FILTER_STATUS_COLUMN}-id:${rowData.id}`
              } >
              {
                rowData.filter_status_good ?
                  <CheckCircleOutlineIcon /> :
                  (
                    <WarningIcon
                      style={{
                        color: theme.palette.error.main,
                      }}
                    />
                  )
              }
            </Box>
          ),
        },
      ]}
      data={dwellings}
      title=""
      actions={[
        {
          icon: () => (
            <KeyboardArrowRightIcon
              style={{
                color: theme.palette.grey[300],
              }}
            />
          ),
          tooltip: 'View Customer Details',
          onClick: (event, rowData: any) => handleActionClick(rowData),
        },
      ]}
      onRowClick={(event, rowData: any) => handleActionClick(rowData)}
      onOrderChange={(orderedColumnId, orderDirection) => handleSortClick(orderedColumnId, orderDirection)}
      components={{
        Toolbar: (props: any) => {
          return (
            <Box>
              {props.search && (
                <MTableToolbar
                  {...props}
                  classes={{
                    root: classes.toolbarRoot,
                    searchField: classes.toolbarSearchField,
                  }}
                />
              )}
              {/*{header}*/}
              <Box p={2}>
                <Typography variant="h5">
                  {type === DataType.Regular && (
                    <span data-tour={`${CUSTOMERS_PAGE_TOUR_SELECTORS.CUSTOMERS_ACCOUNTS_TABLE}`}>
                      Customer accounts
                    </span>
                  )}

                  {type === DataType.Demo && (
                    <Box display="flex" alignItems="center">
                      Demo accounts
                      <InfoTooltip
                        placement="bottom-start"
                        arrow
                        interactive
                        title={
                          <>
                            By default Demo accounts are prioritized at the top of the page. You can turn them ON or OFF
                            in Settings on Preferences page.
                          </>
                        }
                      >
                        <InfoIcon style={{
                          marginLeft: 8,
                        }} fontSize="small" />
                      </InfoTooltip>
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          )
        },
        Header: (props: any) => {
          if (type === DataType.Demo) {
            // Default table header.
            return (
              <MTableHeader
                {...props}
              />
            )
          } else {
            // Get the column number to correctly display the column header with the sorting arrow.
            orderByColumnIdIndex.current = props.columns.findIndex(
              (value: Column<Dwelling>) => value.field === columnToOrder,
            )

            // Default table header with custom properties. orderDirection and orderBy parameters will display the
            // sorting arrow correctly, based on the ordering requested by the server.
            return (
              <MTableHeader
                {...props}
                orderDirection={columnOrderDirection}
                orderBy={orderByColumnIdIndex.current}
              />
            )
          }
        },
        Body: (props: any) => {
          // Either display the default table body when there are data or a specific one row informing the user that
          // there are no records available and a button to start Pro Portal Product Tour.
          if (dwellings.length !== 0) {
            return (
              <MTableBody
                {...props}
              />
            )
          } else {
            const rowHeight = props.options.padding === 'default' ? 49 : 36
            const tableCellStyle = {
              paddingTop: 0,
              paddingBottom: 0,
              textAlign: 'center',
            }

            return (
              <TableRow
                style={{
                  backgroundColor: 'white',
                  height: rowHeight,
                }}
                key={'empty-' + 0}
              >
                <TableCell
                  style={{
                    ...tableCellStyle,
                    textAlign: 'left',
                  }}
                  colSpan={4}
                  key="empty-"
                >
                  No HAVEN installations to show yet
                </TableCell>
                <TableCell
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: 'center',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      navigate({
                        pathname: ROUTE_PATHS.customers.root.absolute,
                        search: `${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Customers}`,
                      })
                    }}
                  >
                    Show Around
                  </Button>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  <KeyboardArrowRightIcon
                    style={{
                      color: theme.palette.grey[300],
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          }
        },
        Pagination: (props: any) => {
          return (
            <>
              {/* Does not display pagination when there are no data. */}
              {dwellings.length !== 0 &&
                <TablePagination
                  {...props}
                  page={pageIndex}
                  rowsPerPage={rowsPerPageLimit}
                  count={num_dwellings}
                  onChangePage={(event: (React.MouseEvent<HTMLButtonElement> | null), page: number) => {
                    if (callbackOnChangePage) {
                      callbackOnChangePage(page)
                    }
                  }}
                  onChangeRowsPerPage={(event: any) => {
                    if (callbackOnChangeRowsPerPage) {
                      callbackOnChangeRowsPerPage(event.target.value)
                      props.onChangeRowsPerPage(event)
                    }
                  }}
                />
              }
            </>
          )
        },
      }}
    />
  )
}
