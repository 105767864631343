import React, { useCallback, useEffect, useState } from 'react'
import {
  generatePath, Link, useParams,
} from 'react-router-dom'
import {
  Box,
  Breadcrumbs,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { getMillisecondsForTimeFrame } from 'utils/time-utils'
import { ROUTE_PATHS } from 'routes/routes'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import {
  DwellingForReport,
} from 'state-mngt/models/dwelling'
import { User } from 'state-mngt/models/user'
import { ReportOptions } from 'state-mngt/models/report'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import InfoTooltip from 'ui/info-tooltip'
import { theme } from 'theme'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { setDwellingForReport } from 'state-mngt/actions/report-actions'
import { setDeviceDataSetting } from 'state-mngt/actions/device-actions'
import { useSelector } from 'react-redux'
import { usePageTitle, useStateInURLQueryParameter } from 'utils/hooks/router'
import { TelemetryDataType } from 'utils/constants/telemetry-data-type'
import { ReportTabs } from 'features/build-report/report-tabs'
import { ReportControls } from 'features/build-report/report-controls/report-controls'
import { defaultEcosenseOption, reportDefaultOptions } from 'features/build-report/report-options'
import CustomerSelectZone from 'features/customer-drill-down/customer-select-zone'
import { Timeframe } from 'utils/constants/time-interval'
import { selectUser } from 'state-mngt/selectors/user-selectors'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import { selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import useAllZones from 'utils/hooks/useAllZones'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import { Dwelling } from 'stores/dwelling'
import useAdmin from 'utils/hooks/useAdmin'
import useEcosenseDevicesInCurrentDwellingZone from 'utils/hooks/useEcosenseDevicesInCurrentDwellingZone'
import useHasMonitorInZone from 'utils/hooks/useHasMonitorInZone'

export const BuildReportTemplate = () => {
  const { mixpanel } = useMixPanel()
  const dispatch = useAppDispatch()

  const { dwellingId } = useParams()
  const user = useAppSelector(selectUser)
  const companyDetails = useSelector(selectCompanyDetails)

  const dwellingDetails = useCurrentDwellingDetails()
  usePageTitle(companyDetails ? `Customer ${companyDetails.name} - Report` : 'Customer - Report')

  const [zone, setZone] = useStateInURLQueryParameter({
    initialValue: '',
    key: HISTORY_QUERY_KEYS.zone,
  })

  const allZones = useAllZones()

  const [startTime, setStartTime] = useStateInURLQueryParameter<Date>({
    key: HISTORY_QUERY_KEYS.startTime,
    initialValue: new Date(getMillisecondsForTimeFrame(Timeframe.Month)),
    fromQuery: (value) => new Date(parseInt(value, 10)),
    toQuery: (value) => value.valueOf().toString(),
    replace: true,
    initializeInTheUrl: true,
  })

  const [endTime, setEndTime] = useStateInURLQueryParameter<Date>({
    key: HISTORY_QUERY_KEYS.endTime,
    initialValue: new Date(),
    fromQuery: (value) => new Date(parseInt(value, 10)),
    toQuery: (value) => value.valueOf().toString(),
    replace: true,
    initializeInTheUrl: true,
  })

  const [dataTypes, setDataTypes] = useStateInURLQueryParameter<TelemetryDataType[]>({
    key: HISTORY_QUERY_KEYS.dataTypes,
    initialValue: [
      TelemetryDataType.PM,
      TelemetryDataType.VOC,
      TelemetryDataType.INDOOR_DEW_POINT,
      TelemetryDataType.HUMIDITY, // Indoor RH
      TelemetryDataType.TEMPERATURE, //Indoor Temperature
      TelemetryDataType.OUTDOOR_AQI,
      TelemetryDataType.OUTDOOR_DEW_POINT,
      TelemetryDataType.OUTDOOR_HUMIDITY, //Outdoor RH
      TelemetryDataType.OUTDOOR_TEMPERATURE,
    ],
    fromQuery: (queryValue) => queryValue.split(',').filter((item) => item !== 'none') as TelemetryDataType[],
    toQuery: (stateValue) => (stateValue.length > 0 ? stateValue.join(',') : 'none'),
    initializeInTheUrl: true,
  })

  const [selectedDeviceIds, setSelectedDeviceIds] = useStateInURLQueryParameter<string[]>({
    key: HISTORY_QUERY_KEYS.devices,
    initialValue: [],
    fromQuery: (queryValue) => queryValue.split(',').filter((item) => item !== 'none') as TelemetryDataType[],
    toQuery: (stateValue) => (stateValue.length > 0 ? stateValue.join(',') : 'none'),
    initializeInTheUrl: true,
  })

  const [options, setOptions] = useState<ReportOptions[]>(
    reportDefaultOptions.map((item) => ({
      ...item,
      isChecked: dataTypes.includes(item.type) || selectedDeviceIds.includes(item.id || ''),
    })),
  )

  const [tabClicked, setTabClicked] = useState<number>(0)
  const admin = useAdmin()
  const ecosenseDevices = useEcosenseDevicesInCurrentDwellingZone()
  const hasMonitor = useHasMonitorInZone()

  const updateOptions = useCallback(
    (options: ReportOptions[]) => {
      setOptions(options)
      const updatedDataTypes = options.filter((item) => item.isChecked).map((item) => item.type)
      if (dataTypes.length !== updatedDataTypes.length) {
        setDataTypes(updatedDataTypes)
      }
    },
    [dataTypes.length, setDataTypes],
  )

  useEffect(() => {
    setOptions((options) =>
      options.map((item) => ({
        ...item,
        isChecked: dataTypes.includes(item.type),
      })),
    )
  }, [dataTypes])

  useEffect(() => {
    if (!ecosenseDevices?.length) return
    setOptions(prev => [
      ...prev,
      ...ecosenseDevices.map(x => defaultEcosenseOption(x)),
    ])
    setSelectedDeviceIds(ecosenseDevices.map(x => x.serial_number))
  }, [ecosenseDevices?.length])

  /**
   * update dwelling details for export in store, no db changes here just for pdf export
   * @param dwelling - Dwelling
   * @param admin - User
   */
  const setDwellingForReportInStore = useCallback((dwelling: Dwelling, admin: User) => {
    const updatedDwellingDetails: DwellingForReport = {
      first_name: admin.first_name ? admin.first_name : '',
      last_name: admin.last_name ? admin.last_name : '',
      email: admin.email,
      phone: admin.phone ? admin.phone : '',
      street_1: dwelling.street_1,
      street_2: dwelling.street_2,
      city: dwelling.city,
      province: dwelling.province,
      country: dwelling.country,
      postal_code: dwelling.postal_code,
    }
    dispatch(setDwellingForReport(updatedDwellingDetails))
  }, [setDwellingForReport, dispatch])

  useEffect(() => {
    if (!user) {
      return
    }
  }, [user])

  // Initially set the zone to the URL if isn't set yet. Do it only once.
  useEffect(() => {
    if (!zone && allZones.length > 0) {
      setZone(allZones[0], true)
    }
  }, [allZones, zone, setZone])

  useEffect(() => {
    dispatch(
      setDeviceDataSetting({
        startTime: startTime.valueOf(),
        endTime: endTime.valueOf(),
        secondType: '',
        selectedOverlayOptions: ['Thresholds', 'Airflow inactive'],
      }),
    )
  }, [
    endTime,
    startTime,
    setDeviceDataSetting,
  ])

  useEffect(() => {
    if (dwellingDetails && admin) {
      setDwellingForReportInStore(dwellingDetails, admin)
    }
  }, [
    admin?.id,
    dwellingDetails?.id,
    setDwellingForReportInStore,
  ])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_reportPage_open')
    }
  }, [mixpanel])

  const onClickExport = () => {
    // const isSafari = () => {
    //   const agentHas = (keyword: string) => {
    //     return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
    //   }
    //   // @ts-ignore
    //   return (!!window.ApplePaySetupFeature || !!window.safari) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS");
    // }

    // const el = document.getElementById('report-to-send')
    // if (el && isSafari()) el.style.width = '302mm'
    // if (el && !isSafari()) el.style.width = '294mm'

    window.print()
  }

  /**
   * callback fn for tab clicks
   * @param index - number
   */
  const tabChange = (index: number) => {
    setTabClicked(index)
  }

  /**
   * callback fn for clicking Exit Report btn
   */
  const handleExit = () => {
    if (mixpanel) {
      mixpanel.track('pp_reportPage_exitButton')
    }
  }

  return (
    <>
      <Box
        p={1}
        style={{
          backgroundColor: theme.palette.grey[200],
        }}
        display="flex"
        alignItems="center"
      >
        {hasMonitor ? (
          <Breadcrumbs>
            <Link to={ROUTE_PATHS.customers.root.absolute}>
              <Typography color="textPrimary" variant="subtitle1">
                Customers
              </Typography>
            </Link>
            <Link
              to={generatePath(ROUTE_PATHS.customers.details.root.absolute, {
                dwellingId,
              })}
              style={{
                color: '#888',
              }}
            >
              <Typography color="textPrimary" variant="subtitle1">
                {admin?.first_name &&
                  admin?.last_name &&
                  dwellingDetails?.street_1 ?
                  admin.first_name + ' ' + admin.last_name + ' - ' + dwellingDetails.street_1 :
                  'Dwelling'}
              </Typography>
            </Link>
            <CustomerSelectZone
              allZones={allZones}
              setZone={setZone}
            />
            <Typography color="textPrimary" variant="subtitle1">
              Build a Report
            </Typography>
          </Breadcrumbs>
        ) : (
          <Typography variant="caption">No monitor found</Typography>
        )}
      </Box>
      <Box display="flex">
        <Box width={480}>
          <Box
            display="flex"
            alignItems="center"
            pb={2}
            pt='38px'
            px={4}
          >
            <Typography variant="h5">Build a Report</Typography>
            <InfoTooltip
              placement="right-end"
              arrow
              interactive
              title={
                <>
                  <p>
                    This feature is currently part of an indefinite Free Trial which means we haven’t set up a date when
                    it will be part of a paid tier of software services.
                  </p>
                  <p>
                    Please note, you will <b>not</b> be charged for it during this Free Trial phase. We’ll keep you
                    posted on any future changes to the feature.
                  </p>
                </>
              }
            >
              <Box
                height={18}
                ml={1}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '.25rem',
                  fontSize: '.8rem',
                  color: 'white',
                  padding: '0 .25rem',
                  cursor: 'help',
                }}
              >
                TRIAL
              </Box>
            </InfoTooltip>
          </Box>
          {dwellingDetails && admin && (
            <ReportControls
              dwelling={dwellingDetails}
              admin={admin}
              setOptionChange={updateOptions}
              tabClick={tabChange}
              smallTabClicked={tabClicked}
              options={options}
              startTime={startTime}
              endTime={endTime}
              setStart={setStartTime}
              setEnd={setEndTime}
            />
          )}
        </Box>
        {(
          dwellingDetails &&
          options &&
          startTime &&
          endTime &&
          admin) ?
          (
            <ReportTabs
              // deviceQuality={deviceQuality}
              options={options}
              tabClicked={tabClicked}
              tabClick={tabChange}
              startTime={startTime}
              endTime={endTime}
            />
          ) : (
            <Box
              width='100%'
              pt={8}
              display='flex'
              alignItems='flex-start'
              justifyContent='center'
            >
              <Box>
                <Typography gutterBottom>Generating content...</Typography>
                <LinearProgress color="secondary" />
              </Box>
            </Box>
          )}
      </Box>
      <Box
        display="flex"
        position="fixed"
        justifyContent="space-between"
        bottom="0"
        padding="1rem"
        width="100%"
        style={{
          backgroundColor: '#CFD8DC',
          zIndex: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleExit}
          component={Link}
          to={generatePath(ROUTE_PATHS.customers.details.root.absolute, {
            dwellingId,
          })}
          color="primary"
          endIcon={<ExitToAppIcon />}
        >
          Exit
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickExport}
          disabled={
            options.filter((o) => o.isChecked).length === 0
          }
        >
          Export
        </Button>
      </Box>
    </>
  )
}
