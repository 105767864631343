import { makeStyles } from "@material-ui/core"

const useClasses = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    backgroundColor: theme.palette.charcoalLightestTint,
  },
  tabPanel: {
    minHeight: '100%',
    backgroundColor: theme.palette.charcoalLightestTint,
    padding: '32px !important',
    margin: 0,
  },
  ruleContainer: {
    flexDirection: 'column',
  },
  ruleTitle: {
    color: '#7A8D98',
    fontSize: 12,
  },
  rule: {
    padding: 16,
    paddingTop: 8,
    boxShadow: 'none',
    borderRadius: '13px',
  },
  ruleNoPadding: {
    boxShadow: 'none',
    borderRadius: '13px',
  },
  padding: {
    padding: 16,
    paddingTop: 8,
  },
}))

export default useClasses
