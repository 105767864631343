import React from 'react'
import { ServiceCompanyRole } from 'state-mngt/models/serviceCompany'
import { useSelector } from 'react-redux'
import { selectUserRole } from 'state-mngt/selectors/user-selectors'
import { MemberRole } from 'utils/constants/member-enums'


interface Props {
  children: React.ReactElement;
  roles?: MemberRole[];
}

export const AccessControl: React.FC<Props> = ({ children, roles }) => {
  const companyRole = useSelector(selectUserRole)

  return isAllowed(companyRole, roles) ? children : null
}

/**
 * It returns the service company role if there is a match for the team-mngt member role type. Otherwise, returns false.
 * @param serviceRole The current user logged in the system roles in the service company it belongs to.
 * @param role The requested role that will be checked against the possible service company roles to match.
 */
const getServiceRoleByMemberRole = (serviceRole: ServiceCompanyRole, role: MemberRole): boolean => {
  switch (role) {
    case MemberRole.ADMIN: {
      return serviceRole.manager
    }
    case MemberRole.ANALYST: {
      return serviceRole.analyst
    }
    case MemberRole.TECHNICIAN: {
      return serviceRole.technician
    }
    default: {
      return false
    }
  }
}

/**
 *
 * @param companyRole
 * @param roles
 */
export const isAllowed = (companyRole?: ServiceCompanyRole, roles: MemberRole[] = []): boolean => {
  if (!companyRole) {
    return false
  }

  // Checks if current user logged in the system has the permission/role as the ones requested in "on".
  if (roles.length > 0) {
    if (roles.some((currentRole) => !getServiceRoleByMemberRole(companyRole, currentRole))) {
      return false
    }
  }

  return true
}
