import React, { useEffect } from 'react'
import {
  Button,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useState } from "react"
import httpService from 'state-mngt/services/data/http-service'
import {
  selectAutomation, selectLogsByAutomationId, updateAutomation, updateRuleOutputs, useAutomationsStore, useEquipmentStore, useLogsStore, useRulesStore, 
} from './store'
import OverrideModal from './overrideModal'
import differenceInHours from "date-fns/differenceInHours"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInSeconds from "date-fns/differenceInSeconds"
import differenceInDays from "date-fns/differenceInDays"
import Stack from 'ui/stack'
import Muted from './muted'
import { Automation } from 'types'

const useStyles = makeStyles(theme => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '11px',
  },
  modalContent: {
    padding: '8px 16px',
    '> *': {
      marginTop: '32px',
    },
  },
  modalFooter: {
    border: `1px solid ${theme.palette.charcoalLightTint}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  controls: {
    marginTop: '16px',
  },
}))

const overrideKeys = (pillar) => ({
  stateKey: `${pillar}_override_on`,
  timeoutKey: `${pillar}_override_timeout`,
})

const aboveZero = x => Math.round(Math.max(0, x))

const _format = (end, start) => {
  const days = differenceInDays(end, start)
  const hours = aboveZero(differenceInHours(end, start) % 24)
  const minutes = aboveZero(differenceInMinutes(end, start) % 60)
  const seconds = aboveZero(differenceInSeconds(end, start) % 60)

  return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
}

// const useUntil = (time, cancel) => {
//     const [until, setUntil] = useState<string | null>(null)
//     useEffect(() => {
//         if (!time) return

//         const interval = setInterval(() => {
//             if (time - Date.now() <= 0) {
//                 clearInterval(interval)
//                 cancel()
//                 return setUntil(null)
//             }
//             setUntil(format(time, 'hh:mm a'))
//         }, 1000)

//         return () => clearInterval(interval)
//     }, [time])

//     return until
// }

const useTime = (time, cancel) => {
  const [duration, setDuration] = useState<string | null>(null)

  useEffect(() => {
    if (!time) return

    const interval = setInterval(() => {
      if (time - Date.now() <= 0) {
        clearInterval(interval)
        cancel()
        return setDuration(null)
      }
      setDuration(_format(time, Date.now()))
    }, 1000)

    return () => clearInterval(interval)
  }, [time])

  return duration
}

function useEquipmentIsTriggered(automationId, pillar) {
  const [isTriggered, setIsTriggered] = useState(false)
  const logs = useLogsStore(selectLogsByAutomationId(automationId))

  useEffect(() => {
    if (!logs?.length) return

    const _logs = logs.sort((x, y) => new Date(x.start_timestamp).getTime() - new Date(y.start_timestamp).getTime())
    const latestLog = _logs[_logs.length - 1]

    const isTriggered = !latestLog?.start_timestamp ||
            (new Date() < new Date(latestLog.stop_timestamp))

    setIsTriggered(isTriggered)
  }, [JSON.stringify(logs)])

  return isTriggered
}

function Override({
  automationId,
  ruleId,
  pillar,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { stateKey, timeoutKey } = overrideKeys(pillar)

  const automation = useAutomationsStore(selectAutomation(automationId))

  const equipmentIsTriggered = useEquipmentIsTriggered(automationId, pillar)

  const on = Boolean(automation[timeoutKey])

  const save = async (data: Partial<Automation>) => {
    try {
      await httpService.post(`/automation/${automationId}`, data)
      updateAutomation(automationId)(data)
    } catch (e) {
      console.error(e)
    }
  }

  const override = async (time) => {
    setLoading(true)
    const data = {
      [stateKey]: !equipmentIsTriggered,
      [timeoutKey]: time,
    }
    await save(data)
    updateRuleOutputs(ruleId)({
      currently_triggered: true, 
    })
    setOpen(false)
    setLoading(false)
  }

  const cancel = async () => {
    const data = {
      [timeoutKey]: null,
      [stateKey]: !automation[stateKey],
    }
    await save(data)
    updateRuleOutputs(ruleId)({
      currently_triggered: false, 
    })
  }

  const timer = useTime(new Date(automation[timeoutKey]).getTime(), cancel)
  // const until = useUntil(new Date(automation[timeoutKey]).getTime(), cancel)
  const state = automation[stateKey] ? 'on' : 'off'

  if (on) return (
    <Stack
      spacing={1}
      direction='row'
    >
      <Typography variant='caption'><Muted>Equipment is {state} for {timer}</Muted></Typography>
      <Button
        className={classes.cancelButton}
        disabled={loading}
        onClick={cancel}
      >Cancel</Button>
    </Stack>
  )

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={loading}
        color='primary'>
                Override
      </Button>
      <OverrideModal
        isOpen={open}
        loading={loading}
        equipmentIsTriggered={equipmentIsTriggered}
        onClose={() => setOpen(false)}
        override={override}
      />
    </>
  )
}

export default Override
