import { theme } from 'theme'

/**
 *
 */
export const CUSTOMER_HISTORY_CONTAINER_ID = {
  demoCustomer: 'customer-history',
  realCustomer: 'customer-device-chart',
}

/**
 * Color used for representing the IAQ measurements PM and VOC thresholds colors for pie charts.
 */
export const pmOrVocColors = [
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.success.main,
  theme.palette.grey[200],
]

/**
 * Color used for representing the IAQ measurement RH thresholds colors for pie charts.
 */
export const rhColors = [
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.success.main,
  theme.palette.warning.main,
  theme.palette.error.main,
  theme.palette.grey[200],
]

export const pieChartColors = [
  theme.palette.success.main,
  theme.palette.warning.main,
  theme.palette.error.main,
  theme.palette.grey[200],
]

export const pieChartHumidityColors = [
  theme.palette.error.main,
  theme.palette.warning.main,
  theme.palette.success.main,
  theme.palette.warning.main,
  theme.palette.error.main,
  theme.palette.grey[200],
]

/**
 * Add null data points to break line, for every gap in the data, a null point need to be inserted for line to break
 * @param telemetryData - DeviceTelemetry[]
 * @returns modifiedData - DeviceTelemetry[]
 */
export const getNormalizedTelemetry = (
  <TType extends { timestamp: string }>(telemetryData: TType[], breakItem: TType): TType[] => {
    const modifiedArr = [...telemetryData]
    // generate null to break chart line
    if (telemetryData.length > 1) {

      const firstPoint = new Date(telemetryData[0].timestamp).getTime()
      const secondPoint = new Date(telemetryData[1].timestamp).getTime()
      const interval = secondPoint - firstPoint

      for (let i = 1; i < telemetryData.length; i++) {
        const currentPoint = new Date(telemetryData[i].timestamp).getTime()
        const prevPoint = new Date(telemetryData[i - 1].timestamp).getTime()
        if (currentPoint - prevPoint > interval) {
          const index = modifiedArr.findIndex(d => d.timestamp === telemetryData[i].timestamp)
          modifiedArr.splice(index, 0,
            {
              ...breakItem,
              timestamp: new Date(prevPoint + interval).toISOString().split('.')[0] + '+00:00',
            },
          )
        }
      }
    }

    return modifiedArr
  }
)

const now = Date.now()

// add +/- to dates. used to add
// more data than is visible to the
// charts
const padDates = (startTime: Date | null, endTime: Date | null) => {
  if (!startTime || !endTime) return []

  const diff = endTime.getTime() - startTime.getTime()
  const factor = Math.round(diff)
  const start = new Date(startTime.getTime() - factor)
  const end = new Date(Math.min(endTime.getTime() + factor, now))

  return [start, end]
}

// const downsample = (
//   data: OutdoorAir[],
//   interval: number,
//   key: string,
// ) => {
//   if (!data?.length) return []
//   const downsampled: OutdoorAir[] = []

//   let currentIntervalStart = new Date(data[0].timestamp).getTime()
//   let currentIntervalEnd = currentIntervalStart + interval
//   let intervalHigh = data[0][key]
//   let intervalLow = data[0][key]

//   for (let i = 1; i < data.length; i++) {
//     const time = new Date(data[i].timestamp).getTime()
//     const value = data[i][key]

//     if (time >= currentIntervalEnd) {

//     } else {

//     }
//   }
// }

export {
  padDates,
}
