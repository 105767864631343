import { Automation, AutomationResponse, AutomationRule } from "types"
import {
  selectAutomation,
  selectRule,
  tempKey,
  useAutomationsStore,
  useRulesStore,
} from "./store"
import httpService from "state-mngt/services/data/http-service"

const merge = (entity, entities) => {
  return entities.map(x => x.id === entity.id ? entity : x)
}

const mergeRule = (tempRule: AutomationRule, automation: Automation): Automation => {
  return {
    ...automation,
    rules: merge(tempRule.trigger, automation.rules),
    outputs: automation.outputs.map(x => {
      const match = tempRule.outputs.find(y => y.id === x.id)
      if (match) return match
      return x
    }),
    inputs: automation.inputs.map(x => {
      const match = tempRule.inputs.find(y => y.id === x.id)
      if (match) return match
      return x
    }),
  }
}

function useMergeTemporaryRuleIntoAutomation(ruleId) {
  const tempRule = useRulesStore(selectRule(tempKey(ruleId)))
  const automation = useAutomationsStore(selectAutomation(tempRule?.automationId))
  if (!tempRule || !automation) return null
  return mergeRule(tempRule, automation)
}

async function save(automation) {
  try {
    await httpService.post(`/automation/${automation.id}`, automation)
  } catch (e) {
    console.error(e)
  }
}

/**
 * used for persisting an automation that has been
 * changed via a temporary rule. merges the temporary
 * rule into the automation, and then saves the changes
 */
function useSaveTemporaryRuleToAutomation(ruleId?: number) {
  if (!ruleId) return () => Promise.resolve()
  const mergedAutomation = useMergeTemporaryRuleIntoAutomation(ruleId)
  if (!mergedAutomation) return () => Promise.resolve()
  return () => save(mergedAutomation)
}

export {
  useMergeTemporaryRuleIntoAutomation,
}

export default useSaveTemporaryRuleToAutomation
