import {
  Box,
  CircularProgress,
  Tab,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useClasses from './useClasses'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import Automations from './automations'
import Schedules from './schedules'
import mixpanel from 'mixpanel-browser'

function AutomationsAndSchedules({
  dwellingId,
  loading,
}: {
  dwellingId: number,
  loading: boolean,
}) {
  const classes = useClasses()
  const [tab, setTab] = useState<'automations' | 'schedules'>('automations')

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track_pageview()
    }
  }, [mixpanel])

  const handleTabChange = (e, v) => {
    setTab(v)
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <TabContext
      value={tab}
    >
      <TabList
        onChange={handleTabChange}
      >
        <Tab value='automations' label='Automations' />
        <Tab value='schedules' label='Schedules' />
      </TabList>
      <TabPanel
        className={classes.tabPanel}
        value='automations'
        tabIndex={0}>
        <Automations
          dwellingId={dwellingId}
        />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value='schedules'
        tabIndex={1}>
        <Schedules
          dwellingId={dwellingId}
        />
      </TabPanel>
    </TabContext>
  )
}

export default AutomationsAndSchedules
