import {
  Box,
  CircularProgress,
  Paper,
} from '@material-ui/core'
import React from 'react'
import { TelemetryDataType } from 'utils/constants/telemetry-data-type'
import { DwellingForReport } from 'state-mngt/models/dwelling'
import {
  getThreshold,
  qualityToPieChartData,
  getLegendStat,
  pluckQuality,
} from 'utils/report-utils'
import PieChart from 'ui/pie-chart'
import 'features/build-report/report-preview/report-preview.scss'
import { theme } from 'theme'
import { getUserPreferences } from 'state-mngt/selectors/user-selectors'
import { getDwellingReport } from 'state-mngt/selectors/report-selectors'
import { selectCompanyDetails, selectIsCompanyDetailsLoading } from 'state-mngt/selectors/company-selectors'
import ReportLogo from 'features/build-report/report-preview/report-logo'
import { DeviceChart } from 'features/customer-drill-down/charts/device-chart'
import { pieChartColors, pieChartHumidityColors } from 'features/customer-drill-down/charts/device-chart/chart-utils'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import useMonitorInCurrentZone from 'features/customer-drill-down/charts/device-chart/useMonitorInCurrentZone'
import { Device } from 'types'
import { AllDeviceQuality } from 'features/customer-drill-down/charts/customer-history/historyPieCharts'
import { ReportOptions } from 'state-mngt/models/report'
// import images as base64
const IconReportsGeneralInfo = require('features/build-report/report-preview/images/icon-reports-general-info.png')
const IconReportsPiecharts = require('features/build-report/report-preview/images/icon-reports-piecharts.png')
const IconReportsComments = require('features/build-report/report-preview/images/icon-reports-sc-comments.png')
const IconReportsCauses = require('features/build-report/report-preview/images/icon-reports-causes.png')

const getFormattedAddress = (dwelling?: DwellingForReport): string => {
  if (!dwelling) {
    return ''
  }
  const {
    street_1,
    city,
    province,
    postal_code,
    country,
  } = dwelling

  return `${street_1}, ${city}, ${province}, ${postal_code}, ${country}`
}

const getFormattedDate = (device?: Device | null): string => {
  if (device && device.install_timestamp) {
    const { install_timestamp } = device
    const date = new Date(install_timestamp)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  }
  return 'N/A'
}

interface ReportPreviewProps {
  deviceQuality: AllDeviceQuality
  option: ReportOptions
  pageNum: number
  startTime: Date
  endTime: Date
  totalPageNum: number
}

const ReportPreview = ({
  deviceQuality,
  option,
  pageNum,
  startTime,
  endTime,
  totalPageNum,
}: ReportPreviewProps) => {

  const userPreferences = useAppSelector(getUserPreferences)
  const dwelling = useAppSelector(getDwellingReport)
  const companyDetails = useAppSelector(selectCompanyDetails)
  const isCompanyDetailsLoading = useAppSelector(selectIsCompanyDetailsLoading)
  const device = useMonitorInCurrentZone()

  return (
    <>
      {isCompanyDetailsLoading && (
        <Paper elevation={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {!isCompanyDetailsLoading && (
        <Paper
          elevation={3}
          className="report-chart"
          id="report-content"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: 'auto',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: '.5rem 0',
            }}
          >
            <div
              className="report-header"
              style={{
                height: '72px',
                display: 'flex',
              }}
            >
              <div
                style={{
                  width: '50%',
                  padding: '0 .5rem',
                  display: 'flex',
                }}
              >
                {companyDetails?.logo_url && (
                  <img src={companyDetails?.logo_url} alt="service company logo" height={64} />
                )}
                {!companyDetails?.logo_url && (
                  <h3
                    style={{
                      display: 'inline',
                      lineHeight: '48px',
                      color: theme.palette.primary.main,
                      margin: '0 4px',
                      fontWeight: 'normal',
                    }}
                  >
                    {companyDetails?.name}
                  </h3>
                )}
                <ReportLogo style={{
                  marginLeft: '1rem',
                }} height={64} />
              </div>
              <div
                style={{
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                <h2
                  style={{
                    color: theme.palette.primary.main,
                    margin: '0 1rem',
                  }}
                >
                  <span
                    style={{
                      marginTop: '2px',
                    }}
                  >
                    Home Air Report
                  </span>
                  <span
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '.25rem',
                      padding: '0 4px',
                      marginLeft: '4px',
                      fontSize: '.8rem',
                      fontWeight: 'lighter',
                      color: 'white',
                      transform: 'translateY(-2px)',
                      display: 'inline-block',
                    }}
                  >
                    TRIAL
                  </span>
                </h2>
                <small
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  PAGE {pageNum}/{totalPageNum}
                </small>
              </div>
            </div>

            {/* User Information section. */}
            <div
              className="report-dwelling-info"
              style={{
                padding: '0 .5rem',
                backgroundColor: '#F7FBFD',
                display: 'flex',
              }}
            >
              <div
                style={{
                  width: '25%',
                  padding: '4px',
                }}
              >
                <h4
                  style={{
                    margin: '.5rem 0 .5rem',
                  }}
                >
                  Contact
                </h4>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  {dwelling?.first_name} {dwelling?.last_name}
                </p>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  {dwelling?.phone}
                </p>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  {dwelling?.email}
                </p>
              </div>
              <div
                style={{
                  width: '25%',
                  padding: '4px',
                }}
              >
                <h4
                  style={{
                    margin: '.5rem 0 .5rem',
                  }}
                >
                  Address
                </h4>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  {getFormattedAddress(dwelling)}
                </p>
              </div>
              <div
                style={{
                  width: '25%',
                  padding: '4px',
                }}
              >
                <h4
                  style={{
                    margin: '.5rem 0 .5rem',
                  }}
                >
                  Device
                </h4>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  {device?.plastic_serial_number}
                </p>
                {device?.name && (
                  <p
                    style={{
                      margin: '.25rem',
                      marginLeft: 0,
                      fontSize: '12px',
                    }}
                  >
                    Nickname: {device?.name}
                  </p>
                )}
                {device?.zone && (
                  <p
                    style={{
                      margin: '.25rem',
                      marginLeft: 0,
                      fontSize: '12px',
                    }}
                  >
                    Zone: {device?.zone}
                  </p>
                )}
              </div>
              <div
                style={{
                  width: '25%',
                  padding: '4px',
                }}
              >
                <h4
                  style={{
                    margin: '.5rem 0 .5rem',
                  }}
                >
                  Installation
                </h4>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  Installed: {getFormattedDate(device)}
                </p>
                <p
                  style={{
                    margin: '.25rem',
                    marginLeft: 0,
                    fontSize: '12px',
                  }}
                >
                  Type: Permanent
                </p>
              </div>
            </div>

            {/* Chart Header */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 40px 0 44px',
                marginTop: '4px',
                alignItems: 'center',
              }}
            >
              <h4
                style={{
                  color: theme.palette.primary.main,
                  lineHeight: '.5',
                }}
              >
                {option.tabLabel}
              </h4>
              <h5
                style={{
                  lineHeight: '.5',
                }}
              >
                From {startTime.toLocaleString()} To {endTime.toLocaleString()}
              </h5>
            </div>

            {/* Chart Section. */}
            {option.type && userPreferences && (
              <DeviceChart
                containerId="report-content"
                dataLabel={option.label}
                dataType={option.id || option.type}
                isCurrentStatusCollapsed={false}
                interactive={false}
                isSidebarCollapsed={true}
                offsetLeft={0}
                isReport
              />
            )}

            {/* Bottom section containing additional information about the report. */}
            <div
              style={{
                margin: '0 .5rem',
              }}
            >
              {/* Bottom section header containing the measurement as a label header. */}
              {((option.type !== TelemetryDataType.OUTDOOR_AQI &&
                option.type !== TelemetryDataType.OUTDOOR_DEW_POINT &&
                option.type !== TelemetryDataType.OUTDOOR_HUMIDITY &&
                option.type !== TelemetryDataType.OUTDOOR_TEMPERATURE) ||
                ((option.type === TelemetryDataType.OUTDOOR_AQI ||
                  option.type === TelemetryDataType.OUTDOOR_DEW_POINT ||
                  option.type === TelemetryDataType.OUTDOOR_HUMIDITY ||
                  option.type === TelemetryDataType.OUTDOOR_TEMPERATURE) && option.hasComments)) && (
                <div
                  style={{
                    marginLeft: '20px',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '10px 10px 0 0',
                    color: 'white',
                    display: 'inline-block',
                    padding: '.25rem 1rem',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {option.tabLabel}
                  </span>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  boxShadow: '4px 4px 10px #F0F0F0',
                  border: '1px solid #EEE',
                }}
              >
                {/* Comments for the bottom section of the report. Only displays if include comments checkbox is marked. */}
                {option.hasComments && (
                  <div
                    style={{
                      width: '46%',
                      padding: '.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <img src={IconReportsComments} alt="comment icon" height="32px" />
                      <h4
                        className="comment-title"
                        style={{
                          margin: '4px 4px 8px',
                        }}
                      >
                        Comments from your service company
                      </h4>
                    </div>
                    <p
                      style={{
                        margin: 0,
                        padding: '4px',
                      }}
                    >
                      {option.comments}
                    </p>
                  </div>
                )}

                {/* Pie Chart for the bottom section of the report. */}
                {(option.type !== TelemetryDataType.TEMPERATURE && option.type !== TelemetryDataType.OUTDOOR_AQI &&
                  option.type !== TelemetryDataType.OUTDOOR_DEW_POINT &&
                  option.type !== TelemetryDataType.OUTDOOR_HUMIDITY &&
                  option.type !== TelemetryDataType.OUTDOOR_TEMPERATURE) && (
                  <div
                    style={{
                      width: option.hasComments ? '54%' : '34%',
                      padding: '.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <img
                        src={IconReportsPiecharts}
                        style={{
                          marginRight: '4px',
                        }}
                        width="24px"
                        height="24px"
                        alt="clock icon"
                      />
                      <h4
                        className="comment-title"
                        style={{
                          margin: '4px 4px 8px',
                        }}
                      >
                        {option.pieChartLabel}
                      </h4>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <PieChart
                        data={qualityToPieChartData(option.type, deviceQuality, option.id)}
                        title=""
                        colors={option.type === TelemetryDataType.HUMIDITY ? pieChartHumidityColors : pieChartColors}
                        isReport={true}
                      />
                      {(qualityToPieChartData(option.type, deviceQuality, option.id)).length ? (
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: 'calc(100% - 50px)',
                          }}
                        >

                          {/* Poor Threshold For Non Humidity. */}
                          {option.type !== TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.error.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Poor: {getLegendStat(
                                    startTime,
                                    endTime,
                                    pluckQuality(deviceQuality, option.type, option.id)['poor'],
                                  )}
                                </h5>
                              </div>
                              {getThreshold(option, 2, userPreferences)}
                            </div>
                          )}

                          {/* Above Poor Thresholds For Humidity. */}
                          {option.type === TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.error.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Too humid: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['too_humid'])}
                                </h5>
                              </div>
                              {getThreshold(option, 4, userPreferences)}
                            </div>
                          )}

                          {/* Fair Threshold For Non Humidity. */}
                          {option.type !== TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.warning.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Fair: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['fair'])}
                                </h5>
                              </div>
                              {getThreshold(option, 1, userPreferences)}
                            </div>
                          )}

                          {/* Above Fair Threshold For Humidity. */}
                          {option.type === TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.warning.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Humid: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['humid'])}
                                </h5>
                              </div>
                              {getThreshold(option, 3, userPreferences)}
                            </div>
                          )}

                          {/* Good/Optional Threshold. */}
                          <div
                            style={{
                              width: option.hasComments ? '50%' : '100%',
                              marginBottom: '.5rem',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: '100%',
                                  height: '8px',
                                  width: '8px',
                                  margin: '3px',
                                  backgroundColor: theme.palette.success.main,
                                }}
                              />
                              <h5
                                style={{
                                  margin: 0,
                                }}
                              >
                                {option.type !== TelemetryDataType.HUMIDITY ? 'Good: ' : 'Optimal: '}
                                {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['good'])}
                              </h5>
                            </div>
                            {getThreshold(option, (option.type !== TelemetryDataType.HUMIDITY ? 0 : 2), userPreferences)}
                          </div>


                          {/* Bellow Fair Threshold For Humidity. */}
                          {option.type === TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.warning.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Dry: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['dry'])}
                                </h5>
                              </div>
                              {getThreshold(option, 1, userPreferences)}
                            </div>
                          )}

                          {/* Bellow Poor Thresholds For Humidity. */}
                          {option.type === TelemetryDataType.HUMIDITY && (
                            <div
                              style={{
                                width: option.hasComments ? '50%' : '100%',
                                marginBottom: '.5rem',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: '100%',
                                    height: '8px',
                                    width: '8px',
                                    margin: '3px',
                                    backgroundColor: theme.palette.error.main,
                                  }}
                                />
                                <h5
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                    Too dry: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['too_dry'])}
                                </h5>
                              </div>
                              {getThreshold(option, 0, userPreferences)}
                            </div>
                          )}

                          {/* No Data Threshold. */}
                          <div
                            style={{
                              width: option.hasComments ? '50%' : '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: '100%',
                                  height: '8px',
                                  width: '8px',
                                  margin: '3px',
                                  backgroundColor: theme.palette.grey[200],
                                }}
                              />
                              <h5
                                style={{
                                  margin: 0,
                                }}
                              >
                                  No Data: {getLegendStat(startTime, endTime, pluckQuality(deviceQuality, option.type, option.id)['no_data'])}
                              </h5>
                            </div>
                            <small
                              style={{
                                display: 'block',
                                marginLeft: '.75rem',
                              }}
                            >
                                (Fan off or disconnected)
                            </small>
                          </div>
                        </div>

                      ) :
                        null
                      }
                    </div>
                  </div>
                )}

                {/* General Info on the bottom section of the report. */}
                {(option.type !== TelemetryDataType.OUTDOOR_AQI &&
                  option.type !== TelemetryDataType.OUTDOOR_DEW_POINT &&
                  option.type !== TelemetryDataType.OUTDOOR_HUMIDITY &&
                  option.type !== TelemetryDataType.OUTDOOR_TEMPERATURE) && (
                  <div
                    style={{
                      width: option.hasComments ? '46%' : option.type === TelemetryDataType.TEMPERATURE ? '100%' : '33%',
                      padding: '.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <img
                        src={IconReportsGeneralInfo}
                        alt="info icon"
                        style={{
                          marginRight: '4px',
                        }}
                        width="24px"
                        height="24px"
                      />
                      <h4
                        className="comment-title"
                        style={{
                          margin: '4px 4px 8px',
                        }}
                      >
                          General Info
                      </h4>
                    </div>
                    <div
                      style={{
                        padding: '4px',
                      }}
                    >
                      {option.info}
                    </div>
                  </div>
                )}

                {/* Spike explanation on the bottom section of the report. */}
                {(option.type !== TelemetryDataType.TEMPERATURE && option.type !== TelemetryDataType.OUTDOOR_AQI &&
                  option.type !== TelemetryDataType.OUTDOOR_DEW_POINT &&
                  option.type !== TelemetryDataType.OUTDOOR_HUMIDITY &&
                  option.type !== TelemetryDataType.OUTDOOR_TEMPERATURE) && (
                  <div
                    style={{
                      width: option.hasComments ? '54%' : '33%',
                      padding: '.5rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: option.hasComments ? '' : '1rem',
                      }}
                    >
                      <img
                        src={IconReportsCauses}
                        alt="chart icon"
                        width="32px"
                        height="32px"
                      />
                      <h4
                        className="comment-title"
                        style={{
                          margin: '4px 4px 8px',
                        }}
                      >
                          What can cause spikes?
                      </h4>
                    </div>
                    <ul
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        padding: '4px',
                        margin: 0,
                        listStyle: 'none',
                      }}
                    >
                      {option.causes.length > 0 &&
                          option.causes.map((c, index) => {
                            return (
                              <li
                                className={option.hasComments ? 'cause-list' : ''}
                                key={'cause' + index}
                                style={{
                                  width: option.hasComments ? '49%' : '100%',
                                  margin: 0,
                                  listStyle: 'none',
                                  lineHeight: '18px',
                                  paddingRight: '4px',
                                }}
                              >
                                {c}
                              </li>
                            )
                          })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Footer section. */}
          <div>
            <p
              id="report-footer"
              style={{
                color: '#78909C',
                padding: '0 .75rem',
                overflowWrap: 'normal',
                width: '100%',
              }}
            >
              *HAVEN uses EPA or ASHRAE standards for the thresholds and IAQ levels described in this report. |
              **HAVEN is not liable for any equipment installed in your home by your HVAC service company.
            </p>
          </div>
        </Paper>
      )}
    </>
  )
}

export default ReportPreview
