import {
  Box,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme'
import Stack from 'ui/stack'
import Emphasis from './emphasis'
import { InputControlProps } from './input'
import Muted from './muted'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from './store'
import { displayUnit, humanizeMeasurement, max, min } from './util'

const recommendedValue = unit => {
  switch (unit) {
    case '°C': return 13
    case '°F': return 55
    case '%RH': return 60
    default: return ''
  }
}

const recommended = (unit) => ({
  value: recommendedValue(unit),
  label: `${recommendedValue(unit)}${unit}`,
})

const marks = ({ min, max, unit }) => [
  {
    value: min,
    label: `${min}${unit}`,
  },
  // {
  //   ...recommended(unit),
  // },
  {
    value: max,
    label: `${max}${unit}`,
  },
]

const StyledSlider = withStyles(theme => ({
  mark: {
  },
}))(Slider)

function Humidity({
  ruleId,
  inputId,
}: InputControlProps) {
  const [localValue, setLocalValue] = useState(1) // store it locally just so it updates visually on change without rendering the whole tree

  useInitTemporaryRule(ruleId)
  const input = useRulesStore(selectTempInput(ruleId, inputId))

  useEffect(() => {
    if (input?.start_set_point) setLocalValue(input.start_set_point)
  }, [input?.start_set_point])

  const handleChangeCommitted = (event, value) => {
    setTemporaryRuleInput(ruleId, input?.id, {
      start_set_point: value,
    })
  }

  if (!input) return null

  const onChange = (event, value) => {
    setLocalValue(value)
  }

  const {
    set_point_unit,
    measurement,
    rising,
    type,
  } = input

  const _min = min(measurement, set_point_unit, rising)
  const _max = max(measurement, set_point_unit, rising)

  const _marks = marks({
    min: min(measurement, set_point_unit, rising),
    max: max(measurement, set_point_unit, rising),
    unit: displayUnit(set_point_unit || input.measurement),
  })

  const triggerAction = input.rising ? 'rises above' : 'drops below'
  const humanizedMeasurement = humanizeMeasurement(type, measurement)

  return (
    <Box
      padding='16px'
      borderRadius='10px'
      border={`1px solid ${theme.palette.charcoalLightTint}`}
      borderColor='charcoalLightTint'
    >
      <Stack>
        <Typography
          variant='body1'
        >
          <Muted>
            <Emphasis>
              {humanizedMeasurement}
            </Emphasis>
            {` `}{triggerAction}{` `}
            <Emphasis>
              {localValue}{displayUnit(set_point_unit || input.measurement)}
            </Emphasis>
          </Muted>
        </Typography>
        <StyledSlider
          track={rising ? 'inverted' : 'normal'}
          value={localValue}
          min={_min}
          max={_max}
          marks={_marks}
          onChange={onChange}
          onChangeCommitted={handleChangeCommitted}
          aria-labelledby={input.type} />
      </Stack>
    </Box>
  )
}

export {
  min,
  max,
}

export default Humidity
