import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Mask } from '@reactour/mask'
import { useTour } from '@reactour/tour'
import { useRect } from '@reactour/utils'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { PRODUCT_TOUR_QUERY_KEYS } from 'features/product-tour/pro-portal-tour'
import { AccessControl } from 'features/protect-route'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { RootState } from 'state-mngt/store'
import { theme } from 'theme'
import { MemberRole } from 'utils/constants/member-enums'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'

interface Props {
  children?: React.ReactNode;
  renderer?: (params: { isCollapsed: boolean }) => React.ReactNode;
  menuItems: SideMenuItem[];
  height?: string;
  isCollapsable?: boolean;
  name: string;
}

const useStyles = makeStyles<Theme, Props & { isCollapsed: boolean }>((theme) => {
  return {
    content: ({ height = 'calc(100vh - 64px)' }) => ({
      height,
      backgroundColor: '#FFFFFF',
    }),
    drawer: ({ isCollapsed }) => {
      return {
        width: isCollapsed ? 56 : 172,
      }
    },
    drawerPaper: {
      position: 'relative',
      backgroundColor: theme.palette.grey[400],
      border: 'none',
      overflow: 'visible',
    },
    menuItem: {
      minHeight: 48,
    },
    menuActive: {
      '&::after': {
        content: '""',
        width: 5,
        height: '2em',
        borderRadius: '2.5px',
        position: 'absolute',
        right: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: theme.palette.primary.main,
      },
      '& $menuLabel': {
        fontWeight: 'bold',
      },
    },
    menuIcon: {
      color: 'white',
    },
    menuLabel: {
      color: 'white !important',
    },
    collapseButton: {
      borderRadius: '50%',
      bottom: 100,
      height: 24,
      minWidth: 24,
      padding: 0,
      position: 'absolute',
      right: -14,
      width: 24,
    },
  }
})

export interface SideMenuItem {
  to: string | {
    pathname: string
    search?: string
  }
  label: string
  icon: React.ReactElement
  roles?: MemberRole[]
}

const SideMenuLayout: React.FC<Props> = (props) => {
  const {
    isCollapsable = false,
    menuItems,
    children,
    renderer,
    name,
  } = props

  const location = useLocation()
  const { mixpanel } = useMixPanel()
  const [searchParams] = useDecodedSearchParams()
  const { isOpen } = useTour()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const isCollapsed = isCollapsable && !sidebarOpen

  const menuItemDOMRef = useRef(null)
  const sizes = useRect(menuItemDOMRef)
  const currentPageTour = searchParams.get(PRODUCT_TOUR_QUERY_KEYS.currentPageTour) || ''

  const classes = useStyles({
    ...props,
    isCollapsed,
  })

  const handleToggle = () => {
    if (mixpanel) {
      mixpanel.track('pp_customerDrillDownPage_sideBar_toggleIconButton')
    }
    setSidebarOpen(prev => !prev)
  }

  return (
    // Both side menu layout and the content section that this layout will render (renderer) by its side.
    <Box display="flex" className={classes.content}>

      {/*The expandable/collapsable side menu layout*/}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List component="nav">
          {menuItems.map(({
            to,
            icon,
            label,
            roles,
          }, index) => (
            <AccessControl key={index} roles={roles}>
              <>
                {/*
                Add a mask to highlight the side menu item if product tour is being displayed.
                We check if is NOT open because when the intro dialog of the product tour is being displayed the flag
                isOpen will be false. It's only true when the actual tour steps start, after continuing from intro dialog.
                */}
                {currentPageTour && !isOpen &&
                  <Mask
                    sizes={sizes}
                    styles={{
                      // The background/backdrop area that is NOT highlighted.
                      maskWrapper: (base: any) => ({
                        ...base,
                        color: theme.palette.grey[500],
                        // I am not sure why but with 20% here it matches the 50% from the main tour mask.
                        opacity: '20%',
                      }),
                      // The background/backdrop area that is highlighted.
                      maskArea: (base: any) => ({
                        ...base,
                        rx: 10,
                      }),
                    }}
                  />
                }
                <ListItem
                  // Only set as a ref if side menu is NOT collapsed, and it's the current one selected.
                  ref={
                    (!isCollapsed && (location.pathname === (typeof to === 'object' && to.pathname))) ?
                      menuItemDOMRef : null
                  }
                  button
                  component={Link}
                  to={to}
                  selected={location.pathname === (typeof to === 'object' ? to.pathname : to)}
                  classes={{
                    root: classes.menuItem,
                    selected: classes.menuActive,
                  }}
                >
                  <ListItemIcon
                    // Only set as a ref if side menu is collapsed, and it's the current one selected.
                    ref={
                      (isCollapsed && (location.pathname === (typeof to === 'object' && to.pathname))) ?
                        menuItemDOMRef : null
                    }
                    classes={{
                      root: classes.menuIcon,
                    }}
                    title={label}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    hidden={isCollapsed}
                    classes={{
                      primary: classes.menuLabel,
                    }}
                    primary={label}
                  />
                </ListItem>
              </>
            </AccessControl>
          ))}
        </List>
        {isCollapsable && (
          <Button
            id="side-menu-collapse-btn"
            color="primary"
            variant="contained"
            onClick={handleToggle}
            classes={{
              root: classes.collapseButton,
            }}
            style={{
              left: isCollapsed ? '42px' : '160px',
            }}
          >
            {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </Button>
        )}
      </Drawer>

      {/* Content rendered side by side with this side menu layout. */}
      <Box width={`calc(100% - ${isCollapsed ? 56 : 172}px)`} overflow="auto">
        {typeof renderer === 'function' ?
          renderer({
            isCollapsed,
          }) :
          children
        }
      </Box>
    </Box>
  )
}

export default SideMenuLayout
