import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { CustomerTablePageSizeOptions } from 'features/customers-list/customer-table-options'
import { CustomersTable, CustomerTableOrderColumns, DataType } from 'features/customers-list/customers-tables'
import DemoIntroDialog from 'features/demo-data/demo-intro-dialog'
import { CUSTOMERS_PAGE_TOUR_SELECTORS, WelcomeProPortalTour } from 'features/product-tour'
import { PRODUCT_TOUR_QUERY_KEYS } from 'features/product-tour/pro-portal-tour'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import { updateUser } from 'state-mngt/actions/user-actions'
import { Dwelling, DwellingsPaginated } from 'state-mngt/models/dwelling'
import { RequestsAdapter } from 'state-mngt/models/http'
import { selectCompanyId } from 'state-mngt/selectors/company-selectors'
import {
  selectIsDemoDataEnabled,
  selectIsLoadingUser,
  selectIsProPortalTourNotificationShown,
  selectUser,
} from 'state-mngt/selectors/user-selectors'
import companyService from 'state-mngt/services/company-service'
import dwellingService from 'state-mngt/services/dwelling-service'
import { SearchBar } from 'ui/search-bar'
import { useRequestsAdapter } from 'utils/hooks/http'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'

/**
 * Render customer list
 */
export const CustomersList = () => {
  const { mixpanel } = useMixPanel()
  const [searchParams] = useDecodedSearchParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUser)
  const isLoadingUser = useAppSelector(selectIsLoadingUser)
  const companyId = useAppSelector(selectCompanyId)
  const isDemoDataEnabled = useAppSelector(selectIsDemoDataEnabled)
  const wasProPortalTourNotificationShown = useAppSelector(selectIsProPortalTourNotificationShown)

  const [startProPortalTour, setStartProPortalTour] = useState(false)
  const [isLoadingDwellings, setIsLoadingDwellings] = useState(true)
  const [isPaginatingDwellings, setIsPaginatingDwellings] = useState(false)
  const [dwellingsPaginated, setDwellingsPaginated] = useState<DwellingsPaginated>({
    num_dwellings: 0,
    dwellings: [],
  })
  const [demoDwellings, setDemoDwellings] = useState<Dwelling[]>()
  const requestsAdapter = useRequestsAdapter()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [rowsPerPageLimit, setRowsPerPageLimit] = useState<number>(CustomerTablePageSizeOptions[0])
  // It may contain the character '~' in the front if the order is descending.
  const [orderBy, setOrderBy] = useState<string>('~' + CustomerTableOrderColumns.POOR_AVERAGE_TIME_PERCENTAGE)
  const [searchFor, setSearchFor] = useState<string>('')

  const currentPageTour = searchParams.get(PRODUCT_TOUR_QUERY_KEYS.currentPageTour) || ''

  const handleCustomerTableSearchEnter = (value: string) => {
    // Ignore when the search input is empty.
    if (value.length !== 0) {
      setSearchFor(value)
      setIsPaginatingDwellings(true)
    }
  }

  const handleCustomerTableSearchClear = (previousValue: string) => {
    setSearchFor('')
    setIsPaginatingDwellings(true)
  }

  const handleCustomerTableSearchInputChange = (previousValue: string, currentValue: string) => {
    if (currentValue.length === 0) {
      setSearchFor('')
      setIsPaginatingDwellings(true)
    }
  }

  const handleCustomerTableChangePage = (pageIndex: number) => {
    setPageIndex(pageIndex)
    setIsPaginatingDwellings(true)
  }

  const handleCustomerTableChangeRowsPerPageLimit = (pageSizeLimit: number) => {
    setPageIndex(0)
    setRowsPerPageLimit(pageSizeLimit)
    setIsPaginatingDwellings(true)
  }

  const handleCustomerTableOrderChange = (orderBy: string) => {
    setOrderBy(orderBy)
    setIsPaginatingDwellings(true)
  }

  const handleProPortalTourEnd = () => {
    setStartProPortalTour(false)
    navigate(ROUTE_PATHS.customers.root.absolute, {
      replace: true,
    })
  }

  // Start Pro Portal Tour if has never been started before.
  useEffect(() => {
    if (!wasProPortalTourNotificationShown && user && user.id !== undefined) {
      setStartProPortalTour(true)

      const newUser = {
        ...user,
        id: user.id,
        pro_portal_tour_notification_shown: true,
      }

      dispatch(updateUser(newUser.id, newUser, true))
    }
  }, [wasProPortalTourNotificationShown, user])

  useEffect(() => {
    if (currentPageTour) {
      setStartProPortalTour(true)
    }
  }, [currentPageTour])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track('pp_customerPage_open')
    }
  }, [mixpanel])

  // Load Demo Dwellings if demo data is enabled.
  useEffect(() => {
    if (isDemoDataEnabled && companyId && companyId !== -1 && requestsAdapter) {
      requestsAdapter
        .make(dwellingService.getDemoDwellings(companyId))
        .then((demoDwellings) => {
          if (demoDwellings) {
            setDemoDwellings(demoDwellings)
          }
        })
    }
  }, [isDemoDataEnabled, companyId, requestsAdapter])

  // Load Dwellings paginated data based on the current page for the customer table.
  useEffect(() => {

    const getDwellingsPaginated = async (companyId: number, requestAdapter: RequestsAdapter) => {
      requestAdapter.abortAll()

      requestsAdapter
        .make(companyService.getDwellingsPaginated(companyId, {
          limit: rowsPerPageLimit,
          offset: pageIndex * rowsPerPageLimit,
          order: orderBy,
          ...(searchFor.length > 0 && {
            search: searchFor,
          }),
        }))
        .then(result => {
          setIsLoadingDwellings(false)
          setIsPaginatingDwellings(false)

          if (result) {
            setDwellingsPaginated(result)

            if (result.num_dwellings === 0) {
              // Do something if no dwellings???
            }
          }
        })
        .catch(error => {
          setIsLoadingDwellings(false)
          setIsPaginatingDwellings(false)
        })
    }

    if (companyId && companyId > 0 && requestsAdapter && (isLoadingDwellings || isPaginatingDwellings))
      getDwellingsPaginated(companyId, requestsAdapter)

  }, [companyId, requestsAdapter, isPaginatingDwellings])

  if (isLoadingUser) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Box
        minHeight='calc(100vh - 64px)' // header height
        bgcolor='#EAF1F5'
      >
        {/* Display loading spinner. */}
        {isLoadingDwellings && (dwellingsPaginated.num_dwellings === 0) &&
          <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
            <CircularProgress />
          </Box>
        }
        {/*
        Display search bar, customer table and demo table if there are any existent dwellings.
        The table may not be 100% opaque and available to click if the dwellings are loading, but it will still be shown.
        */}
        {!isLoadingDwellings && (dwellingsPaginated.num_dwellings > 0) &&
          <Box>
            <Box
              display="block"
              style={{
                padding: '24px 24px 16px 24px',
                width: '100%',
              }}
            >
              <SearchBar
                placeholder="Search for customer name, dwelling name or address"
                onSearchEnter={handleCustomerTableSearchEnter}
                onSearchClear={handleCustomerTableSearchClear}
                onSearchInputChange={handleCustomerTableSearchInputChange}
              />
            </Box>
            {demoDwellings &&
              <Box id={CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE}>
                <CustomersTable dwellings={demoDwellings} type={DataType.Demo} />
              </Box>
            }
            <CustomersTable
              isLoading={isPaginatingDwellings}
              num_dwellings={dwellingsPaginated.num_dwellings}
              dwellings={dwellingsPaginated.dwellings}
              type={DataType.Regular}
              callbackOnChangePage={handleCustomerTableChangePage}
              callbackOnChangeRowsPerPage={handleCustomerTableChangeRowsPerPageLimit}
              callbackOnChangeOrder={handleCustomerTableOrderChange}
              pageIndex={pageIndex}
              rowsPerPageLimit={rowsPerPageLimit}
              // It may contain the character '~' in the front if the order is descending but we should not pass it along.
              columnToOrder={(orderBy.replace('~', '') as CustomerTableOrderColumns)}
              columnOrderDirection={orderBy.startsWith('~') ? 'desc' : 'asc'}
            />
          </Box>
        }

        {/* Display empty customer table and demo table if enabled. */}
        {!isLoadingDwellings && (dwellingsPaginated.num_dwellings === 0) &&
          <Box mb={8}>
            {demoDwellings &&
              <Box id={CUSTOMERS_PAGE_TOUR_SELECTORS.DEMO_ACCOUNTS_TABLE}>
                <CustomersTable dwellings={demoDwellings} type={DataType.Demo} />
              </Box>
            }
            <CustomersTable dwellings={[]} type={DataType.Regular} />
          </Box>
        }
      </Box>

      {!isLoadingDwellings &&
        <>
          <DemoIntroDialog />
          <WelcomeProPortalTour startTour={startProPortalTour} onEndTour={handleProPortalTourEnd} />
        </>
      }
    </Box>
  )
}
