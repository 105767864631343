import React, { useEffect } from 'react'
import { CustomerDrillDown } from 'routes/customer-drill-down'
import { Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTE_PATHS } from 'routes/routes'
import { cleanCustomers } from 'state-mngt/actions/customer-actions'
import { setCompletedDemos } from 'state-mngt/actions/user-actions'
import { getUserId } from 'state-mngt/selectors/user-selectors'
import { CustomersList } from 'features/customers-list'
import { usePageTitle } from 'utils/hooks/router'
import userService from 'state-mngt/services/user-service'


export const Customers = () => {
  usePageTitle('All Customers')
  const dispatch = useDispatch()
  const userId = useSelector(getUserId)

  useEffect(() => {
    if (userId !== undefined) {
      userService.getUserCompletedDemos(userId)
        .then(({ demos }) => {
          dispatch(setCompletedDemos(demos))
        })
        .catch(() => {
          dispatch(setCompletedDemos([]))
        })
    }
  }, [setCompletedDemos, userId])

  useEffect(() => {
    return () => {
      dispatch(cleanCustomers())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* Descendant Routes, MUST USE RELATIVE PATHS TO MATCH THE ROUTES. */}
      <Routes>
        {/* Index Route - A child route with no path that renders in the parent's outlet at the parent's URL. */}
        <Route
          index
          element={<CustomersList />} />
        {/* Path have trailing '*' wildcard because its rendering descendant (sub) routes. */}
        <Route
          path={ROUTE_PATHS.customers.details.root.relative + ROUTE_PATHS.trailingDescendantRoutes}
          element={<CustomerDrillDown />}
        />
      </Routes>
    </>
  )
}
