import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { theme } from 'theme'
import { InputControlProps } from './input'
import Emphasis from './emphasis'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from './store'
import {
  displayRange,
  humanizeMeasurement,
  start_set_point,
  stop_set_point,
  useSkipFirstRender,
  findUnit,
  parseUnit,
} from './util'
import Stack from 'ui/stack'
import Muted from './muted'

const useClasses = makeStyles(theme => ({
  textField: {
    marginLeft: '32px',
    maxWidth: '164px',
  },
}))

const stopPoint = (startPoint: number) => {
  return startPoint * 0.9
}

const startPointToFactor = measurement => unit => (startPoint: number) => {
  if (!measurement) return ''
  if (!unit) return ''
  const startPoints = start_set_point[measurement][unit]
  return Object.keys(startPoints).find(factor => startPoints[factor] === startPoint) || 'custom'
}

function AirFlow({
  ruleId,
  inputId,
}: InputControlProps) {
  const [customSetPoint, setCustomSetPoint] = useState(0) // store it locally just so it updates visually on change without rendering the whole tree
  const [selectedFactor, setSelectedFactor] = useState('fair')
  const [error, setError] = useState('')

  useInitTemporaryRule(ruleId)

  const input = useRulesStore(selectTempInput(ruleId, inputId))
  const classes = useClasses()
  const unit = findUnit(input?.measurement)

  useSkipFirstRender(() => {
    if (!customSetPoint) return
    if (input?.start_set_point) setTemporaryRuleInput(ruleId, input.id, {
      start_set_point: customSetPoint,
      stop_set_point: stopPoint(customSetPoint),
    })
  }, [customSetPoint])

  useEffect(() => {
    if (!input) return
    if (selectedFactor === 'custom') setCustomSetPoint(input.start_set_point)
  }, [selectedFactor, input?.start_set_point])

  useEffect(() => {
    if (!input) return
    if (selectedFactor === 'custom') return
    setSelectedFactor(
      startPointToFactor(input?.measurement)(findUnit(input?.measurement))(input?.start_set_point))
  }, [input?.measurement, input?.start_set_point])

  const handleChange = (event, value) => {
    setSelectedFactor(value)
    if (value === 'custom') return
    return setTemporaryRuleInput(ruleId, input?.id, {
      start_set_point: start_set_point[input?.measurement][unit][value],
      stop_set_point: stop_set_point[input?.measurement][unit][value],
    })
  }

  const onChangeCustomSetPoint = (event) => {
    const { value } = event.target
    let _value = parseInt(value || 0)
    if (_value > 999) _value = 999
    setCustomSetPoint(_value)
  }

  if (!input) return null

  const triggerAction = () => {
    if (selectedFactor === 'custom') {
      return input.rising ?
        'are above' :
        'are below'
    }
    return input.rising ?
      'are in or above' :
      'are in or below'
  }

  const humanizedMeasurement = humanizeMeasurement(input.type, input.measurement)
  const parsedUnit = parseUnit(unit)

  const humanizedFactor = selectedFactor === 'custom' ?
    (<>
      <Emphasis>{customSetPoint}</Emphasis> {parsedUnit}
    </>) :
    (<>
      <Emphasis>{displayRange[input.measurement][unit][selectedFactor]} </Emphasis>
      <Muted>({selectedFactor})</Muted>
    </>)

  return (
    <Box
      padding='16px'
      borderRadius='10px'
      border={`1px solid ${theme.palette.charcoalLightTint}`}
      borderColor='charcoalLightTint'
    >
      <Stack>
        <Typography
          variant='body1'
        >
          <Muted>
            <Emphasis>
              {humanizedMeasurement} levels
            </Emphasis>
            {` `}{triggerAction()}{` `}
            {humanizedFactor}
          </Muted>
        </Typography>
        <RadioGroup
          value={selectedFactor}
          aria-label={input.measurement}
          name={input.measurement}
          onChange={handleChange}>
          <FormControlLabel value='fair' control={<Radio />} label={
            <Typography>
              {displayRange[input.measurement][unit]['fair']}
              <Muted> (fair)</Muted>
            </Typography>
          } />
          <FormControlLabel value='poor' control={<Radio />} label={
            <Typography>
              {displayRange[input.measurement][unit]['poor']}
              <Muted> (poor)</Muted>
            </Typography>
          } />
          {/* <FormControlLabel value='custom' control={<Radio />} label={
                        <Typography>
                            Custom
                        </Typography>
                    } /> */}
          {(selectedFactor === 'custom') ? (
            <TextField
              autoFocus
              className={classes.textField}
              value={customSetPoint}
              onChange={onChangeCustomSetPoint}
            />
          ) : null}
        </RadioGroup>
      </Stack>
    </Box>
  )
}

export {
  startPointToFactor,
}

export default memo(AirFlow)
