import {
  Box, Grid, Link, Typography, 
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { createCompany, createCompanyProfile } from 'state-mngt/actions/company-actions'
import { getCompanyRole, updateUser } from 'state-mngt/actions/user-actions'
import { selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import { selectUserRole, selectUser } from 'state-mngt/selectors/user-selectors'
import { ErrorAutoHideWithDismissAction } from 'ui/custom-snackbar-provider'
import { initCompanyProfile } from 'utils/constants/company-lookup-options'
import { ROUTE_PATHS } from 'routes/routes'
import { CompanyProfile } from 'state-mngt/models/company-profile'
import { OnboardingProfile, OnboardingValidation } from 'state-mngt/models/onboarding-profile'
import { NewServiceCompany } from 'state-mngt/models/serviceCompany'
import { Country } from 'utils/constants/province-lookup-options'
import OnboardingLinearProgress from 'ui/liner-progress'
import {
  FormOne, FormTwo, FormThree, FormFour, FormSeven, FormFive, FormSix,
} from 'features/onboarding/forms'
import 'features/onboarding/onboarding.scss'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import config from 'config'
import { User } from 'state-mngt/models/user'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxTypes'
import { usePageTitle } from 'utils/hooks/router'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const formatPhone = (phone: string) => {
  return `${phone.slice(0,3)}-${phone.slice(3,6)}-${phone.slice(6)}`
}

/**
 * Render onboarding is user doesn't have a profile and is not a part of any service company
 * step1 (Name): 15%
 * step2 (Company): 30%
 * step3 (Address): 50%
 * step4 (Services): 65%
 * step5 (Focus): 80%
 * step6 (Distribution): 90%
 * step7 (Feedback): 100% complete
 */
export const Onboarding = () => {

  const dispatch = useAppDispatch()
  const { mixpanel } = useMixPanel()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const user = useAppSelector(selectUser)
  const company = useAppSelector(selectCompanyDetails)
  const role = useAppSelector(selectUserRole)

  const [profile, setProfile] = useState<OnboardingProfile>()
  const [progress, setProgress] = useState<number>(0)
  const [formValidation, setFormValidation] = useState<OnboardingValidation>({
    isFormOneValid: true,
    isFormTwoValid: true,
    isFormThreeValid: true,
    isFormFourValid: true,
    isFormFiveValid: true,
    isFormSixValid: true,
    isFormSevenValid: true,
  })
  const [isParentFormValid, setIsParentFormValid] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [loadingMsgOne, setLoadingMsgOne] = useState<boolean>(false)
  const [loadingMsgTwo, setLoadingMsgTwo] = useState<boolean>(false)

  const { logout } = useAuth0()

  const cardRef = React.useRef<HTMLDivElement>(null)

  usePageTitle('Onboarding')

  useEffect(() => {
    if (role && role.service_company_id !== -1) {
      navigate(ROUTE_PATHS.customers.root.absolute, {
        replace: true,
      })
    }
  }, [role])

  useEffect(() => {
    if (user) {
      setProfile({
        ...initCompanyProfile, // constant
        admin_id: user.id!,
        user_email: user.email,
        first_name: user.first_name ? user.first_name : '',
        last_name: user.last_name ? user.last_name : '',
      })
    }
  }, [user])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.init(config.mixpanelId, {
      }, '')
      mixpanel.track('pp_onBoardingPage_open') // event tracking
    }
  }, [mixpanel])

  /**
     * callback fn for progress bar value change
     * @param value - number
     */
  const handleProgressChange = (value: number) => {
    setProgress(value)
    window.requestAnimationFrame(scrollToNext)
  }

  /**
     * callback fn for profile change (all form value changes)
     * @param profile - OnboardingProfile
     */
  const handleProfileChange = (profile: OnboardingProfile) => {
    setProfile(profile)
  }

  /**
     * callback fn for save button on the last form
     * @param profile - OnboardingProfile
     */
  const handleFormSave = async (profile: OnboardingProfile) => {
    const {
      admin_id, company_name, email, phone, website, street_2, street_1,
      city, province, postal_code, country, services, focuses, distribution,
      employeeCount, hearAboutUs, hasSalesTeam, wantDemo, wantBeta, comments, first_name, last_name,
    } = profile

    const _phone = formatPhone(phone)

    const companyDetails: NewServiceCompany = {
      admin_id,
      name: company_name,
      email,
      phone: _phone,
      website,
      status: 0,
      street_1,
      street_2,
      city,
      province,
      postal_code,
      country: country as Country,
    }

    const newCompanyProfile: CompanyProfile = {
      employee_count: employeeCount,
      have_sales_team: hasSalesTeam,
      want_demo: wantDemo,
      want_beta: wantBeta,
      comment: comments,
      services,
      focuses,
      distribution,
      hear_about_us: hearAboutUs,
    }

    setIsSaving(true)
    setLoadingMsgOne(true)
    setLoadingMsgTwo(false)

    saveCompany(companyDetails, newCompanyProfile)

    //if no change in names, no need to save
    if (user && (user.last_name !== last_name || user.first_name !== first_name)) {
      saveUserNames(user, first_name, last_name)
    }
  }

  /**
     * API calls to create company and setup company profile
     * @param company - NewServiceCompany
     * @param companyProfile - CompanyProfile
     */
  const saveCompany = async (company: NewServiceCompany, companyProfile: CompanyProfile) => {
    const newCompany = await dispatch(createCompany(company))

    if (newCompany) {
      setLoadingMsgOne(false)
      setLoadingMsgTwo(true)
      const profile = await dispatch(createCompanyProfile(companyProfile, newCompany.id))

      if (profile) {
        try {
          await dispatch(getCompanyRole())
          navigate(ROUTE_PATHS.customers.root.absolute)
        } catch {
          setIsSaving(false)
        }
      } else {
        enqueueSnackbar('Failed to create company profile, please try again later', ErrorAutoHideWithDismissAction())
        setIsSaving(false)
      }
    } else {
      enqueueSnackbar('Failed to create company, please try again later', ErrorAutoHideWithDismissAction())
      setIsSaving(false)
    }

  }

  /**
     * Update existing user's first and last name when provided
     * @param existingUser - User
     * @param first_name - string
     * @param last_name - string
     */
  const saveUserNames = async (existingUser: User, first_name: string, last_name: string) => {
    const updatedUser: User = {
      ...existingUser!,
      first_name,
      last_name,
    }

    if (updatedUser.id) {
      await dispatch(updateUser(updatedUser.id, updatedUser, true))
    }
  }

  /**
     * callback fn for overall form validation status change
     * @param formNum - number
     * @param isValid - boolean
     */
  const validateForm = (formNum: number, isValid: boolean): void => {
    switch (formNum) {
      case 1:
        formValidation.isFormOneValid = isValid
        break
      case 2:
        formValidation.isFormTwoValid = isValid
        break
      case 3:
        formValidation.isFormThreeValid = isValid
        break
      case 4:
        formValidation.isFormFourValid = isValid
        break
      case 5:
        formValidation.isFormFiveValid = isValid
        break
      case 6:
        formValidation.isFormSixValid = isValid
        break
      case 7:
        formValidation.isFormSevenValid = isValid
        break
      default:
        formValidation.isFormOneValid = isValid
        break
    }

    setFormValidation(formValidation)

    if (
      formValidation.isFormOneValid &&
      formValidation.isFormTwoValid &&
      formValidation.isFormThreeValid &&
      formValidation.isFormFourValid &&
      formValidation.isFormFiveValid &&
      formValidation.isFormSixValid &&
      formValidation.isFormSevenValid
    ) {
      setIsParentFormValid(true)
    } else {
      setIsParentFormValid(false)
    }
  }

  /**
     * scroll to next card after clicking 'next' btn
     */
  const scrollToNext = () => {
    // scroll to next card
    if (cardRef && cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  return (
    <>

      <Link
        onClick={() => logout({
          returnTo: window.location.origin,
        })}
        style={{
          position: 'absolute',
          right: 32,
          top: 16,
          cursor: 'pointer',
          color: 'white',
        }}>
        Logout
      </Link>
      {!isSaving && progress !== 100 && !company &&
        <>
          <Box id="onboarding-container" display="flex" flexDirection="column">
            <Grid container >
              <Grid item sm={2} />
              <Grid item sm={10}>
                <Box mt="30vh" mb={4}>
                  <Typography variant="h1" gutterBottom>Welcome to the HAVEN IAQ family</Typography>
                  <Typography variant="h5" gutterBottom>
                    Tell us a bit about yourself.<br />
                    This should only take 2 minutes.
                  </Typography>
                </Box>
                {profile && (
                  <FormOne
                    profile={profile}
                    onProfileChange={handleProfileChange}
                    onNextValueChange={handleProgressChange}
                    onIsValidValueChange={validateForm}
                    isParentFormValid={isParentFormValid}
                  />
                )}
                {profile && progress >= 15 && (
                  <div ref={progress >= 15 && progress < 30 ? cardRef : null}>
                    <FormTwo
                      profile={profile}
                      onProfileChange={handleProfileChange}
                      onNextValueChange={handleProgressChange}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {profile && progress >= 30 && (
                  <div ref={progress >= 30 && progress < 50 ? cardRef : null}>
                    <FormThree
                      profile={profile}
                      onProfileChange={handleProfileChange}
                      onNextValueChange={handleProgressChange}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {profile && progress >= 50 && (
                  <div ref={progress >= 50 && progress < 65 ? cardRef : null}>
                    <Box>
                      <Typography variant="h1">Just a few more questions</Typography>
                      <Typography variant="h5" gutterBottom>Tell us a bit about your company.</Typography>
                    </Box>
                    <FormFour
                      profile={profile}
                      onProfileChange={handleProfileChange}
                      onNextValueChange={handleProgressChange}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {profile && progress >= 65 && (
                  <div ref={progress >= 65 && progress < 80 ? cardRef : null}>
                    <FormFive
                      profile={profile}
                      onProfileChange={handleProfileChange}
                      onNextValueChange={handleProgressChange}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {profile && progress >= 80 && (
                  <div ref={progress >= 80 && progress < 90 ? cardRef : null}>
                    <FormSix
                      profile={profile}
                      onProfileChange={handleProfileChange}
                      onNextValueChange={handleProgressChange}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {profile && progress >= 90 && (
                  <div ref={progress >= 90 ? cardRef : null}>
                    <Box>
                      <Typography variant="h1">Aaaand Lastly</Typography>
                      <Typography variant="h5" gutterBottom>How did you hear about HAVEN IAQ</Typography>
                    </Box>
                    <FormSeven
                      profile={profile}
                      onFormSave={handleFormSave}
                      onIsValidValueChange={validateForm}
                      isParentFormValid={isParentFormValid}
                    />
                  </div>
                )}
                {/* this box is used as a padding at the bottom so that the cards are kept closer to the middle of the sreen */}
                <Box height={150}>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              backgroundColor: '#EEE',
            }}
            width="100%"
            padding="2rem 1rem 1rem"
          >
            <OnboardingLinearProgress
              value={progress}
              style={{
                width: '50%',
                margin: '0 auto',
              }}
            />
          </Box>
        </>
      }
      {isSaving &&
        <Box id="onboarding-container" className="full-height" display="flex" flexDirection="column">
          <Grid container>
            <Grid item sm={2} />
            <Grid item sm={10}>
              <Box mt="30vh">
                <Typography
                  variant="h1"
                  style={{
                    width: '60%',
                  }}
                >
                  Thanks for helping us get to know you and your company a bit better.
                </Typography>
                {loadingMsgOne && (
                  <Typography variant="h5" gutterBottom>Loading your new service company profile...</Typography>
                )}
                {loadingMsgTwo && (
                  <Typography variant="h5" gutterBottom>Loading Technician permissions...</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}
