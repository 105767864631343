import { useEffect } from "react"
import { DwellingAutomationsStore, useDwellingAutomationsStore } from "./store"
import httpService from "state-mngt/services/data/http-service"

type Selector = (id?: number) => (store: DwellingAutomationsStore | null) => number[] | null

const select: Selector = id =>
  store => id ? (store?.[id] || null) : null

const useAutomationIds = (dwellingId?: number) => {
  const ids = useDwellingAutomationsStore(select(dwellingId))

  useEffect(() => {
    if (!dwellingId) return

    const get = async () => {
      try {
        const { automations } = await httpService.get<{ automations: { id: string }[] }>(`/automations?dwelling_id=${dwellingId}`)
        useDwellingAutomationsStore.setState(prev => ({
          ...prev,
          [dwellingId]: automations.map(x => parseInt(x.id)),
        }))
      } catch (e) {
        console.error(e)
      }
    }

    if (!ids?.length) get()
  }, [dwellingId])

  return ids
}

export default useAutomationIds
