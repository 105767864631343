// import { Dwelling } from "state-mngt/models/dwelling"
import { Device, Equipment } from "types"
import { create } from "zustand"

interface EcosenseDevice {
  serial_number: string
  name: string
  zone: string
}

export interface Dwelling {
  id: number
  name: string
  create_timestamp: string
  preferred_service_company_id: number
  street_1: string
  street_2: string
  city: string
  province: string
  postal_code: string
  country: string
  time_zone: string
  air_quality_factors: any[]
}

export interface Permission {
  user_id: number | null
  dwelling_id: number | null
  permission: number | null
}

interface DwellingState {
  dwelling: Dwelling
  contents: {
    devices: Device[]
    ecosense_devices: EcosenseDevice[]
    equipment: Equipment[]
  },
  permissions: Permission[]
}

interface DwellingStore { [key: number]: DwellingState }

const useDwellingStore = create<DwellingStore>(set => ({
  dwelling: {
  },
  contents: {
    devices: [],
    ecosense_devices: [],
    equipment: [],
  },
  permissions: [],
}))

const selectPermissions = (id: number) => (store: DwellingStore) => store[id]?.permissions || []
const selectContents = (id: number) => (store: DwellingStore) => id ? store[id]?.contents : null
const selectDetails = (id?: number) => (store: DwellingStore) => id ? store[id]?.dwelling : null

export {
  selectPermissions,
  selectContents,
  selectDetails,
}

export default useDwellingStore
