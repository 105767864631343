import { formatForStore, useAutomationsStore, useRulesStore } from "features/customer-drill-down/equipment/store"
import { useEffect } from "react"
import { automationsToRules } from "features/customer-drill-down/equipment/useGetData"

/**
 * when updating the rules store
 * don't touch the temp rules as
 * they might have unsaved changes
 */
const keepTheTemps = rules => store => ({
  ...Object.keys(store)
    .filter(x => x.includes('_temp_'))
    .reduce((prev, curr) => ({
      ...prev,
      [curr]: store[curr],
    }), {
    }),
  ...rules,
})

const useSyncAutomationWithRules = () => {
  const automations = useAutomationsStore()
  const setRules = rules => useRulesStore.setState(keepTheTemps(rules), true)

  useEffect(() => {
    if (!automations) return
    const rules = automationsToRules(Object.values(automations))
    setRules(formatForStore(rules))
  }, [JSON.stringify(automations)])
}

export default useSyncAutomationWithRules
