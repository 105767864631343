import React, { Fragment, useState } from 'react'
import {
    persistTemporaryRule,
    resetTemporaryRule,
    selectInput,
    selectScheduleRules,
    selectTempInput,
    setTemporaryRuleInput,
    useEquipmentStore,
    useInitTemporaryRule,
    useRulesStore,
} from "./store"
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Typography,
    capitalize,
} from "@material-ui/core"
import Muted from "./muted"
import Stack from 'ui/stack'
import useClasses from './useClasses'
import AutomationRuleCard from './automation-rule-card'
import RuleTitle from './ruleTitle'
import { useSearchParams } from 'react-router-dom'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import InnerCard from './inner-card'
import Dot from './dot'
import { ScheduleRule } from 'types'
import Footer from './footer'
import useSaveAutomation from './useSaveAutomation'
import useIsTouched from './useIsTouched'
import { useResetAfterTimeout } from './useRuleState'

const Toggle = ({ ruleId, inputId, type }) => {
    const touched = useIsTouched(ruleId, [
        `${type}_awake_interval`,
        `${type}_sleep_interval`,
    ])

    const original = useRulesStore(selectInput(ruleId, inputId))
    const input = useRulesStore(selectTempInput(ruleId, inputId))

    const handleToggle = (e, v) => {
        if (!original) return
        if (v) {
            if (touched) {
                return setTemporaryRuleInput(ruleId, inputId, {
                    [`${type}_awake_interval`]: original[`${type}_awake_interval`],
                    [`${type}_sleep_interval`]: original[`${type}_sleep_interval`],
                })

            }
            return setTemporaryRuleInput(ruleId, inputId, {
                [`${type}_awake_interval`]: 'on_10_off_20',
                [`${type}_sleep_interval`]: 'on_10_off_20',
            })
        }

        setTemporaryRuleInput(ruleId, inputId, {
            [`${type}_awake_interval`]: 'always_off',
            [`${type}_sleep_interval`]: 'always_off',
        })
    }

    const off = input?.[`${type}_awake_interval`] === 'always_off' &&
        input?.[`${type}_sleep_interval`] == 'always_off'

    return (
        <Stack direction='row' spacing={1}>
            {off ? (<Typography variant='caption'><Muted>Disabled</Muted></Typography>) : null}
            <Switch checked={!off} onChange={handleToggle} />
        </Stack>
    )
}

const formatData = ({ key, value }) => {
    if (key.includes('time')) return {
        [key]: `${value}:00`,
    }
    return { [key]: value }
}

const Schedule = ({ rule, type }: { rule: ScheduleRule, type: 'weekday' | 'weekend' }) => {
    const equipment = useEquipmentStore()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const _equipment = rule.outputs.map(x => equipment[x.equipment_id]).filter(x => x)
    const input = rule.inputs.find(x => x.type === 'schedule')
    const tempInput = useRulesStore(selectTempInput(rule.id, input?.id))

    useResetAfterTimeout([success, error], [setSuccess, setError])
    useInitTemporaryRule(rule.id)

    const save = useSaveAutomation(rule.id)
    const touched = useIsTouched(rule.id, [
        `${type}_awake_interval`,
        `${type}_sleep_interval`,
        `${type}_start_time`,
        `${type}_stop_time`,
    ])

    const handleChange = (e) => {
        if (!input) return
        if (!e.target.name || !e.target.value) return console.error('[handleChange] missing values')
        const data = formatData({ key: e.target.name, value: e.target.value })
        setTemporaryRuleInput(rule.id, input.id, data)
    }

    const handleCancel = () => {
        resetTemporaryRule(rule.id)
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            await save()
            persistTemporaryRule(rule.id)
            setSuccess('Saved ✓')
        } catch (e) {
            console.error(e)
            setError('Couldn\'t save')
        }
        setLoading(false)
    }

    if (!input) return null
    if (!tempInput) return null

    const off = tempInput[`${type}_awake_interval`] === 'always_off' &&
        tempInput[`${type}_sleep_interval`] === 'always_off'

    return (
        <AutomationRuleCard>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Stack direction='row' align='flex-end'>
                        <RuleTitle>
                            {capitalize(type)}
                        </RuleTitle>
                        <Toggle
                            ruleId={rule.id}
                            inputId={input.id}
                            type={type}
                        />
                    </Stack>
                    <InnerCard>
                        <Stack divider>
                            {_equipment.map((x, i) => (
                                <Stack
                                    key={x.id}
                                    direction='row'>
                                    <Typography variant='body1'>
                                        {x?.name}
                                    </Typography>
                                    <Dot active={rule.outputs[i].currently_triggered} />
                                </Stack>
                            ))}
                        </Stack>
                    </InnerCard>
                </Stack>
                <Stack spacing={2}>
                    <RuleTitle>
                        Awake
                    </RuleTitle>
                    <FormControl
                        size='small'
                        variant='outlined'
                        style={{ width: '100%' }}
                    >
                        <InputLabel
                            htmlFor={`${type}_awake_interval`}
                        >
                            Interval
                        </InputLabel>
                        <Select
                            id={`${type}_awake_interval`}
                            name={`${type}_awake_interval`}
                            labelId={`${type}_awake_interval`}
                            label='Interval'
                            value={tempInput[`${type}_awake_interval`]}
                            disabled={off || loading}
                            onChange={handleChange}
                        >
                            <MenuItem
                                value='always_on'
                            >
                                Always on
                            </MenuItem>
                            <MenuItem
                                value='always_off'
                            >
                                Always off
                            </MenuItem>
                            <MenuItem
                                value='on_5_off_55'
                            >
                                On 5m off 55m
                            </MenuItem>
                            <MenuItem
                                value='on_5_off_25'
                            >
                                On 5m off 25m
                            </MenuItem>
                            <MenuItem
                                value='on_10_off_20'
                            >
                                On 10m off 20m
                            </MenuItem>
                            <MenuItem
                                value='on_20_off_20'
                            >
                                On 20m off 20m
                            </MenuItem>
                            <MenuItem
                                value='on_20_off_10'
                            >
                                On 20m off 10m
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack
                        direction='row'
                        wrap='nowrap'
                        itemStyles={[{
                            flex: 1,
                        }]}
                    >
                        <FormControl
                            size='small'
                            variant='outlined'
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                htmlFor={`${type}_start_time`}
                            >
                                Start
                            </InputLabel>
                            <OutlinedInput
                                id={`${type}_start_time`}
                                name={`${type}_start_time`}
                                label='Interval'
                                type='time'
                                disabled={off || loading}
                                value={tempInput[`${type}_start_time`]}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl
                            size='small'
                            variant='outlined'
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                htmlFor={`${type}_stop_time`}
                            >
                                End
                            </InputLabel>
                            <OutlinedInput
                                id={`${type}_stop_time`}
                                name={`${type}_stop_time`}
                                label='Interval'
                                type='time'
                                disabled={off || loading}
                                value={tempInput[`${type}_stop_time`]}
                                onChange={handleChange}
                                style={{ cursor: 'pointer' }}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                <Stack spacing={2}>
                    <RuleTitle>
                        Asleep
                    </RuleTitle>
                    <FormControl
                        size='small'
                        variant='outlined'
                        style={{ width: '100%' }}
                    >
                        <InputLabel
                            htmlFor={`${type}_sleep_interval`}
                        >
                            Interval
                        </InputLabel>
                        <Select
                            id={`${type}_sleep_interval`}
                            name={`${type}_sleep_interval`}
                            labelId={`${type}_sleep_interval`}
                            label='Interval'
                            disabled={off || loading}
                            value={tempInput[`${type}_sleep_interval`]}
                            onChange={handleChange}
                        >
                            <MenuItem
                                value='always_on'
                            >
                                Always on
                            </MenuItem>
                            <MenuItem
                                value='always_off'
                            >
                                Always off
                            </MenuItem>
                            <MenuItem
                                value='on_5_off_55'
                            >
                                On 5m off 55m
                            </MenuItem>
                            <MenuItem
                                value='on_5_off_25'
                            >
                                On 5m off 25m
                            </MenuItem>
                            <MenuItem
                                value='on_10_off_20'
                            >
                                On 10m off 20m
                            </MenuItem>
                            <MenuItem
                                value='on_20_off_20'
                            >
                                On 20m off 20m
                            </MenuItem>
                            <MenuItem
                                value='on_20_off_10'
                            >
                                On 20m off 10m
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack
                        direction='row'
                        wrap='nowrap'
                        itemStyles={[{
                            flex: 1,
                        }]}
                    >
                        <FormControl
                            size='small'
                            variant='outlined'
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                htmlFor={`${type}_stop_time`}
                            >
                                Start
                            </InputLabel>
                            <OutlinedInput
                                id={`${type}_stop_time`}
                                name={`${type}_stop_time`}
                                label='Interval'
                                type='time'
                                disabled
                                value={tempInput[`${type}_stop_time`]}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl
                            size='small'
                            variant='outlined'
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                htmlFor={`${type}_start_time`}
                            >
                                End
                            </InputLabel>
                            <OutlinedInput
                                id={`${type}_start_time`}
                                name={`${type}_start_time`}
                                label='Interval'
                                type='time'
                                disabled
                                value={tempInput[`${type}_start_time`]}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
                <Footer
                    success={success}
                    error={error}
                    loading={loading}
                    touched={touched}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                />
            </Stack>
        </AutomationRuleCard>
    )
}

const filterByZone = (rule) => {
    const [searchParams] = useSearchParams()
    const selectedZone = searchParams.get(HISTORY_QUERY_KEYS.zone) || '';
    return rule.zone?.toLowerCase() === selectedZone.toLowerCase()
}

const Schedules = ({ dwellingId }: { dwellingId: number }) => {
    const rules = useRulesStore(selectScheduleRules(dwellingId))
    const _rules = rules.filter(filterByZone)
    const classes = useClasses()

    if (!Boolean(_rules?.length)) return (
        <Box className={classes.root}>
            <Muted>
                No schedules
            </Muted>
        </Box>
    )

    return (
        <Grid
            className={classes.root}
            spacing={4}
            container>
            {
                _rules.map(rule => (
                    <Fragment key={rule.id}>
                        <Grid
                            xs={12}
                            md={6}
                            lg={4}
                            item>
                            <Schedule
                                key={rule.trigger?.id}
                                // @ts-ignore bad to ignore but this is where inputs having 2 distinct interfaces breaks things
                                rule={rule}
                                type='weekday'
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={6}
                            lg={4}
                            item>
                            <Schedule
                                key={rule.trigger?.id}
                                // @ts-ignore bad to ignore but this is where inputs having 2 distinct interfaces breaks things
                                rule={rule}
                                type='weekend'
                            />
                        </Grid>
                    </Fragment>
                ))
            }
        </Grid>
    )
}

export default Schedules
