import React, { useEffect } from 'react'
import {
  List, ListItem, ListItemText, Typography, Box, CircularProgress,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { customerDetailsTooltip } from 'utils/constants/tooltip-content'
import { usePageTitle } from 'utils/hooks/router'
import useAdmin from 'utils/hooks/useAdmin'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import mixpanel from 'mixpanel-browser'

const useStyles = makeStyles(() =>
  createStyles({
    labelWidth: {
      maxWidth: '290px',
      width: '290px',
    },
    listLabel: {
      paddingLeft: 0,
    },
  }),
)

interface Props {
  customerId: number;
}

/**
 * Display customer dwelling details
 */
const CustomerDetailsTemplate = ({ customerId }: Props) => {
  const classes = useStyles()
  const dwellingDetails = useCurrentDwellingDetails()
  const adminUser = useAdmin()

  usePageTitle(adminUser ? `Customer ${adminUser.first_name} - Contact ` : 'Customer - Contact')

  useEffect(() => {
    if (mixpanel) {
      mixpanel.track_pageview()
    }
  }, [mixpanel])

  return (
    <Box my={1} mx={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Contact Info</Typography>
        {customerDetailsTooltip}
      </Box>
      {(adminUser && dwellingDetails) ? (
        <List aria-label="Customer detail list">
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Name"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {!adminUser && <ListItemText primary="No Admin User Assigned" />}
            {adminUser?.first_name && <ListItemText primary={adminUser.first_name + ' ' + adminUser.last_name} />}
            {!adminUser?.first_name && <ListItemText primary="" />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Email"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {!adminUser && <ListItemText primary="No Admin User Assigned" />}
            {adminUser?.email && <ListItemText primary={adminUser.email} />}
            {!adminUser?.email && <ListItemText primary="" />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Phone"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {!adminUser && <ListItemText primary="No Admin User Assigned" />}
            {adminUser?.phone && <ListItemText primary={adminUser.phone} />}
            {!adminUser?.phone && <ListItemText primary="" />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Street Address"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.street_1 && <ListItemText primary={dwellingDetails.street_1} />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Street Address 2"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.street_2 && <ListItemText primary={dwellingDetails.street_2} />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="City"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.city && <ListItemText primary={dwellingDetails.city} />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="State"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.province && <ListItemText primary={dwellingDetails.province} />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Country"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.country && <ListItemText primary={dwellingDetails.country} />}
          </ListItem>
          <ListItem className={classes.listLabel}>
            <ListItemText
              className={classes.labelWidth}
              primary="Zip Code"
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
            {dwellingDetails.postal_code && <ListItemText primary={dwellingDetails.postal_code} />}
          </ListItem>
        </List>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default CustomerDetailsTemplate