import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core'
import bundleImageUrl from 'features/how-to-buy/bundle.svg'
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeAddressElementChangeEvent, loadStripe } from '@stripe/stripe-js'
import React, {
  ChangeEvent, FormEvent, useEffect, useState, 
} from 'react'
import config from 'config'
import { useLocation, useNavigate } from 'react-router-dom'
import stripeService, { InitPaymentResponse, Taxes } from 'state-mngt/services/stripe-service'
import { ROUTE_PATHS } from 'routes/routes'
import { usePageTitle } from 'utils/hooks/router'
import { useAppSelector } from 'utils/hooks/reduxTypes'
import { selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import { CheckCircleOutline, ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
import { Country } from 'utils/constants/province-lookup-options'

export type NavState = InitPaymentResponse & { quantity: number }
const stripePromise = loadStripe(config.stripePublicKey)
const renderPrice = (amount: number) => `$${(amount / 100).toFixed(2)}`

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    padding: 32,
  },
  input: {
    marginBottom: '0.75rem',
  },
  productImage: {
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
    maxWidth: '160px',
  },
  productTitle: {
    fontWeight: 'bold',
  },
  muted: {
    color: '#9BB3BF',
  },
  line: {
    borderColor: '#9BB3BF',
  },
  button: {
    marginTop: 24,
  },
  error: {
    color: 'red',
    marginTop: '16px',
  },
}))

const formatPercent = (taxPercentage: string) => parseFloat(taxPercentage).toFixed(1)
const taxAmount = (taxBreakown: Taxes[]) => taxBreakown.reduce((prev, curr) =>
  curr.amount + prev, 0)

const PriceBreakdown = ({ priceDetails, loading, ...rest }: BoxProps & { loading: boolean, priceDetails: PriceDetails }) => {
  const {
    unit_amount,
    quantity,
    currency,
    taxes,
  } = priceDetails

  const classes = useStyles()
  const subtotal = unit_amount * quantity
  const total = subtotal + taxAmount(taxes)

  const renderTax = ({ amount, percentage, type }: Taxes) => {
    return (
      <>
        <Typography>{type.toUpperCase()} ({formatPercent(percentage)}%)</Typography>
        <Typography>{renderPrice(amount)}</Typography>
      </>
    )
  }

  return (
    <Box {...rest}>
      <Box display='flex' justifyContent='space-between'>
        <Typography>Subtotal</Typography>
        <Typography>{renderPrice(subtotal)}</Typography>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box mt={2}>
        <Box
          display='flex'
          justifyContent='space-between'
          className={classes.muted}
        >
          <Typography>Shipping</Typography>
          <Typography>$0.00</Typography>
        </Box>
        {(!taxes.length && loading) && (
          <Box
            display='flex'
            justifyContent='space-between'
            className={classes.muted}
          >
            <Typography>
                            Calculating taxes...
            </Typography>
          </Box>
        )}
        {taxes.filter(x => x.amount).map(x => (
          <Box
            display='flex'
            justifyContent='space-between'
            className={classes.muted}
          >
            {renderTax(x)}
          </Box>
        ))}
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box
        mt={2}
        display='flex'
        justifyContent='space-between'
      >
        <Typography>Total</Typography>
        <Typography>{currency.toUpperCase()} {renderPrice(total)}</Typography>
      </Box>
    </Box>
  )
}

const ProductDetails = ({ priceDetails, ...rest }: BoxProps & { priceDetails: PriceDetails }) => {
  const classes = useStyles()
  const { unit_amount, quantity } = priceDetails

  return (
    <Box {...rest} display='flex'>
      <img
        alt="Haven CAM product shot"
        src={bundleImageUrl}
        className={classes.productImage}
      />
      <Box display='flex' justifyContent='space-between' width='100%'>
        <Box ml={2}>
          <Typography className={classes.productTitle}>
                        HAVEN Bundle
          </Typography>
          <Typography className={classes.muted}>
                        (1 monitor, 1 controller)
          </Typography>
          <Typography className={classes.muted}>
                        Quantity: {quantity}
          </Typography>
        </Box>
        <Box>
          {renderPrice(unit_amount)}
        </Box>

      </Box>
    </Box>
  )
}

interface PriceDetails {
    unit_amount: number
    quantity: number
    taxes: Taxes[]
    currency: string
}

const toCountryCode = (country: Country): 'CA' | 'US' =>
  country.toLowerCase() === 'canada' ? 'CA' : 'US'

const toCountry = (countryCode: string): 'Canada' | 'USA' =>
  countryCode === 'CA' ? 'Canada' : 'USA'

type status = null | 'error' | 'loading' | 'success'

const Component = () => {
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const serviceCompany = useAppSelector(selectCompanyDetails)
  const [status, setStatus] = useState<{ address: status, payment: status }>({
    address: null, payment: null, 
  })
  const [expanded, setExpanded] = useState<string | null>('address')
  const [priceDetails, setPriceDetails] = useState<PriceDetails>({
    unit_amount: 0,
    currency: 'cad',
    quantity: 1,
    taxes: [],
  })
  const {
    name,
    street_1,
    street_2,
    city,
    province,
    postal_code,
    country,
  } = serviceCompany || {
  }

  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    quantity, currency, unit_amount, payment_intent_id, 
  } = state as NavState

  useEffect(() => {
    // handle case where user lands directly here, skipping the pup page
    if (!Object.keys(state).length) navigate(-1)
  }, [Object.keys(state).length])

  useEffect(() => {
    setPriceDetails(prev => ({
      ...prev, ...state, 
    }))
  }, [currency, unit_amount, quantity])

  const calculateTax = async ({ country, postal_code }: { country: string, postal_code: string }) => {
    setStatus(prev => ({
      ...prev, address: 'loading', 
    }))
    setErrorMessage('')
    const params = {
      quantity,
      postal_code,
      country,
      payment_intent_id,
    }
    try {
      const { taxes, unit_amount, currency } = await stripeService.calculateTax(params)
      setPriceDetails(prev => ({
        ...prev, taxes, unit_amount, currency, 
      }))
      setExpanded('payment')
      setDisabled(false)
      setStatus(prev => ({
        ...prev, address: 'success', 
      }))
    } catch (e) {
      console.error(e)
      setErrorMessage('We could not determine the tax location based on the provided address.')
      setStatus(prev => ({
        ...prev, address: 'error', 
      }))
      setDisabled(true)
    }
  }

  const handleAccordion = (panel: string) => (e: ChangeEvent<{}>, expanded: boolean) => {
    setExpanded(expanded ? panel : null)
  }

  const onChangeAddress = (e: StripeAddressElementChangeEvent) => {
    if (e.complete) {
      calculateTax({
        postal_code: e.value.address.postal_code,
        country: toCountry(e.value.address.country),
      })
    } else {
      setDisabled(true)
      setExpanded('address')
      setStatus(prev => ({
        ...prev, address: null, 
      }))
    }
  }

  const onSubmit = async (e: FormEvent) => {
    if (!stripe) return
    if (!elements) return
    e.preventDefault()
    setLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}${ROUTE_PATHS.paymentRedirect.absolute}`,
      },
    })

    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message as string)
    } else {
      setErrorMessage("An unexpected error occurred.")
    }
    setLoading(false)
  }

  const renderIcon = (key: 'address' | 'payment') => {
    if (status[key] === 'error') return (<ErrorOutlineRounded style={{
      color: 'red', height: '100%', 
    }} />)
    if (status[key] === 'success') return (<CheckCircleOutline style={{
      color: 'green', height: '100%', 
    }} />)
    if (status[key] === 'loading') return (<CircularProgress style={{
      marginLeft: '8px', height: '100%', 
    }} size={14} />)
    return null
  }

  return (
    <Container>
      <Box
        component='form'
        onSubmit={onSubmit}
        display="flex"
        maxWidth='lg'
        mx='auto'
        mt={8}
        flexWrap='wrap'
        alignItems="center">
        <Box
          className={classes.box}
        >
          <Typography variant="h3" color="primary">
                        HAVEN personal use program
          </Typography>
          <ProductDetails
            mt={8}
            priceDetails={priceDetails}
          />
          <PriceBreakdown
            mt={8}
            loading={loading}
            priceDetails={priceDetails}
          />
        </Box>
        <Box
          className={classes.box}
          display='flex'
          flexDirection='column'
        >
          <div>
            <Accordion expanded={expanded === 'address'} onChange={handleAccordion('address')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="address-header"
                id="address-header"
              >
                <Box display='flex' alignItems='center'>
                                    Shipping Address <Box height='16px'>{renderIcon('address')}</Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <AddressElement
                  onChange={onChangeAddress}
                  options={{
                    mode: 'shipping',
                    defaultValues: {
                      name,
                      address: {
                        line1: street_1,
                        line2: street_2,
                        city,
                        state: province,
                        country: country ? toCountryCode(country) : '',
                        postal_code,
                      },
                    },
                    allowedCountries: ['CA', 'US'],
                  }} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'payment'} onChange={handleAccordion('payment')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="payment-header"
                id="payment-header"
              >
                <Box display='flex' alignItems='center'>
                                    Payment <Box height='16px'>{renderIcon('payment')}</Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <PaymentElement />
              </AccordionDetails>
            </Accordion>

          </div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type='submit'
            disabled={disabled || loading}
            endIcon={loading ? <CircularProgress size={20} /> : null}
          >
                        Buy
          </Button>
          {errorMessage && <Typography className={classes.error}>{errorMessage}</Typography>}
        </Box>
      </Box>
    </Container>
  )
}

export function PaymentForm() {
  const { state } = useLocation()
  usePageTitle('Payment')

  const {
    client_secret,
  } = state as any

  return (
    <Elements stripe={stripePromise} options={{
      ...(client_secret ? {
        clientSecret: client_secret, 
      } : {
      }), 
    }}>
      <Component />
    </Elements>
  )
}

export {
  renderPrice,
}
