import {
    Box,
    Paper
} from '@material-ui/core';
import DeviceCurrentStatus from 'features/customer-drill-down/charts/current-status';
import CustomerHistory from 'features/customer-drill-down/charts/customer-history';
import DemoCustomerHistory from 'features/demo-data/demo-customer-history';
import DemoDataTour from 'features/demo-data/demo-data-tour';
import { ChartImagePlaceholder } from 'features/product-tour/charts-page/chart-image-placeholder';
import { PRODUCT_TOUR_QUERY_KEYS } from 'features/product-tour/pro-portal-tour';
import React, { useState } from 'react';
import { selectDwellingDetails } from 'state-mngt/selectors/customer-selectors';
import { theme } from 'theme';
import { useAppSelector } from 'utils/hooks/reduxTypes';
import { usePageTitle } from 'utils/hooks/router';
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams';
import useDevicesInZone from '../equipment/useDevicesInZone';

/**
 * Display Device tab on customer drill-down page
 */
const ChartsTemplate = ({ isSidebarCollapsed }: { isSidebarCollapsed: boolean }) => {
  const [searchParams] = useDecodedSearchParams();
  const dwelling = useAppSelector(selectDwellingDetails);
  const currentPageTour = searchParams.get(PRODUCT_TOUR_QUERY_KEYS.currentPageTour) || '';

  const devicesInZone = useDevicesInZone()
  const cam = Object.values(devicesInZone).find(device => device.type === 'cam')

  usePageTitle(dwelling ? `Customer ${dwelling.name} - Data` : 'Customer - Data')
  const [isCollapsed, setToggleOpen] = useState<boolean>(false)

  return (
    <Box
      p={4}
      pl={4}
    >
      {devicesInZone &&
        <Box display="flex">
          <Paper elevation={3}
            style={{
              marginRight: '2rem',
              padding: theme.spacing(2),
              position: 'relative',
              borderRadius: 10,
            }}>
            <DeviceCurrentStatus
              deviceId={cam?.id || 0}
              setOpen={setToggleOpen}
            />
          </Paper>
          {dwelling?.isDemo || currentPageTour ? (
            <Paper elevation={3}
              style={{
                flexGrow: 1,
                overflow: 'auto',
                borderRadius: 10,
                maxWidth: 1041,
              }}
            >
              {dwelling?.isDemo ? (
                <DemoCustomerHistory />
              ) : (
                <ChartImagePlaceholder />
              )}
            </Paper>
          ) : (
            <Paper elevation={3}
              style={{
                flexGrow: 1,
                overflow: 'hidden',
                padding: theme.spacing(2),
                borderRadius: 10,
              }}
            >
              <CustomerHistory
                isCurrentStatusCollapsed={isCollapsed}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </Paper>
          )}
        </Box>
      }
      <DemoDataTour />
    </Box>
  )
}

export default ChartsTemplate
